import React, { Component } from 'react';
import logo from '../images/logo.svg';
import { Link } from 'react-router-dom';

class BetaClosed extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='beta-screen'>
                <div className="beta-screen-container">
                    <img className="logo" src={logo} alt="tender-notify-logo" />
                    <h2 className='title'>Now Available by Private Request Only</h2>
                    <p className='description'>After a multi-year commitment to Microsoft’s Founders Hub, we have decided to offer Tender Notify exclusively on demand.</p>
                    <p className='description'>This approach allows us to provide a more personalized experience to our users.</p>
                    <p className='description'>For more details or to request access, please contact us directly.</p>
                    <p className='mobile-description'>After a multi-year commitment to Microsoft’s Founders Hub, we have decided to offer Tender Notify exclusively on demand. This approach allows us to provide a more personalized experience to our users. For more details or to request access, please contact us directly.</p>
                    <Link to={"/"}>
                        <button className='button'>RETURN TO SITE</button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default BetaClosed;