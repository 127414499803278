import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../../actions/generalActions";

class TermsOfUseAndConditions extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("TermsOfUseAndConditions");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'TermsOfUseAndConditions', page: '/TermsOfUseAndConditions' });
    }

    render() {
        return (
            <section id="privacy-section" class="privacy-section" onMouseOver={this.props.currentSection != "TermsOfUseAndConditions" ? this.changeSection : () => { }}>
                <Container>
                    <Row className="justify-content-md-left">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h1 className="title-cookies-policy">Terms of use and conditions</h1>
                            <p className='text-below-title'>Last updated: February 1st, 2021</p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">1. Ownership</h2>
                            <p className='cookie-policy-paragraph'>
                                The owner of this website is CoowiseIT OU (hereinafter referred to as Tender Notify), with registered office at Pärnu mnt 139c, Tallinn, Estonia, 11317.
                            </p>
                            <p className='cookie-policy-paragraph'>Registration No: 16129859</p>
                            <p className='cookie-policy-paragraph'>E-mail: <a href="mailto:contact@coowise.com">contact@coowise.com</a></p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">2. Acceptance</h2>
                            <p className='cookie-policy-paragraph'>
                                This online platform (hereinafter the Service) is subject to the following terms and conditions: these Terms of Use and Agreement, our Privacy Policy and Cookie Policy.
                            </p>
                            <p className='cookie-policy-paragraph'>
                                When you use the Service, you accept our terms and conditions and thereby agree not to use the Service for illegal purposes.
                            </p>
                            <p className='cookie-policy-paragraph'>
                                On the other hand, we inform you that for legal reasons we archive the electronic documents in which purchases are formalised. You will be able to access these documents at any time by requesting them at: <a href="mailto:contact@coowise.com">contact@coowise.com</a>.
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">3. Description of the Service</h2>
                            <p className='cookie-policy-paragraph'>
                                The Tender Notify service is a B2B SaaS that offers companies notifications and tender administration through different communication channels (internal channel of the App, email, WhatsApp and SMS). In this way, it makes available to the Client thousands of structured and categorised bidding documents so that the user can find the information they need quickly and easily, keeping them constantly updated through a real-time bid notification system.
                            </p>
                            <p className='cookie-policy-paragraph'>The language in which the contract between Tender Notify and the customer will be finalised will be English.</p>
                            <p className='cookie-policy-paragraph'>The process of contracting the online service will be as follows:</p>
                            <ul className='list-margin'>
                                <li className='summary-item privacy'>You must register on the website with a username and password.</li>
                                <li className='summary-item privacy'>At the time of registration you will need to include a payment method.</li>
                                <li className='summary-item privacy'>The user will have to choose the subscription plan that best suits their needs.</li>
                                <li className='summary-item'>The purchase confirmation document will be stored in your profile for consultation. In any case, you can always request it at: <a href="mailto:contact@coowise.com">contact@coowise.com</a>.</li>
                            </ul>
                            <p className='cookie-policy-paragraph'>
                                Once the purchase of the service has been made, it will be confirmed by sending an e-mail, within a maximum period of 24 hours, to the address you have indicated. The e-mail will indicate the service, the amount, applicable taxes, the method of payment, the applicable terms and conditions and the access details.
                            </p>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">3.1. Subscription</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "The Service has two different subscription plans (FREE, PRO), each with different features and prices. However, all subscriptions are monthly and have a free trial period of 15 days."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Your subscription to the Service will automatically renew for the same period of time until terminated. Unless you cancel your subscription, you authorise us to charge the applicable monthly subscription fee to your payment method."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "You may cancel your subscription to the Service at any time, and you will continue to have access to the Service until the end of your monthly billing period. To the extent permitted by law, payments are non-refundable and we do not provide refunds or credits for partial monthly subscription periods."
                                    }
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"3.2. Support and availability"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Tender Notify will at all times try to maintain adequate levels of availability for the proper functioning of the service and will devote its maximum effort and compliance with its responsibilities at the level of service parameters (availability, response time or performance, among others)."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "In any case, Tender Notify will make available to the customer a communication channel by email at the following address "
                                    }
                                    <a href="mailto:contact@coowise.com">contact@coowise.com</a>.
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "If there is any change in this regard, the customer will be informed."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Through this channel the customer may report any incident related to the contracted Service. At all times we will try to solve any defect in our products as soon as possible as we become aware of it, either by giving you a temporary solution or by launching an update for all our customers."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Specifically, Tender Notify has support through the platform for the following issues:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "Administrative"
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Error resolution"
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Data inconsistency"
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Issues linked to the application"
                                        }
                                    </li>
                                </ul>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"4. External links"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "You may be directed from the Service to other websites via links."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "However, Tender Notify does not control those sites or their content, which are in fact subject to their own terms and conditions. Therefore, Tender Notify is not responsible for the quality, truthfulness or accuracy of those sites."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"5. Age"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Regarding the use of the Service, you declare that you are of legal age and that you have the necessary legal capacity to be bound by this agreement, and to use it in accordance with its terms and conditions, which you fully understand and acknowledge."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "If you are contracting on behalf of a company for the Service, you acknowledge that you have the appropriate authority and representation on behalf of the organisation to do so."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "You declare that all the information you provide to access the Service, before and during its use, is true, complete and accurate."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"6. Intellectual and industrial property and licence"}</h2>
                            <Col md="auto" className='sub-columns privacy'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"6.1. Intellectual and industrial property"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "The content and information on the Service (among other, text, sound, video, image or software code), as well as the infrastructure used to provide such content and information, is the property of Tender Notify or has the corresponding authorisations for its use."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "In addition, modification, reproduction, duplication, copying, distribution, sale, resale or other exploitation for commercial or equivalent purposes of the Service is prohibited."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "For any other use of the content of the Service you need our prior written consent."
                                    }
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"6.2. Licence"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "For the provision of the Service, we grant you a limited, revocable, non-exclusive licence, which may not be leased, loaned, sold, sublicensed, transferred or otherwise infringed in any way or by any means to use our Service, subject to and in accordance with our terms and conditions, to any person or entity, in whole or in part, in any form or by any means. This licence is granted for the sole purpose of enabling you to use our Service in the manner set out in our terms and conditions."
                                    }
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"6.3. Confidentiality"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "The parties agree to maintain the confidentiality of the data and information shared, especially that which is expressly designated as such and which Tender Notify may share with the customer. Both within the framework of the contractual relationship and with regard to any information provided by Tender Notify for the execution of these Conditions of Use and Contract. In any case, this information may be disclosed by law."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "The parties have a duty of secrecy in relation to the private and internal acts of both parties, as well as in relation to the knowledge they may have of the commercial relations, projects or tenders of the other party."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "This obligation of confidentiality shall remain in force for as long as the parties maintain this specific contractual relationship, and indefinitely after its termination, regardless of the cause."
                                    }
                                </p>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"7. User Content"}</h2>
                            <Col md="auto" className='sub-columns privacy'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"7.1. Content"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "You can contribute to the Service by sending us messages to our email address or via the contact form (\"Content\")."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "We may use your Content in different ways, such as: displaying it on the Website, reformatting it, translating it into other languages, editing it for clarity, correcting errors, promoting it or distributing it."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Therefore, by submitting Content to us you grant Tender Notify a worldwide, non-exclusive, royalty-free, until the Content is removed, transferable and sub-licensable on that Content."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "This means that the Content remains yours but Tender Notify, under this licence to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from, display and perform the Content throughout the world by any means known and for any lawful purpose; and (b) use the name that you submit in connection with the Content."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "However, Tender Notify reserves the right not to publish content or information that is false or contrary to the rights of third parties."
                                    }
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"7.2. Prohibited Activities"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "In addition, you agree not to do any of the following:"
                                    }
                                </p>
                                <ol className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "Access, monitor or copy content or information from this website by using robots, trackers, data scrapers or any other automated means to access, track, index, retrieve or otherwise use the website or its content for any purpose without the express written permission of Tender Notify."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Embed, mirror or otherwise incorporate any part of this website in another website without our prior written permission."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Attempt to modify, translate, adapt, edit, decompile, disassemble or reverse engineer any software program used by Tender Notify in connection with the website."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Use the website to threaten, harass, defraud, incite, harass another person or advocate harassment of another person, or otherwise interfere with another user's use of the website."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Use the Website to send or transmit spam, chain letters, contests, junk mail, pyramid schemes, surveys or other bulk messages, whether commercial or non-commercial in nature."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Use the website to infringe the rights of third parties, including breach of confidence and infringement of copyright, trademarks, patents, trade secrets, moral rights, privacy rights or any other intellectual property or proprietary rights."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Attempting to gain unauthorised access to the website, computer systems or networks connected to the website by hacking, password mining or any other means. As well as the transmission of computer viruses, worms, defects, Trojan horses or other items of a destructive nature."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Use any device, software or routine that interferes with the proper functioning and security measures of the website."
                                        }
                                    </li>
                                </ol>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"8. Price and taxes"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "The prices of the services offered on Tender Notify are indicated in euros (€), with the corresponding value added tax in force at the time of contracting being added to the price."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "In any case, the operation may be exempt or not subject to tax depending on your country of residence or the condition in which you act. Therefore, in some cases the final price of the order may be altered with respect to that shown."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "You can request more information by contacting us at "
                                }
                                <a href="mailto:contact@coowise.com">contact@coowise.com</a>.
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"9. Payment and means"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "The online payment of the service purchased can be made:"
                                }
                            </p>
                            <ul className='list-margin'>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "By credit or debit card."
                                    }
                                </li>
                            </ul>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "In this regard, Tender Notify informs credit and debit card holders that transactions in the online shop are made in a secure payment gateway Stripe, using TLS technology to ensure the security of data transmission."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"10. Right of withdrawal, refunds and termination of the Service"}</h2>
                            <Col md="auto" className='sub-columns privacy'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"10.1. Right of withdrawal"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Given the type of services provided by Tender Notify, the supply of digital content that is not provided on a material medium when performance has begun, the right of withdrawal will not be applicable in accordance with "
                                    }
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : <b>article 103, section m) </b>
                                    }
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "of Royal Legislative Decree 1/2007, of November 16th, which approves the revised text of the General Law for the Defence of Consumers and Users and other complementary laws and "
                                    }
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : <b>article 16, letter m) of Directive 2011/83/EU </b>
                                    }
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "of the European Parliament and of the Council on the rights of Consumers."
                                    }
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"10.2. Refunds"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "The user can cancel the subscription to the Service at any time, from their own account or by contacting us at "
                                    }
                                    <a href="mailto:contact@coowise.com">contact@coowise.com</a>.
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "In any case, cancellation of the Service will not imply any refund, either partial or total, of the amount paid. The user will be able to access the content and use the subscription until the end of the contracted period."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Subscriptions contracted may not be assigned or transferred to other users of the Service."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "If you have any doubts, please contact us at "
                                    }
                                    <a href="mailto:contact@coowise.com">contact@coowise.com</a>.
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"10.3. Restriction, Suspension and Termination of the Service"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "In the event of a breach of the terms of these legal conditions or in the event of non-payment, the Service may be restricted, suspended or terminated."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "In the event of restriction or suspension of the Service due to a cause that can be rectified, such as non-payment, access to the Service will be restored once the amounts owed have been paid."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "On the other hand, the user may cancel the subscription to the Service at any time, from their own account or by contacting us at "
                                    }
                                    <a href="mailto:contact@coowise.com">contact@coowise.com</a>.
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "In any case, the cancellation of the Service will not imply any refund, either partial or total, of the amount paid. The user will be able to access the content and use the subscription until the end of the contracted period."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "In case of doubt, please contact us at "
                                    }
                                    <a href="mailto:contact@coowise.com">contact@coowise.com</a>.
                                </p>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"11. Validity of the offers"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "The services offered in the Service, and their prices, will be available for purchase while they are in the catalogue of products displayed on this website."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "In any case, Tender Notify reserves the right to make any modifications to the Service that it deems appropriate, and may update products and services according to the market."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Tender Notify reserves the right to change prices without prior notice."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Please note that despite updates to the prices on the Service, prices may contain errors. We will promptly correct any errors that appear, but they will not be binding on the Service."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"12. Security"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "As stated above, the entire contracting procedure, as well as the transmission of your personal data and payment systems, is carried out on a secure page and encrypted via the TLS protocol. The Service will also provide data encryption and authentication."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "We guarantee the security of the Service to the best of our technological knowledge. However, Tender Notify cannot guarantee the complete future security of the Service. We do, however, undertake to remedy and implement appropriate corrective measures to correct any security breach as soon as possible."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "You agree to notify Tender Notify immediately by email of any situation that could lead to the impersonation of a user."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"13. Disclaimer of warranties and responsibility"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Except in those cases expressly described in these terms and conditions and to the extent permitted by law, Tender Notify is not liable for damages of any kind that may be due to the lack of accuracy, completeness or timeliness, including errors and omissions, of the information contained in the Service. Likewise, neither is it liable for any duty or commitment to verify or monitor its contents and information."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Likewise, Tender Notify does not guarantee the availability and absolute continuity of the operation of the Service. Tender Notify will endeavour to give sufficient advance notice of any interruptions that may occur in the operation of the Service whenever possible."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Tender Notify excludes, to the full extent permitted by law, any liability for damages of any kind that may be due to the lack of availability or continuity of the operation of the Service, expressly including cases of major causes, unforeseeable circumstances, natural catastrophes or similar circumstances. Similarly, with respect to the fraudulent use that users may have attributed to the Service."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Likewise, Tender Notify excludes any liability for damages of any kind that may be due to the use of the Service and its contents by users, clients or professionals, or that may be due to the lack of veracity, validity or authenticity of the information that users provide to others about themselves. In particular, Tender Notify excludes any liability for damages of any kind that may be due to the impersonation of a third party by a user in any kind of communication made through the Service."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"14. Modifications and nullity"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "We may update the terms and conditions of the Service in the future, as well as features and functions of the Service itself."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "We will inform you of changes to terms and conditions by posting a notice in a prominent place on our website and/or by email."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "If any provision of these terms and conditions is found to be invalid or unenforceable in whole or in part, it will only affect that provision or that part of it which is invalid or unenforceable. The terms and conditions shall survive in all other respects, and such provision, or the part thereof that is affected, shall be deemed not to have been made."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"15. Claims and actions derived from the contract"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "This Service is governed by the legislation of the place where the service is contracted."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "In order to simplify the resolution of civil claims and reduce costs, we do not exclude the possibility of submitting to an Arbitration of Equity of the Court of Arbitration of the Chambers of Commerce and Industry."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "In this respect, and in accordance with the applicable regulations, Tender Notify informs you of the existence of a European online dispute resolution platform which facilitates the out-of-court settlement of such disputes for contracts concluded online between Consumers and Internet service providers. This platform can be accessed via the following website:"
                                }
                                <a href="http://ec.europa.eu/odr" target='_blank'>http://ec.europa.eu/odr</a>.
                            </p>
                            
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"16. Contact"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "For any clarification, incident or complaint, you can contact us by:"
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "E-mail: "
                                }
                                <a href="mailto:contact@coowise.com">contact@coowise.com</a>
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Address: Pärnu mnt 139c, Tallinn, Estonia, 11317."
                                }
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUseAndConditions);