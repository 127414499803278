import React, { Component } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import { connect } from "react-redux";
import {
    changeSubscription
} from "../../actions/userActions";

class ChangeSubscriptionModal extends Component {
    constructor(props) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(event) {
        if (event === "Refresh"){
            window.location.href += "#pricing";
            window.location.reload();
        }
        else
            if (event === "buttonCancelModal")
                this.props.changeChangeSubscriptionModalFlag(event);
            else
                this.props.changeSubscription(this.props.planId);
    }

    render() {
        if (this.props.fetchingChangeSubscription == false) {
            if (this.props.fetchedChangeSubscription == false && this.props.changeSubscriptionError == null)
                return (
                    <ReactModal isOpen={this.props.changeSubscriptionModalFlag} className="modal changeSubscription animate__animated animate__fadeInDown animate__faster">
                        <Grid columns={2} divided>
                            <Grid.Row className="modalHeader">
                                <Grid.Column width={12} className="gridColumn">
                                    <h2 className="titleModal">Change subscription</h2>
                                </Grid.Column>

                                <Grid.Column textAlign={"right"} width={4} className="gridColumn">
                                    <Button icon='close' onClick={() => this.closeModal("buttonCancelModal")} className="closeModal" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <div className="containerModal changeSubscription">
                            <div className="contentModal">
                                <p className="modalParagraph">
                                    Take your membership to the next level!
                                </p>
                                <p className="modalParagraph">
                                    Click on <span className='span'>CONFIRM</span> to upgrade to the <a className='plan'> {this.props.planName}</a> plan
                                </p>
                            </div>
                        </div>

                        <div className="modalFooter">
                            <div className="divEspaciado"></div>
                            <Button onClick={() => this.closeModal("buttonCancelModal")} className="buttonLabel">CANCEL</Button>
                            <Button content='CONFIRM' className="buttonPrimary" onClick={() => this.closeModal("buttonConfirmModal")} />
                        </div>
                    </ReactModal>
                );
            else
                return (
                    <ReactModal isOpen={this.props.changeSubscriptionModalFlag} className={"modal changeSubscription animate__animated animate__fadeInDown animate__faster"}>
                        <Grid columns={2} divided>
                            <Grid.Row className="modalHeader">
                                <Grid.Column width={12} className="gridColumn">
                                    <h2 className="titleModal">Change subscription</h2>
                                </Grid.Column>

                                <Grid.Column textAlign={"right"} width={4} className="gridColumn">
                                        <Button icon='close' onClick={() => this.closeModal("Refresh")} className="closeModal" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        {
                            this.props.fetchedChangeSubscription
                                ?
                                <div className="containerModal changeSubscription without-footer">
                                    <div className="contentModal">
                                        <p className="successfullChangeSubscription">
                                            Please wait a moment...
                                        </p>
                                        <p className="modalParagraph">
                                            We are preparing your connection to the payment platform
                                        </p>
                                        <p className="infoMessage">
                                            If you are logged into the app, please refresh the page or log in again.
                                        </p>
                                    </div>
                                </div>
                                :
                                <div className="containerModal changeSubscription without-footer">
                                    <div className="contentModal">
                                        <p className="failedChangeSubscription">
                                            Sorry, an error has occurred
                                        </p>
                                        <p className="modalParagraph">
                                            We apologize for the inconvenience. Please try again
                                        </p>
                                    </div>
                                </div>
                        }
                    </ReactModal>
                );
        }
        else
            return (
                <ReactModal isOpen={this.props.changeSubscriptionModalFlag} className="modal changeSubscription fetching animate__animated animate__fadeInDown animate__faster">
                    <Spinner myClass="containerSpinnerSecondary" />
                </ReactModal>
            );
    }
}

ChangeSubscriptionModal.propTypes = {
    changeSubscriptionModalFlag: PropTypes.bool.isRequired,
    changeChangeSubscriptionModalFlag: PropTypes.func.isRequired,
    planId: PropTypes.number.isRequired,
    planName: PropTypes.string.isRequired
};

const mapStateToProps = (value) => {
    return {
        fetchingChangeSubscription: value.user.fetchingChangeSubscription,
        fetchedChangeSubscription: value.user.fetchedChangeSubscription,
        changeSubscriptionError: value.user.changeSubscriptionError
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeSubscription: (planId) => dispatch(changeSubscription(planId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSubscriptionModal);