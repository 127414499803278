import React, { Component } from 'react';
import logo from '../images/logo.svg';
import email from '../images/email.svg';
import map from '../images/map.svg';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';
import MP_Black from '../images/MicrosoftPartner/MP_Black.png'

class Footer extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Footer");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Footer", page: '/Footer' });
    }

    onClickLink(id) {
        if (window.location.pathname == "/blog" || window.location.pathname == "/satisfaction-survey" || window.location.pathname == "/sign-up") {
            window.location.hash = id;
            window.location.pathname = "/";
        }
        else {
            let element = document.getElementById(id);
            if (element != null)
                element.scrollIntoView({ behavior: "smooth" });
        }
    }

    render() {
        const currentYear = new Date().getFullYear();

        return (
            <section id="footer" class="footer pt-50 pb-20" onMouseOver={this.props.currentSection != "Footer" ? this.changeSection : () => { }}>
                <div class="container">
                    <div class="row rowFooterContact">
                        <div class="col-lg-2 footer">
                            <img class='logo' src={logo} />
                            <img class='mp' src={MP_Black} />
                        </div>
                        <div class="col-lg-9 footer">
                            <div class="row nav">
                                <ul class="menuFooter">
                                    <li>
                                        <a class="page-scroll cursor" onClick={() => this.onClickLink("home")}>Home</a>
                                    </li>
                                    <li>
                                        <a class="page-scroll cursor" onClick={() => this.onClickLink("exploreResources")}>Explore Our Resources</a>
                                    </li>
                                    <li>
                                        <a class="page-scroll cursor" onClick={() => this.onClickLink("features")}>Features</a>
                                    </li>
                                    <li>
                                        <a class="page-scroll cursor" onClick={() => this.onClickLink("pricing")}>Pricing</a>
                                    </li>
                                    <li>
                                        <a class="page-scroll cursor" onClick={() => this.onClickLink("faqs")} >FAQs</a>
                                    </li>
                                    <li>
                                        <a class="page-scroll cursor" onClick={() => this.onClickLink("contact")}>Contact Us</a>
                                    </li>
                                    <li>
                                        <a class="page-scroll cursor" href={window.location.origin + "/blog"} target="_blank">Blog</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="row">
                                <ul class="menuFooterContact">
                                    <li>
                                        <img src={email} />
                                        <a class="description-contact-footer icons" href="mailto:contact@coowise.com">contact@coowise.com</a>
                                    </li>
                                    <li>
                                        <img src={map} />
                                        <a
                                            class="description-contact-footer icons"
                                            href={"https://www.google.com/maps/place/P%C3%A4rnu+mnt.+139c,+11317+Tallinn,+Estonia/@59.4069866,24.7342189,17z/data=!3m1!4b1!4m6!3m5!1s0x469294c3c45ab373:0xd01cdf63dfe36c23!8m2!3d59.4069866!4d24.7342189!16s%2Fg%2F11cs7ghdxx?entry=ttu"}
                                            target="_blank"
                                        >
                                            Pärnu mnt 139c, Tallinn, Estonia, 11317
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="row">
                                <ul class="menuFooterContact">
                                    <li>
                                        <a class="description-contact-footer" href={window.location.origin + "/privacy-policy"} target="_blank">Privacy policy</a>
                                    </li>
                                    <li>
                                        <a
                                            class="description-contact-footer"
                                            href={window.location.origin + "/cookies-policy"}
                                            target="_blank"
                                        >
                                            Cookie policy
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            class="description-contact-footer"
                                            href={window.location.origin + "/terms-of-use-and-conditions"}
                                            target="_blank"
                                        >
                                            Terms of use and conditions
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr class="lineCopyright" />
                    <div class="row">
                        <div class="col-12">
                            <p class="copyright">{"© " + currentYear + " Coowise, All Rights Reserved."}</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Footer.propTypes = {
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

export default (Footer);