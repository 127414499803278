import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../../actions/generalActions";

const texts = [
    `curl https://api.tendernotify.com/api/swagger/accesstoken/getaccesstoken \\
  -H "Content-Type: application/json" \\
  -d '{
    "api_key": "YOUR_API_KEY"
}'`,
    "Authorization: Bearer YOUR_ACCESS_TOKEN",
    `curl https://api.tendernotify.com/api/swagger/cpv/getcpvs \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $YOUR_ACCESS_TOKEN" \\
  -d '{
    "code": "45000000-7"
}'`,
    {  "code": "45000000-7", "description": "Construction work", 
    "cpvChildrenList": [ 
        { "code": "45100000-8", "description": "Site preparation work" }, 
        { "code": "45200000-9", "description": "Works for complete or part construction and civil engineering work" }, 
        { "code": "45300000-0", "description": "Building installation work" }, 
        { "code": "45400000-1", "description": "Building completion work" },        
        { "code": "45500000-2", "description": "Hire of construction and civil engineering machinery and equipment with operator" }    
    ],    
    "message": ""  },
    `curl https://api.tendernotify.com/api/swagger/authority/getauthorities \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $YOUR_ACCESS_TOKEN" \\
  -d '{
    "authorityFilter": "",
    "pageNumber": 1,
    "pageSize": 2
}'`,
    {
        "authorityDTOList": [ { "id": 1, "name": "Authority 1"}, { "id": 2, "name": "Authority 2" } ], "count": 1500
    },
    `curl https://api.tendernotify.com/api/authority/getauthority \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $YOUR_ACCESS_TOKEN" \\
  -d '{
    "authorityId": 1
}'`,
    {
        "name": "Authority name", "description": "Authority description", "department": "Authority department", "organizationNumber": "Authority organization number",
        "url": "Authority url", "externalLinks": [ "http://www.authorityexternallink.com" ]
    },
    `curl https://api.tendernotify.com/api/swagger/cpv/getcpvs \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $YOUR_ACCESS_TOKEN" \\
  -d '{
    "cpvFilter": "",
    "pageNumber": 1,
    "pageSize": 2
}'`,
    {
        "cpvDTOList": [ 
            { "code": "03000000-1", "description": "Agricultural, farming, fishing, forestry and related products" }, 
            { "code": "03100000-2", "description": "Agricultural and horticultural products"}
        ],
        "count": 1500
    },
    `curl https://api.tendernotify.com/api/swagger/cpv/getcpvtree \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $YOUR_ACCESS_TOKEN" \\
  -d '{
    "code": "45314300-4"
}'`,
    {
        "code": "45314300-4",
        "description": "Installation of cable infrastructure",
        "cpvChildrenList": [ 
            { "code": "45314310-7", "description": "Installation of cable laying" }, { "code": "45314320-0", "description": "Installation of computer cabling" }
        ],
        "message": ""
    },
    `curl https://api.tendernotify.com/api/swagger/procedure/getprocedures \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $YOUR_ACCESS_TOKEN" \\
  -d '{
    "procedureFilter": "",
    "pageNumber": 1,
    "pageSize": 2
}'`,
    {
        "procedureDTOList": [ 
            { "id": 1, "procedure": "Procedure 1" }, { "id": 2, "procedure": "Procedure 2" }
        ],
        "count": 1500
    },
    `curl https://api.tendernotify.com/api/swagger/smartfilter/getsmartfilters \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $YOUR_ACCESS_TOKEN" \\`,
    {
        "smartFilterDTOList": [ 
            { 
                "id": 1, 
                "name": "Smart Filter 1", 
                "filterfrontend": "zr1xqn7e7q contains '%03100000-2-Agricultural and horticultural products%'",
                "enabled": true,
                "createdon": "2023-04-08T20:07:48.195978",
                "lastupdate": "2023-04-08T20:07:48.195978",
                "groupNameList": []
            }
        ],
        "count": 1,
        "totalEnabled": 1
    },
    `curl https://api.tendernotify.com/api/swagger/tender/gettenders \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $YOUR_ACCESS_TOKEN" \\
  -d '{
    "tenderFiltersDTO": {
        "referenceNameSystemId": "",
        "status": 1,
        "fromPublicationDate": "2023-04-21T16:27:52.509Z",
        "toPublicationDate": "2023-04-21T16:27:52.509Z",
        "procedureId": 0,
        "authorityId": 0,
        "cpvId": 0,
        "trackedStatus": 1
    },
    "smartFilterId": 0,
    "pageNumber": 1,
    "pageSize": 2,
    "orderAsc": true,
    "orderBy": ""
}'`,
    {
        "tenderDTOList": [ 
            { 
                "id": 1,
                "systemId": "12345",
                "tenderReference": "Tender reference",
                "tenderName": "Tender name",
                "publicationDate": "2023-03-22T00:00:00",
                "responseDeadLine": "2023-04-12T16:00:00",
                "procedure": "Procedure 1",
                "authorityName": "Authority 1",
                "country": "Ireland",
                "status": "Published",
                "tracked": true
            },
            { 
                "id": 2,
                "systemId": "12346",
                "tenderReference": "Tender reference",
                "tenderName": "Tender name",
                "publicationDate": "2023-03-22T00:00:00",
                "responseDeadLine": "2023-04-12T16:00:00",
                "procedure": "Procedure 2",
                "authorityName": "Authority 3",
                "country": "Slovakia",
                "status": "Expired",
                "tracked": false
            },
        ],
        "count": 350
    }
]

const delay = ms => new Promise(res => setTimeout(res, ms));

class ApiReference extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apireferencesection: "introduction",
            codeCopied: 0
        }

        this.changeSection = this.changeSection.bind(this);
        this.onMouseOverReferenceSection = this.onMouseOverReferenceSection.bind(this);
        this.onClickReferenceSection = this.onClickReferenceSection.bind(this);
        this.onClickCopy = this.onClickCopy.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Blog-API-Reference-Article");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'Blog-API-Reference-Article', page: '/Blog-API-Reference-Article' });
    }

    onMouseOverReferenceSection(apireferencesection) {
        this.setState({ apireferencesection: apireferencesection });
    }

    onClickReferenceSection(apireferencesection) {
        this.setState({ apireferencesection: apireferencesection });

        let element = document.getElementById(apireferencesection);
        if (element != null) {
            element.scrollIntoView();
            if (apireferencesection == "introduction")
                window.scrollBy(0, -122);
            else
                window.scrollBy(0, -83);
        }
    }

    async onClickCopy(codeCopied, isJson) {
        if(isJson)
            navigator.clipboard.writeText(JSON.stringify(texts[codeCopied - 1], null, 2));
        else
            navigator.clipboard.writeText(texts[codeCopied - 1]);

        this.setState({ codeCopied: codeCopied });
        
        await delay(2000);

        this.setState({ codeCopied: 0 });
    }

    render() {
        const swaggerUrl = process.env.REACT_APP_SERVER_HOSTNAME + (process.env.REACT_APP_API_PORT != "" ? (":" + process.env.REACT_APP_API_PORT) : "") + "/swagger";
        return (
            <div id="blog-div" className='blog-div'>
                <section class="art-section api-reference" onMouseOver={this.props.currentSection != "Blog-API-Reference-Article" ? this.changeSection : () => { }}>
                    <Container className='api-reference-body'>
                        <Container className='api-reference-nav'>
                            <h1>API REFERENCE</h1>
                            <h2
                                className={"nav-item" + (this.state.apireferencesection == "introduction" ? " active" : "")}
                                onClick={() => this.onClickReferenceSection("introduction")}
                            >
                                Introduction
                            </h2>
                            <h2
                                className={"nav-item" + (this.state.apireferencesection == "authentication" ? " active" : "")}
                                onClick={() => this.onClickReferenceSection("authentication")}
                            >
                                Authentication
                            </h2>
                            <h2
                                className={"nav-item" + (this.state.apireferencesection == "making-requests" ? " active" : "")}
                                onClick={() => this.onClickReferenceSection("making-requests")}
                            >
                                Making requests
                            </h2>
                            <h2
                                className={"nav-item" + (this.state.apireferencesection == "entities" ? " active" : "")}
                                onClick={() => this.onClickReferenceSection("entities")}
                            >
                                Entities
                            </h2>
                            <h3
                                className={"nav-item" + (this.state.apireferencesection == "authority" ? " active" : "")}
                                onClick={() => this.onClickReferenceSection("authority")}
                            >
                                Authority
                            </h3>
                            {
                                this.state.apireferencesection == "authority" || this.state.apireferencesection == "get-authorities" ||
                                    this.state.apireferencesection == "get-authority"
                                    ?
                                    <>
                                        <h4
                                            className={"nav-item" + (this.state.apireferencesection == "get-authorities" ? " active" : "")}
                                            onClick={() => this.onClickReferenceSection("get-authorities")}
                                        >
                                            Get Authorities
                                        </h4>
                                        <h4
                                            className={"nav-item" + (this.state.apireferencesection == "get-authority" ? " active" : "")}
                                            onClick={() => this.onClickReferenceSection("get-authority")}
                                        >
                                            Get Authority
                                        </h4>
                                    </>
                                    : null
                            }
                            <h3
                                className={"nav-item" + (this.state.apireferencesection == "cpv" ? " active" : "")}
                                onClick={() => this.onClickReferenceSection("cpv")}
                            >
                                CPV
                            </h3>
                            {
                                this.state.apireferencesection == "cpv" || this.state.apireferencesection == "get-cpvs" ||
                                    this.state.apireferencesection == "get-cpv-tree"
                                    ?
                                    <>
                                        <h4
                                            className={"nav-item" + (this.state.apireferencesection == "get-cpvs" ? " active" : "")}
                                            onClick={() => this.onClickReferenceSection("get-cpvs")}
                                        >
                                            Get CPVs
                                        </h4>
                                        <h4
                                            className={"nav-item" + (this.state.apireferencesection == "get-cpv-tree" ? " active" : "")}
                                            onClick={() => this.onClickReferenceSection("get-cpv-tree")}
                                        >
                                            Get CPV tree
                                        </h4>
                                    </>
                                    : null
                            }
                            <h3
                                className={"nav-item" + (this.state.apireferencesection == "procedure" ? " active" : "")}
                                onClick={() => this.onClickReferenceSection("procedure")}
                            >
                                Procedure
                            </h3>
                            {
                                this.state.apireferencesection == "procedure" || this.state.apireferencesection == "get-procedures"
                                    ?
                                    <h4
                                        className={"nav-item" + (this.state.apireferencesection == "get-procedures" ? " active" : "")}
                                        onClick={() => this.onClickReferenceSection("get-procedures")}
                                    >
                                        Get Procedures
                                    </h4>
                                    : null
                            }
                            <h3
                                className={"nav-item" + (this.state.apireferencesection == "smart-filter" ? " active" : "")}
                                onClick={() => this.onClickReferenceSection("smart-filter")}
                            >
                                Smart Filter
                            </h3>
                            {
                                this.state.apireferencesection == "smart-filter" || this.state.apireferencesection == "get-smart-filters"
                                    ?
                                    <h4
                                        className={"nav-item" + (this.state.apireferencesection == "get-smart-filters" ? " active" : "")}
                                        onClick={() => this.onClickReferenceSection("get-smart-filters")}
                                    >
                                        Get Smart Filters
                                    </h4>
                                    : null
                            }
                            <h3
                                className={"nav-item" + (this.state.apireferencesection == "tender" ? " active" : "")}
                                onClick={() => this.onClickReferenceSection("tender")}
                            >
                                Tender
                            </h3>
                            {
                                this.state.apireferencesection == "tender" || this.state.apireferencesection == "get-tenders"
                                    ?
                                    <h4
                                        className={"nav-item" + (this.state.apireferencesection == "get-tenders" ? " active" : "")}
                                        onClick={() => this.onClickReferenceSection("get-tenders")}
                                    >
                                        Get Tenders
                                    </h4>
                                    : null
                            }
                        </Container>
                        <Container className='art-container api-reference'>
                            <Row className="title-row">
                                <Col md="auto" className='title-col'>
                                    <ul class='navigation'>
                                        <li>
                                            <a href={window.location.origin + "/"}>Home</a>
                                        </li>
                                        <li>
                                            <a href={window.location.origin + "/blog"}>Tender Notify Blog</a>
                                        </li>
                                        <li>
                                            API Reference
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="title-row">
                                <div md="auto">
                                    <hr class="line-title"></hr>
                                    <h1 className="title-art">API Reference</h1>
                                    <p className='text-below-title'>Explore the ever-evolving world of Tender Notify</p>
                                </div>
                                <div className="second-title-col" md="auto">
                                    <p className='published-day'>Updated on May 24 2023 10:00 AM • 5 minutes to read</p>
                                </div>
                            </Row>
                            <Row className="body-row api-reference">
                                <Col className="column-paragraph reference-margin" md="auto" onMouseOver={() => this.onMouseOverReferenceSection("introduction")} id="introduction">
                                    <h2 className="art-subtitle api-reference">Introduction</h2>
                                    <p className='art-paragraph'>
                                        Tender Notify API Reference Documentation offers a seamless experience for developers by providing two convenient methods for API interaction. The first method, HTTP requests, allows for flexibility and customization across various programming languages. Alternatively, the user-friendly <a href={swaggerUrl} target='_blank'>Swagger UI</a> simplifies API interaction with its visual representation of endpoints, enabling quick testing and hassle-free implementation. By offering these two methods, Tender Notify API caters to developers with varying expertise, ensuring a straightforward and efficient integration process for all users.
                                    </p>
                                </Col>
                                <Col className="column-paragraph api-reference reference-margin" md="auto" onMouseOver={() => this.onMouseOverReferenceSection("authentication")} id="authentication">
                                    <h2 className="art-subtitle api-reference">Authentication</h2>
                                    <p className='art-paragraph'>
                                        To access the resources provided by our API, it's essential to authenticate using an access token. First, you'll need to request an access token by making a call to the Get Access Token endpoint, including your API key in the request body, as shown below:
                                    </p>
                                    <div className='code-sample'>
                                        <div className='code-sample-body'>
                                            <Button onClick={() => this.onClickCopy(1, false)}><Icon className={this.state.codeCopied == 1 ? 'check' : ''} name={this.state.codeCopied == 1 ? 'check' : 'copy outline'}></Icon></Button>
                                            <pre className='code-sample-pre'>
                                                <code className='language-json'>
                                                    <code>
                                                        <span className='react-syntax-highlighter-line-number'>1</span>
                                                        <span className='react-syntax-highlighter-line-number'>2</span>
                                                        <span className='react-syntax-highlighter-line-number'>3</span>
                                                        <span className='react-syntax-highlighter-line-number'>4</span>
                                                        <span className='react-syntax-highlighter-line-number'>5</span>
                                                    </code>
                                                    <div className='json-code'>
                                                        <span>curl https://api.tendernotify.com/api/swagger/accesstoken/getaccesstoken \</span>
                                                        <p><span>{"  -H"}</span><span className='string'>{" \"Content-Type: application/json\""}</span><span>{" \\"}</span></p>
                                                        <p><span>{"  -d"}</span><span className='string'>{" '{"}</span></p>
                                                        <p><span className='string'>{"     \"api_key\": \""}</span><span className='number'>{"YOUR_API_KEY"}</span><span className='string'>{"\""}</span></p>
                                                        <p><span className='string'>{"   }'"}</span></p>
                                                    </div>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>
                                    <p className='art-paragraph'>
                                        The response will contain an access token that you can use for subsequent API requests. To utilize the access token, include it in the Authorization header for each request, preceded by the Bearer keyword, as demonstrated below:
                                    </p>
                                    <div className='code-sample'>
                                        <div className='code-sample-body'>
                                        <Button className='simple' onClick={() => this.onClickCopy(2, false)}><Icon className={this.state.codeCopied == 2 ? 'check' : ''} name={this.state.codeCopied == 2 ? 'check' : 'copy outline'}></Icon></Button>
                                        <pre className='code-sample-pre'>
                                            <code className='language-json'>
                                            <div className='json-code'>
                                                <p><span>Authorization: Bearer YOUR_ACCESS_TOKEN</span></p>
                                            </div>
                                            </code>
                                        </pre>
                                        </div>
                                    </div>
                                    <p className='art-paragraph'>
                                        Keep in mind that your API key is sensitive information, so avoid sharing it with others or exposing it in client-side code, such as browsers or apps.
                                    </p>
                                    </Col>
                                <Col className="column-paragraph api-reference reference-margin" md="auto" onMouseOver={() => this.onMouseOverReferenceSection("making-requests")} id="making-requests">
                                    <h2 className="art-subtitle api-reference">Making requests</h2>
                                    <p className='art-paragraph'>
                                        You can paste the command below into your terminal to run your first API request. Make sure to replace <span className='key'>$YOUR_ACCESS_TOKEN</span> with the access token that you get with your API key.
                                    </p>
                                    <div className='code-sample'>
                                        <div className='code-sample-body'>
                                            <Button onClick={() => this.onClickCopy(3, false)}><Icon className={this.state.codeCopied == 3 ? 'check' : ''} name={this.state.codeCopied == 3 ? 'check' : 'copy outline'}></Icon></Button>
                                            <pre className='code-sample-pre'>
                                                <code className='language-json'>
                                                    <code>
                                                        <span className='react-syntax-highlighter-line-number'>1</span>
                                                        <span className='react-syntax-highlighter-line-number'>2</span>
                                                        <span className='react-syntax-highlighter-line-number'>3</span>
                                                        <span className='react-syntax-highlighter-line-number'>4</span>
                                                        <span className='react-syntax-highlighter-line-number'>5</span>
                                                        <span className='react-syntax-highlighter-line-number'>6</span>
                                                    </code>
                                                    <div className='json-code'>
                                                        <span>curl https://api.tendernotify.com/api/swagger/cpv/getcpvs \</span>
                                                        <p><span>{"  -H"}</span><span className='string'>{" \"Content-Type: application/json\""}</span><span>{" \\"}</span></p>
                                                        <p><span>{"  -H"}</span><span className='string'>{" \"Authorization: Bearer"}</span><span className='number'>{" $YOUR_ACCESS_TOKEN"}</span><span className='string'>"</span><span>{" \\"}</span></p>
                                                        <p><span>{"  -d"}</span><span className='string'>{" '{"}</span></p>
                                                        <p><span className='string'>{"     \"code\": \"45000000-7\""}</span></p>
                                                        <p><span className='string'>{"   }'"}</span></p>
                                                    </div>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>
                                    <p className='art-paragraph'>
                                        You should get a response back that resembles the following:
                                    </p>
                                    <div className='code-sample'>
                                        <div className='code-sample-body'>
                                            <Button onClick={() => this.onClickCopy(4, true)}><Icon className={this.state.codeCopied == 4 ? 'check' : ''} name={this.state.codeCopied == 4 ? 'check' : 'copy outline'}></Icon></Button>
                                            <pre className='code-sample-pre'>
                                                <code className='language-json'>
                                                    <code>
                                                        <span className='react-syntax-highlighter-line-number'>1</span>
                                                        <span className='react-syntax-highlighter-line-number'>2</span>
                                                        <span className='react-syntax-highlighter-line-number'>3</span>
                                                        <span className='react-syntax-highlighter-line-number'>4</span>
                                                        <span className='react-syntax-highlighter-line-number'>5</span>
                                                        <span className='react-syntax-highlighter-line-number'>6</span>
                                                        <span className='react-syntax-highlighter-line-number'>7</span>
                                                        <span className='react-syntax-highlighter-line-number'>8</span>
                                                        <span className='react-syntax-highlighter-line-number'>9</span>
                                                        <span className='react-syntax-highlighter-line-number'>10</span>
                                                        <span className='react-syntax-highlighter-line-number'>11</span>
                                                        <span className='react-syntax-highlighter-line-number'>12</span>
                                                        <span className='react-syntax-highlighter-line-number'>13</span>
                                                        <span className='react-syntax-highlighter-line-number'>14</span>
                                                        <span className='react-syntax-highlighter-line-number'>15</span>
                                                        <span className='react-syntax-highlighter-line-number'>16</span>
                                                        <span className='react-syntax-highlighter-line-number'>17</span>
                                                        <span className='react-syntax-highlighter-line-number'>18</span>
                                                        <span className='react-syntax-highlighter-line-number'>19</span>
                                                        <span className='react-syntax-highlighter-line-number'>20</span>
                                                        <span className='react-syntax-highlighter-line-number'>21</span>
                                                        <span className='react-syntax-highlighter-line-number'>22</span>
                                                        <span className='react-syntax-highlighter-line-number'>23</span>
                                                        <span className='react-syntax-highlighter-line-number'>24</span>
                                                        <span className='react-syntax-highlighter-line-number'>25</span>
                                                        <span className='react-syntax-highlighter-line-number'>26</span>
                                                        <span className='react-syntax-highlighter-line-number'>27</span>
                                                    </code>
                                                    <div className='json-code'>
                                                        <span>{"{"}</span>
                                                        <p><span className='string'>{"   \"code\""}</span><span>:</span><span className='string'>{" \"45000000-7\""}</span><span>,</span></p>
                                                        <p><span className='string'>{"   \"description\""}</span><span>:</span><span className='string'>{" \"Construction work\""}</span><span>,</span></p>
                                                        <p><span className='string'>{"   \"cpvChildrenList\""}</span><span>{": ["}</span></p>
                                                        <p><span>{"      {"}</span></p>
                                                        <p><span className='string'>{"        \"code\""}</span><span>:</span><span className='string'>{" \"45100000-8\""}</span><span>,</span></p>
                                                        <p><span className='string'>{"        \"description\""}</span><span>:</span><span className='string'>{" \"Site preparation work\""}</span></p>
                                                        <p><span>{"      },"}</span></p>
                                                        <p><span>{"      {"}</span></p>
                                                        <p><span className='string'>{"        \"code\""}</span><span>:</span><span className='string'>{" \"45200000-9\""}</span><span>,</span></p>
                                                        <p><span className='string'>{"        \"description\""}</span><span>:</span><span className='string'>{" \"Works for complete or part construction and civil engineering work\""}</span></p>
                                                        <p><span>{"      },"}</span></p>
                                                        <p><span>{"      {"}</span></p>
                                                        <p><span className='string'>{"        \"code\""}</span><span>:</span><span className='string'>{" \"45300000-0\""}</span><span>,</span></p>
                                                        <p><span className='string'>{"        \"description\""}</span><span>:</span><span className='string'>{" \"Building installation work\""}</span></p>
                                                        <p><span>{"      },"}</span></p>
                                                        <p><span>{"      {"}</span></p>
                                                        <p><span className='string'>{"        \"code\""}</span><span>:</span><span className='string'>{" \"45400000-1\""}</span><span>,</span></p>
                                                        <p><span className='string'>{"        \"description\""}</span><span>:</span><span className='string'>{" \"Building completion work\""}</span></p>
                                                        <p><span>{"      },"}</span></p>
                                                        <p><span>{"      {"}</span></p>
                                                        <p><span className='string'>{"        \"code\""}</span><span>:</span><span className='string'>{" \"45500000-2\""}</span><span>,</span></p>
                                                        <p><span className='string'>{"        \"description\""}</span><span>:</span><span className='string'>{" \"Hire of construction and civil engineering machinery and equipment with operator\""}</span></p>
                                                        <p><span>{"      }"}</span></p>
                                                        <p><span>{"  ],"}</span></p>
                                                        <p><span className='string'>{"   \"message\""}</span><span>:</span><span className='string'>{" \"\""}</span></p>
                                                        <span>{"}"}</span>
                                                    </div>
                                                </code>
                                            </pre>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="column-paragraph api-reference reference-margin" md="auto" onMouseOver={() => this.onMouseOverReferenceSection("entities")} id="entities">
                                    <h2 className="art-subtitle api-reference">Entities</h2>
                                    <p className='art-paragraph'>
                                        The API provides access to several entities that you can use to retrieve data. Below is a list of the available entities and a brief description of each one:
                                    </p>
                                </Col>
                                <Col className="column-paragraph api-reference no-border" md="auto" onMouseOver={() => this.onMouseOverReferenceSection("authority")} id="authority">
                                    <h2 className="art-subtitle api-reference">Authority</h2>
                                    <p className='art-paragraph'>
                                        Represents a governing authority or organization responsible for overseeing tenders or procurement processes.
                                    </p>
                                </Col>
                                <div className='api-reference-methods' onMouseOver={() => this.onMouseOverReferenceSection("get-authorities")} id="get-authorities">
                                    <Col className="column-paragraph api-reference no-border left" md="auto">
                                        <h2 className="art-subtitle api-reference">Get Authorities</h2>
                                        <p className='art-paragraph'>
                                            Allows you to retrieve a list of Authorities that match a specific filter.
                                        </p>
                                        <h2 className="art-subtitle api-reference body">Request body</h2>
                                        <div className='param-table'>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>authorityFilter</span><span className='param-type'>string</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The filter is applied to the name of the authority. This parameter is optional, and if not specified, all authorities will be returned.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>pageNumber</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The page number of the results to retrieve. This parameter is optional and its default value is '1'.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>pageSize</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The number of results per page. This parameter is optional and its default value is '20'.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="column-paragraph api-reference no-border right" md="auto">
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Example request</h5>
                                                <Button onClick={() => this.onClickCopy(5, false)}><Icon className={this.state.codeCopied == 5 ? 'check' : ''} name={this.state.codeCopied == 5 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 5 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>curl https://api.tendernotify.com/api/swagger/authority/getauthorities \</span>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Content-Type: application/json\""}</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Authorization: Bearer"}</span><span className='number'>{" $YOUR_ACCESS_TOKEN"}</span><span className='string'>"</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -d"}</span><span className='string'>{" '{"}</span></p>
                                                            <p><span className='string'>{"     \"authorityFilter\": \"\","}</span></p>
                                                            <p><span className='string'>{"     \"pageNumber\": 1,"}</span></p>
                                                            <p><span className='string'>{"     \"pageSize\": 2"}</span></p>
                                                            <p><span className='string'>{"   }'"}</span></p>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Response</h5>
                                                <Button onClick={() => this.onClickCopy(6, true)}><Icon className={this.state.codeCopied == 6 ? 'check' : ''} name={this.state.codeCopied == 6 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 6 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                            <span className='react-syntax-highlighter-line-number'>9</span>
                                                            <span className='react-syntax-highlighter-line-number'>10</span>
                                                            <span className='react-syntax-highlighter-line-number'>11</span>
                                                            <span className='react-syntax-highlighter-line-number'>12</span>
                                                            <span className='react-syntax-highlighter-line-number'>13</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>{"{"}</span>
                                                            <p><span className='string'>{"   \"authorityDTOList\""}</span><span>{": ["}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"id\""}</span><span>:</span><span className='number'>{" 1"}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"name\""}</span><span>:</span><span className='string'>{" \"Authority 1\""}</span></p>
                                                            <p><span>{"      },"}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"id\""}</span><span>:</span><span className='number'>{" 2"}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"name\""}</span><span>:</span><span className='string'>{" \"Authority 2\""}</span></p>
                                                            <p><span>{"      }"}</span></p>
                                                            <p><span>{"  ],"}</span></p>
                                                            <p><span className='string'>{"   \"count\""}</span><span>:</span><span className='number'>{" 1500"}</span></p>
                                                            <span>{"}"}</span>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                                <div className='api-reference-methods with-border' onMouseOver={() => this.onMouseOverReferenceSection("get-authority")} id="get-authority">
                                    <Col className="column-paragraph api-reference no-border left" md="auto">
                                        <h2 className="art-subtitle api-reference">Get Authority</h2>
                                        <p className='art-paragraph'>
                                            Allows you to retrieve information about a specific authority based on its unique ID.
                                        </p>
                                        <h2 className="art-subtitle api-reference body">Request body</h2>
                                        <div className='param-table'>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>authorityId</span><span className='param-type'>integer</span><span className='param-required'>Required</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The authority ID is a unique identifier that is assigned to each authority in the system.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="column-paragraph api-reference no-border right" md="auto">
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Example request</h5>
                                                <Button onClick={() => this.onClickCopy(7, false)}><Icon className={this.state.codeCopied == 7 ? 'check' : ''} name={this.state.codeCopied == 7 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 7 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>curl https://api.tendernotify.com/api/swagger/authority/getauthority \</span>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Content-Type: application/json\""}</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Authorization: Bearer"}</span><span className='number'>{" $YOUR_ACCESS_TOKEN"}</span><span className='string'>"</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -d"}</span><span className='string'>{" '{"}</span></p>
                                                            <p><span className='string'>{"     \"authorityId\": 1"}</span></p>
                                                            <p><span className='string'>{"   }'"}</span></p>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Response</h5>
                                                <Button onClick={() => this.onClickCopy(8, true)}><Icon className={this.state.codeCopied == 8 ? 'check' : ''} name={this.state.codeCopied == 8 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 8 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                            <span className='react-syntax-highlighter-line-number'>9</span>
                                                            <span className='react-syntax-highlighter-line-number'>10</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>{"{"}</span>
                                                            <p><span className='string'>{"   \"name\""}</span><span>:</span><span className='string'>{" \"Authority name\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"   \"description\""}</span><span>:</span><span className='string'>{" \"Authority description\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"   \"department\""}</span><span>:</span><span className='string'>{" \"Authority department\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"   \"organizationNumber\""}</span><span>:</span><span className='string'>{" \"Authority organization number\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"   \"url\""}</span><span>:</span><span className='string'>{" \"Authority url\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"   \"externalLinks\""}</span><span>{": ["}</span></p>
                                                            <p><span className='string'>{"        \"http://www.authorityexternallink.com\""}</span></p>
                                                            <p><span>{"  ]"}</span></p>
                                                            <span>{"}"}</span>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                                <Col className="column-paragraph api-reference" md="auto" onMouseOver={() => this.onMouseOverReferenceSection("cpv")} id="cpv">
                                    <h2 className="art-subtitle">CPV</h2>
                                    <p className='art-paragraph'>
                                        Stands for Common Procurement Vocabulary, and represents a standardized classification system used to describe procurement contracts and tenders.
                                    </p>
                                </Col>
                                <div className='api-reference-methods' onMouseOver={() => this.onMouseOverReferenceSection("get-cpvs")} id="get-cpvs">
                                    <Col className="column-paragraph api-reference no-border left" md="auto">
                                        <h2 className="art-subtitle api-reference">Get CPVs</h2>
                                        <p className='art-paragraph'>
                                            Allows you to retrieve a list of CPVs that match a specific filter.
                                        </p>
                                        <h2 className="art-subtitle api-reference body">Request body</h2>
                                        <div className='param-table'>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>cpvFilter</span><span className='param-type'>string</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The filter is applied to the description of the CPV. This parameter is optional, and if not specified, all CPVs will be returned.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>pageNumber</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The page number of the results to retrieve. This parameter is optional and its default value is '1'.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>pageSize</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The number of results per page. This parameter is optional and its default value is '20'.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="column-paragraph api-reference no-border right" md="auto">
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Example request</h5>
                                                <Button onClick={() => this.onClickCopy(9, false)}><Icon className={this.state.codeCopied == 9 ? 'check' : ''} name={this.state.codeCopied == 9 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 9 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>curl https://api.tendernotify.com/api/swagger/cpv/getcpvs \</span>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Content-Type: application/json\""}</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Authorization: Bearer"}</span><span className='number'>{" $YOUR_ACCESS_TOKEN"}</span><span className='string'>"</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -d"}</span><span className='string'>{" '{"}</span></p>
                                                            <p><span className='string'>{"     \"cpvFilter\": \"\","}</span></p>
                                                            <p><span className='string'>{"     \"pageNumber\": 1,"}</span></p>
                                                            <p><span className='string'>{"     \"pageSize\": 2"}</span></p>
                                                            <p><span className='string'>{"   }'"}</span></p>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Response</h5>
                                                <Button onClick={() => this.onClickCopy(10, true)}><Icon className={this.state.codeCopied == 10 ? 'check' : ''} name={this.state.codeCopied == 10 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 10 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                            <span className='react-syntax-highlighter-line-number'>9</span>
                                                            <span className='react-syntax-highlighter-line-number'>10</span>
                                                            <span className='react-syntax-highlighter-line-number'>11</span>
                                                            <span className='react-syntax-highlighter-line-number'>12</span>
                                                            <span className='react-syntax-highlighter-line-number'>13</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>{"{"}</span>
                                                            <p><span className='string'>{"   \"cpvDTOList\""}</span><span>{": ["}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"code\""}</span><span>:</span><span className='string'>{" \"03000000-1\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"description\""}</span><span>:</span><span className='string'>{" \"Agricultural, farming, fishing, forestry and related products\""}</span></p>
                                                            <p><span>{"      },"}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"code\""}</span><span>:</span><span className='string'>{" \"03100000-2\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"description\""}</span><span>:</span><span className='string'>{" \"Agricultural and horticultural products\""}</span></p>
                                                            <p><span>{"      }"}</span></p>
                                                            <p><span>{"  ],"}</span></p>
                                                            <p><span className='string'>{"   \"count\""}</span><span>:</span><span className='number'>{" 1500"}</span></p>
                                                            <span>{"}"}</span>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                                <div className='api-reference-methods with-border' onMouseOver={() => this.onMouseOverReferenceSection("get-cpv-tree")} id="get-cpv-tree">
                                    <Col className="column-paragraph api-reference no-border left" md="auto">
                                        <h2 className="art-subtitle api-reference">Get CPV tree</h2>
                                        <p className='art-paragraph'>
                                            Allows you to retrieve the child nodes of a specified CPV by its code.
                                        </p>
                                        <h2 className="art-subtitle api-reference body">Request body</h2>
                                        <div className='param-table'>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>code</span><span className='param-type'>string</span><span className='param-required'>Required</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The code is applied to the description of the CPV.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="column-paragraph api-reference no-border right" md="auto">
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Example request</h5>
                                                <Button onClick={() => this.onClickCopy(11, false)}><Icon className={this.state.codeCopied == 11 ? 'check' : ''} name={this.state.codeCopied == 11 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 11 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>curl https://api.tendernotify.com/api/swagger/cpv/getcpvtree \</span>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Content-Type: application/json\""}</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Authorization: Bearer"}</span><span className='number'>{" $YOUR_ACCESS_TOKEN"}</span><span className='string'>"</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -d"}</span><span className='string'>{" '{"}</span></p>
                                                            <p><span className='string'>{"     \"code\": \"45314300-4\""}</span></p>
                                                            <p><span className='string'>{"   }'"}</span></p>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Response</h5>
                                                <Button onClick={() => this.onClickCopy(12, true)}><Icon className={this.state.codeCopied == 12 ? 'check' : ''} name={this.state.codeCopied == 12 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 12 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                            <span className='react-syntax-highlighter-line-number'>9</span>
                                                            <span className='react-syntax-highlighter-line-number'>10</span>
                                                            <span className='react-syntax-highlighter-line-number'>11</span>
                                                            <span className='react-syntax-highlighter-line-number'>12</span>
                                                            <span className='react-syntax-highlighter-line-number'>13</span>
                                                            <span className='react-syntax-highlighter-line-number'>14</span>
                                                            <span className='react-syntax-highlighter-line-number'>15</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>{"{"}</span>
                                                            <p><span className='string'>{"   \"code\""}</span><span>:</span><span className='string'>{" \"45314300-4\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"   \"description\""}</span><span>:</span><span className='string'>{" \"Installation of cable infrastructure\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"   \"cpvChildrenList\""}</span><span>{": ["}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"code\""}</span><span>:</span><span className='string'>{" \"45314310-7\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"description\""}</span><span>:</span><span className='string'>{" \"Installation of cable laying\""}</span></p>
                                                            <p><span>{"      },"}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"code\""}</span><span>:</span><span className='string'>{" \"45314320-0\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"description\""}</span><span>:</span><span className='string'>{" \"Installation of computer cabling\""}</span></p>
                                                            <p><span>{"      }"}</span></p>
                                                            <p><span>{"  ],"}</span></p>
                                                            <p><span className='string'>{"   \"message\""}</span><span>:</span><span className='string'>{" \"\""}</span></p>
                                                            <span>{"}"}</span>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                                <Col className="column-paragraph api-reference " md="auto" onMouseOver={() => this.onMouseOverReferenceSection("procedure")} id="procedure">
                                    <h2 className="art-subtitle">Procedure</h2>
                                    <p className='art-paragraph'>
                                        Represents the type of procurement process that is being followed, such as open procedure, restricted procedure, negotiated procedure, or competitive dialogue.
                                    </p>
                                </Col>
                                <div className='api-reference-methods' onMouseOver={() => this.onMouseOverReferenceSection("get-procedures")} id="get-procedures">
                                    <Col className="column-paragraph api-reference no-border left" md="auto">
                                        <h2 className="art-subtitle api-reference">Get Procedures</h2>
                                        <p className='art-paragraph'>
                                            Allows you to retrieve a list of Procedures that match a specific filter.
                                        </p>
                                        <h2 className="art-subtitle api-reference body">Request body</h2>
                                        <div className='param-table'>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>procedureFilter</span><span className='param-type'>string</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The filter is applied to the name of the Procedure. This parameter is optional, and if not specified, all Procedures will be returned.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>pageNumber</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The page number of the results to retrieve. This parameter is optional and its default value is '1'.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>pageSize</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The number of results per page. This parameter is optional and its default value is '20'.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="column-paragraph api-reference no-border right" md="auto">
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Example request</h5>
                                                <Button onClick={() => this.onClickCopy(13, false)}><Icon className={this.state.codeCopied == 13 ? 'check' : ''} name={this.state.codeCopied == 13 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 13 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>curl https://api.tendernotify.com/api/swagger/procedure/getprocedures \</span>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Content-Type: application/json\""}</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Authorization: Bearer"}</span><span className='number'>{" $YOUR_ACCESS_TOKEN"}</span><span className='string'>"</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -d"}</span><span className='string'>{" '{"}</span></p>
                                                            <p><span className='string'>{"     \"procedureFilter\": \"\","}</span></p>
                                                            <p><span className='string'>{"     \"pageNumber\": 1,"}</span></p>
                                                            <p><span className='string'>{"     \"pageSize\": 2"}</span></p>
                                                            <p><span className='string'>{"   }'"}</span></p>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Response</h5>
                                                <Button onClick={() => this.onClickCopy(14, true)}><Icon className={this.state.codeCopied == 14 ? 'check' : ''} name={this.state.codeCopied == 14 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 14 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                            <span className='react-syntax-highlighter-line-number'>9</span>
                                                            <span className='react-syntax-highlighter-line-number'>10</span>
                                                            <span className='react-syntax-highlighter-line-number'>11</span>
                                                            <span className='react-syntax-highlighter-line-number'>12</span>
                                                            <span className='react-syntax-highlighter-line-number'>13</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>{"{"}</span>
                                                            <p><span className='string'>{"   \"procedureDTOList\""}</span><span>{": ["}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"id\""}</span><span>:</span><span className='number'>{" 1"}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"procedure\""}</span><span>:</span><span className='string'>{" \"Procedure 1\""}</span></p>
                                                            <p><span>{"      },"}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"id\""}</span><span>:</span><span className='number'>{" 2"}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"procedure\""}</span><span>:</span><span className='string'>{" \"Procedure 2\""}</span></p>
                                                            <p><span>{"      }"}</span></p>
                                                            <p><span>{"  ],"}</span></p>
                                                            <p><span className='string'>{"   \"count\""}</span><span>:</span><span className='number'>{" 1500"}</span></p>
                                                            <span>{"}"}</span>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                                <Col className="column-paragraph api-reference" md="auto" onMouseOver={() => this.onMouseOverReferenceSection("smart-filter")} id="smart-filter">
                                    <h2 className="art-subtitle">Smart Filter</h2>
                                    <p className='art-paragraph'>
                                        Represents a filter that can be applied to search queries to refine the results based on specific criteria.
                                    </p>
                                </Col>
                                <div className='api-reference-methods' onMouseOver={() => this.onMouseOverReferenceSection("get-smart-filters")} id="get-smart-filters">
                                    <Col className="column-paragraph api-reference no-border left" md="auto">
                                        <h2 className="art-subtitle api-reference">Get Smart Filters</h2>
                                        <p className='art-paragraph'>
                                            Allows you to retrieve a list of Smart Filters that belong to you.
                                        </p>
                                    </Col>
                                    <Col className="column-paragraph api-reference no-border right" md="auto">
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Example request</h5>
                                                <Button onClick={() => this.onClickCopy(15, false)}><Icon className={this.state.codeCopied == 15 ? 'check' : ''} name={this.state.codeCopied == 15 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 15 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>curl https://api.tendernotify.com/api/swagger/smartfilter/getsmartfilters \</span>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Authorization: Bearer"}</span><span className='number'>{" $YOUR_ACCESS_TOKEN"}</span><span className='string'>"</span><span>{" \\"}</span></p>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Response</h5>
                                                <Button onClick={() => this.onClickCopy(16, true)}><Icon className={this.state.codeCopied == 16 ? 'check' : ''} name={this.state.codeCopied == 16 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 16 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                            <span className='react-syntax-highlighter-line-number'>9</span>
                                                            <span className='react-syntax-highlighter-line-number'>10</span>
                                                            <span className='react-syntax-highlighter-line-number'>11</span>
                                                            <span className='react-syntax-highlighter-line-number'>12</span>
                                                            <span className='react-syntax-highlighter-line-number'>13</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>{"{"}</span>
                                                            <p><span className='string'>{"   \"smartFilterDTOList\""}</span><span>{": ["}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"id\""}</span><span>:</span><span className='number'>{" 1"}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"name\""}</span><span>:</span><span className='string'>{" \"Smart Filter 1\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"filterfrontend\""}</span><span>:</span><span className='string'>{" \"zr1xqn7e7q contains '%03100000-2-Agricultural and horticultural products%'\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"enabled\""}</span><span>:</span><span className='boolean'>{" true"}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"createdon\""}</span><span>:</span><span className='string'>{" \"2023-04-08T20:07:48.195978\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"lastupdate\""}</span><span>:</span><span className='string'>{" \"2023-04-08T20:07:48.195978\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"groupNameList\""}</span><span>{": []"}</span></p>
                                                            <p><span>{"      }"}</span></p>
                                                            <p><span>{"  ],"}</span></p>
                                                            <p><span className='string'>{"   \"count\""}</span><span>:</span><span className='number'>{" 1"}</span><span>,</span></p>
                                                            <p><span className='string'>{"   \"totalEnabled\""}</span><span>:</span><span className='number'>{" 1"}</span></p>
                                                            <span>{"}"}</span>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                                <Col className="column-paragraph api-reference" md="auto" onMouseOver={() => this.onMouseOverReferenceSection("tender")} id="tender">
                                    <h2 className="art-subtitle">Tender</h2>
                                    <p className='art-paragraph'>
                                        Represents a procurement process that solicits bids from suppliers or vendors for the provision of goods or services.
                                    </p>
                                </Col>
                                <div className='api-reference-methods' onMouseOver={() => this.onMouseOverReferenceSection("get-tenders")} id="get-tenders">
                                    <Col className="column-paragraph api-reference no-border left" md="auto">
                                        <h2 className="art-subtitle api-reference">Get Tenders</h2>
                                        <p className='art-paragraph'>
                                            Allows you to retrieve Tenders based on various filters. These filters include tender name, system ID, reference, status, procedure, authority, CPV, and tracked tender status, among others.
                                        </p>
                                        <h2 className="art-subtitle api-reference body">Request body</h2>
                                        <div className='param-table'>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>tenderFiltersDTO</span><span className='param-type'>object</span><span className='param-required'>Required</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>Contains several properties corresponding to the various filters that can be applied to the query.</p>
                                                    <div className='object-param-table'>
                                                        <div className='object-param-row'>
                                                            <div className='param-row-header'>
                                                                <span className='param-name'>referenceNameSystemId</span><span className='param-type'>string</span><span className='param-type'>Optional</span>
                                                            </div>
                                                            <div className='param-row-body'>
                                                                <p>The filter is applied to the name, system Id and reference of the Tender.  This parameter is optional and its default value is ' '.</p>
                                                            </div>
                                                        </div>
                                                        <div className='object-param-row'>
                                                            <div className='param-row-header'>
                                                                <span className='param-name'>status</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                            </div>
                                                            <div className='param-row-body'>
                                                                <p>This property is used to filter tenders based on their current status. This parameter is optional and its default value is '1'.</p>
                                                            </div>
                                                        </div>
                                                        <div className='object-param-row'>
                                                            <div className='param-row-header'>
                                                                <span className='param-name'>fromPublicationDate</span><span className='param-type'>date time</span><span className='param-type'>Optional</span>
                                                            </div>
                                                            <div className='param-row-body'>
                                                                <p>This property is used to filter tenders based on their publication date. It will return tenders that were published on or after the specified date.</p>
                                                            </div>
                                                        </div>
                                                        <div className='object-param-row'>
                                                            <div className='param-row-header'>
                                                                <span className='param-name'>toPublicationDate</span><span className='param-type'>date time</span><span className='param-type'>Optional</span>
                                                            </div>
                                                            <div className='param-row-body'>
                                                                <p>This property is used to filter tenders based on their publication date. It will return tenders that were published on or before the specified date.</p>
                                                            </div>
                                                        </div>
                                                        <div className='object-param-row'>
                                                            <div className='param-row-header'>
                                                                <span className='param-name'>procedureId</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                            </div>
                                                            <div className='param-row-body'>
                                                                <p>This property is used to filter tenders based on the procurement procedure ID. This parameter is optional and its default value is '0'.</p>
                                                            </div>
                                                        </div>
                                                        <div className='object-param-row'>
                                                            <div className='param-row-header'>
                                                                <span className='param-name'>authorityId</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                            </div>
                                                            <div className='param-row-body'>
                                                                <p>This property is used to filter tenders based on the ID of the contracting authority that issued the tender. This parameter is optional and its default value is '0'.</p>
                                                            </div>
                                                        </div>
                                                        <div className='object-param-row'>
                                                            <div className='param-row-header'>
                                                                <span className='param-name'>cpvId</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                            </div>
                                                            <div className='param-row-body'>
                                                                <p>This property is used to filter tenders based on the CPV code assigned to the tender. This parameter is optional and its default value is '0'.</p>
                                                            </div>
                                                        </div>
                                                        <div className='object-param-row'>
                                                            <div className='param-row-header'>
                                                                <span className='param-name'>trackedStatus</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                            </div>
                                                            <div className='param-row-body'>
                                                                <p>This property is used to filter tenders based on the tracking status set by you. This parameter is optional and its default value is '1'.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>smartFilterId</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>Specifies the ID of the Smart Filter to apply to the query. This parameter is optional and its default value is '0'.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>pageNumber</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The page number of the results to retrieve. This parameter is optional and its default value is '1'.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>pageSize</span><span className='param-type'>integer</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>The number of results per page. This parameter is optional and its default value is '10'.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>orderAsc</span><span className='param-type'>boolean</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>Specifies whether to sort the results in ascending order. This parameter is optional and its default value is 'true'.</p>
                                                </div>
                                            </div>
                                            <div className='param-row'>
                                                <div className='param-row-header'>
                                                    <span className='param-name'>orderBy</span><span className='param-type'>string</span><span className='param-type'>Optional</span>
                                                </div>
                                                <div className='param-row-body'>
                                                    <p>Specifies the property by which to order the results. This parameter is optional and its default value is ' '.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="column-paragraph api-reference no-border right" md="auto">
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Example request</h5>
                                                <Button onClick={() => this.onClickCopy(17, false)}><Icon className={this.state.codeCopied == 17 ? 'check' : ''} name={this.state.codeCopied == 17 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 17 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                            <span className='react-syntax-highlighter-line-number'>9</span>
                                                            <span className='react-syntax-highlighter-line-number'>10</span>
                                                            <span className='react-syntax-highlighter-line-number'>11</span>
                                                            <span className='react-syntax-highlighter-line-number'>12</span>
                                                            <span className='react-syntax-highlighter-line-number'>13</span>
                                                            <span className='react-syntax-highlighter-line-number'>14</span>
                                                            <span className='react-syntax-highlighter-line-number'>15</span>
                                                            <span className='react-syntax-highlighter-line-number'>16</span>
                                                            <span className='react-syntax-highlighter-line-number'>17</span>
                                                            <span className='react-syntax-highlighter-line-number'>18</span>
                                                            <span className='react-syntax-highlighter-line-number'>19</span>
                                                            <span className='react-syntax-highlighter-line-number'>20</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>curl https://api.tendernotify.com/api/swagger/tender/gettenders \</span>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Content-Type: application/json\""}</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -H"}</span><span className='string'>{" \"Authorization: Bearer"}</span><span className='number'>{" $YOUR_ACCESS_TOKEN"}</span><span className='string'>"</span><span>{" \\"}</span></p>
                                                            <p><span>{"  -d"}</span><span className='string'>{" '{"}</span></p>
                                                            <p><span className='string'>{"     \"tenderFiltersDTO\": {"}</span></p>
                                                            <p><span className='string'>{"       \"referenceNameSystemId\": \"\","}</span></p>
                                                            <p><span className='string'>{"       \"status\": 1,"}</span></p>
                                                            <p><span className='string'>{"       \"fromPublicationDate\": \"2023-04-21T16:27:52.509Z\","}</span></p>
                                                            <p><span className='string'>{"       \"toPublicationDate\": \"2023-04-21T16:27:52.509Z\","}</span></p>
                                                            <p><span className='string'>{"       \"procedureId\": 0,"}</span></p>
                                                            <p><span className='string'>{"       \"authorityId\": 0,"}</span></p>
                                                            <p><span className='string'>{"       \"cpvId\": 0,"}</span></p>
                                                            <p><span className='string'>{"       \"trackedStatus\": 1"}</span></p>
                                                            <p><span className='string'>{"   },"}</span></p>
                                                            <p><span className='string'>{"     \"smartFilterId\": 0,"}</span></p>
                                                            <p><span className='string'>{"     \"pageNumber\": 1"}</span></p>
                                                            <p><span className='string'>{"     \"pageSize\": 2,"}</span></p>
                                                            <p><span className='string'>{"     \"orderAsc\": true,"}</span></p>
                                                            <p><span className='string'>{"     \"orderBy\": \"\""}</span></p>
                                                            <p><span className='string'>{"   }'"}</span></p>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                        <div className='code-sample'>
                                            <div className='code-sample-header'>
                                                <h5>Response</h5>
                                                <Button onClick={() => this.onClickCopy(18, true)}><Icon className={this.state.codeCopied == 18 ? 'check' : ''} name={this.state.codeCopied == 18 ? 'check' : 'copy outline'}></Icon>{this.state.codeCopied == 18 ? ' Copied' : 'Copy'}</Button>
                                            </div>
                                            <div className='code-sample-body'>
                                                <pre className='code-sample-pre'>
                                                    <code className='language-json'>
                                                        <code>
                                                            <span className='react-syntax-highlighter-line-number'>1</span>
                                                            <span className='react-syntax-highlighter-line-number'>2</span>
                                                            <span className='react-syntax-highlighter-line-number'>3</span>
                                                            <span className='react-syntax-highlighter-line-number'>4</span>
                                                            <span className='react-syntax-highlighter-line-number'>5</span>
                                                            <span className='react-syntax-highlighter-line-number'>6</span>
                                                            <span className='react-syntax-highlighter-line-number'>7</span>
                                                            <span className='react-syntax-highlighter-line-number'>8</span>
                                                            <span className='react-syntax-highlighter-line-number'>9</span>
                                                            <span className='react-syntax-highlighter-line-number'>10</span>
                                                            <span className='react-syntax-highlighter-line-number'>11</span>
                                                            <span className='react-syntax-highlighter-line-number'>12</span>
                                                            <span className='react-syntax-highlighter-line-number'>13</span>
                                                        </code>
                                                        <div className='json-code'>
                                                            <span>{"{"}</span>
                                                            <p><span className='string'>{"   \"tenderDTOList\""}</span><span>{": ["}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"id\""}</span><span>:</span><span className='number'>{" 1"}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"systemId\""}</span><span>:</span><span className='string'>{" \"12345\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"tenderReference\""}</span><span>:</span><span className='string'>{" \"Tender reference\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"tenderName\""}</span><span>:</span><span className='string'>{" \"Tender name\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"publicationDate\""}</span><span>:</span><span className='string'>{" \"2023-03-22T00:00:00\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"responseDeadLine\""}</span><span>:</span><span className='string'>{" \"2023-04-12T16:00:00\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"procedure\""}</span><span>:</span><span className='string'>{" \"Procedure 1\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"authorityName\""}</span><span>:</span><span className='string'>{" \"Authority 1\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"country\""}</span><span>:</span><span className='string'>{" \"Ireland\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"status\""}</span><span>:</span><span className='string'>{" \"Published\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"tracked\""}</span><span>:</span><span className='boolean'>{" true"}</span></p>
                                                            <p><span>{"      },"}</span></p>
                                                            <p><span>{"      {"}</span></p>
                                                            <p><span className='string'>{"        \"id\""}</span><span>:</span><span className='number'>{" 2"}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"systemId\""}</span><span>:</span><span className='string'>{" \"12346\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"tenderReference\""}</span><span>:</span><span className='string'>{" \"Tender reference\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"tenderName\""}</span><span>:</span><span className='string'>{" \"Tender name\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"publicationDate\""}</span><span>:</span><span className='string'>{" \"2023-03-22T00:00:00\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"responseDeadLine\""}</span><span>:</span><span className='string'>{" \"2023-04-12T16:00:00\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"procedure\""}</span><span>:</span><span className='string'>{" \"Procedure 2\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"authorityName\""}</span><span>:</span><span className='string'>{" \"Authority 3\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"country\""}</span><span>:</span><span className='string'>{" \"Slovakia\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"status\""}</span><span>:</span><span className='string'>{" \"Expired\""}</span><span>,</span></p>
                                                            <p><span className='string'>{"        \"tracked\""}</span><span>:</span><span className='boolean'>{" false"}</span></p>
                                                            <p><span>{"      }"}</span></p>
                                                            <p><span>{"  ],"}</span></p>
                                                            <p><span className='string'>{"   \"count\""}</span><span>:</span><span className='number'>{" 350"}</span></p>
                                                            <span>{"}"}</span>
                                                        </div>
                                                    </code>
                                                </pre>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </Container>
                    </Container>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiReference);