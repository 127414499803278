import React, { Component } from 'react';
import { Button, Icon, Dropdown } from "semantic-ui-react";
import Spinner from './Spinner';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { connect } from "react-redux";
import {
    signUp,
    changeSignUpStates,
    GetSignUpGeneralData
} from "../actions/userActions";
import {
    changeCurrentSection
} from "../actions/generalActions";
import { Snackbar } from '@material/react-snackbar';
import Flag_Spain from '../images/Flag_Spain.svg';
import Flag_Ireland from '../images/Flag_Ireland.svg';
import Flag_Estonia from '../images/Flag_Estonia.svg';
import ReactGA4 from 'react-ga4';
import Footer from './Footer';

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const languageOptions = [
    { 
        key: 1, 
        value: <div class="item"><img src={Flag_Ireland}/><span>English</span></div>, 
        text: <div class="item"><img src={Flag_Ireland}/><span>English</span></div> 
    },
    { 
        key: 2, 
        value: <div class="item"><img src={Flag_Estonia}/><span>Estonian</span></div>, 
        text: <div class="item"><img src={Flag_Estonia}/><span>Estonian</span></div> 
    },
    { 
        key: 3, 
        value: <div class="item"><img src={Flag_Spain}/><span>Spanish</span></div>, 
        text: <div class="item"><img src={Flag_Spain}/><span>Spanish</span></div> 
    }
];

class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: "",
            firstName: "",
            lastName: "",
            mail: "",
            workPhone: "",
            language: 1,
            languageOption: 0,

            sector: "",
            sectorChecked: null,
            userInputError: false,
            userInputErrorType: 0,
            firstNameInputError: false,
            lastNameInputError: false,
            mailInputError: false,
            mailInputErrorType: 0,
            workPhoneInputError: false,
            emailing: false,
            privacyPolicy: false,
            privacyPolicyInputError: false,

            scrolled: false,

            userIconName:"",
            mailIconName:""
        }

        this.onChange = this.onChange.bind(this);
        this.onChangeSector = this.onChangeSector.bind(this);
        this.onClickSubmit = this.onClickSubmit.bind(this);
        this.checkSubmit = this.checkSubmit.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onClickCheckBox = this.onClickCheckBox.bind(this);
        this.checkUserName = this.checkUserName.bind(this);
        this.checkMail = this.checkMail.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);

        this.changeSection = this.changeSection.bind(this);
    }

    onChange(e, input) {
        if (input === "FirstName") {
            const re = /^([a-zA-Z]+\s?)*$/;
            if ((String(e.target.value).toLowerCase().length <= 50 && re.test(String(e.target.value).toLowerCase())) || e.target.value == "")
                this.setState({ firstName: e.target.value, firstNameInputError: false });
        }
        else
            if (input === "LastName") {
                const re = /^([a-zA-Z]+\s?)*$/;
                if ((String(e.target.value).toLowerCase().length <= 50 && re.test(String(e.target.value).toLowerCase())))
                    this.setState({ lastName: e.target.value, lastNameInputError: false });
            }
            else
                if (input === "Mail")
                    this.setState({ mail: e.target.value, mailInputError: false, mailInputErrorType: 0, mailIconName: "" });
                else
                    if (input === "WorkPhone")
                        this.setState({ workPhone: e.target.value, workPhoneInputError: false, workPhoneInputErrorType: 0 });
                    else
                        if (input === "UserName") {
                            const re = /^[a-zA-Z0-9_]*$/;
                            if (String(e.target.value).toLowerCase().length <= 50 && re.test(String(e.target.value).toLowerCase()))
                                this.setState({ userName: e.target.value, userInputError: false, userIconName: "" });
                        }
    }

    onChangeSector(sector, index) {
        if (this.state.sectorChecked == index)
            this.setState({ sector: "", sectorChecked: null });
        else
            this.setState({ sector: sector, sectorChecked: index });
    }

    onChangePhone(value) {
        if (value != undefined)
            this.setState({ workPhone: value, workPhoneInputError: false, workPhoneInputErrorType: 0 });
    }

    onClickSubmit() {
        if (!this.checkSubmit()) {
            let user = {
                UserName: this.state.userName.trim(),
                FirstName: this.state.firstName.trim(),
                LastName: this.state.lastName.trim(),
                Email: this.state.mail,
                Phone: this.state.workPhone,
                Sector: this.state.sector,
                MktEmailing: this.state.emailing,
                LegitimateInterest: this.state.privacyPolicy,
                Language: this.state.language
            };

            this.props.signUp(user);
            this.setState
                ({
                    userName: "",
                    firstName: "",
                    lastName: "",
                    mail: "",
                    workPhone: "",
                    sector: "",
                    language: 1,
                    languageOption: 0,
                    sectorChecked: null,
                    emailing: false,
                    privacyPolicy: false,
                });
        }
        else {
            let existingUserName = this.props.signUpGeneralDataDTO.userNameList.some(x => x == this.state.userName);

            let existingMail = this.props.signUpGeneralDataDTO.mailList.some(x => x == this.state.mail);

            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let validMail = regex.test(String(this.state.mail).toLowerCase());

            let validPhoneNumber = isValidPhoneNumber(this.state.workPhone);

            this.setState({
                userInputError: this.state.userName != "" && !existingUserName ? false : true,
                userInputErrorType: this.state.userName != "" && !existingUserName ? 0 : (this.state.userName == "" ? 0 : 1),
                firstNameInputError: this.state.firstName != "" ? false : true,
                lastNameInputError: this.state.lastName != "" ? false : true,
                mailInputError: this.state.mail != "" && !existingMail && validMail ? false : true,
                mailInputErrorType: this.state.mail != "" && !existingMail && validMail ? 0 : (this.state.mail == "" ? 0 : (existingMail ? 1 : 2)),
                workPhoneInputError: this.state.workPhone != "" && validPhoneNumber ? false : true,
                workPhoneInputErrorType: this.state.workPhone != "" && validPhoneNumber ? 0 : (this.state.workPhone == "" ? 0 : 1),
                privacyPolicyInputError: this.state.privacyPolicy ? false : true
            })
        }
    }

    checkSubmit() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let flag = re.test(String(this.state.mail).toLowerCase());

        let firstName = this.state.firstName;
        firstName = firstName.trim();

        if (this.state.userName !== "" && firstName !== "" && this.state.lastName !== "" && flag && isValidPhoneNumber(this.state.workPhone)
            && this.checkUserNameAndMail() && this.state.privacyPolicy)
            return false
        else
            return true;
    }

    checkUserNameAndMail() {
        if (this.props.signUpGeneralDataDTO != null)
            return !this.props.signUpGeneralDataDTO.mailList.some(x => x == this.state.mail ) || !this.props.signUpGeneralDataDTO.userNameList.some(x => x == this.state.userName);
        else
            return false;
    }

    onClickCheckBox(type) {
        if (type === "Emailing")
            this.setState({ emailing: !this.state.emailing });
        else
            if (type === "PrivacyPolicy")
                this.setState({ privacyPolicy: !this.state.privacyPolicy, privacyPolicyInputError: false });
    }

    componentDidMount() {
        if (!this.state.scrolled) {
            this.setState({ scrolled: true });
            let element = document.getElementById("sign-up");
            if (element != null)
                element.scrollIntoView();
        }
    }

    checkUserName(){
        if (this.props.signUpGeneralDataDTO != null && !this.props.signUpGeneralDataDTO.userNameList.some(x => x == this.state.userName))
            this.setState({ userIconName: 'check circle' });
        else
            this.setState({ userIconName: 'times circle', userInputErrorType: 1 });
    }

    checkMail(){
        if (this.state.mail != "") {
            if(re.test(String(this.state.mail).toLowerCase())){
                if (this.props.signUpGeneralDataDTO != null && !this.props.signUpGeneralDataDTO.mailList.some(x => x == this.state.mail))
                    this.setState({ mailIconName: 'check circle' });
                else
                    this.setState({ mailIconName: 'times circle', mailInputErrorType: 1 });
            }
            else
                this.setState({ mailIconName: 'times circle',  mailInputErrorType: 2});
        }
    }

    changeLanguage(e, data) {
        let selectedValue = data.value;
        let options = data.options;
        let i = 0;

        while (i < options.length && selectedValue != options[i].value)
            i++;

        if (i < options.length)
            this.setState({ language: options[i].key, languageOption: i });
    }

    changeSection() {
        this.props.changeCurrentSection("Sign-Up");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Sign-Up", page: '/Sign-Up' });
    }

    render() {
        if(window.sessionStorage.getItem("planId") == null)
            window.sessionStorage.setItem("planId", 1);

        if (this.props.fetchingSignUpGeneralData == false && this.props.signUpGeneralDataDTO == null)
            this.props.GetSignUpGeneralData();

        if (this.props.fetchedSignUpGeneralData) {
            if (this.props.fetchingSignUp == false)
                return (
                    <>
                        <div id="sign-up" onMouseOver={this.props.currentSection != "Sign-Up" ? this.changeSection : () => { }}>
                            <div className="container sign-up-form">
                                <div class="row justify-content-center">
                                    <div class="col-lg-10">
                                        <div class="containerHeader">
                                            <h2 class="title">Sign Up</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-lg-10">
                                        <div class="containerForm">
                                            <div class="row form-group">
                                                <div class="col-lg-6">
                                                    <label for="inputUserName">User Name *</label>
                                                    <div className='div-user-name'>
                                                        <input
                                                            id="inputUserName"
                                                            type="text"
                                                            class={"form-control inputTenders " + (this.state.userInputError || this.state.userIconName == "times circle" ? 'inputIncompleteData' : "")}
                                                            placeholder="User name"
                                                            value={this.state.userName}
                                                            onChange={(e) => this.onChange(e, "UserName")}
                                                            onBlur={() => this.checkUserName()}
                                                        />

                                                        {
                                                            this.state.userName != "" && this.state.userIconName != ""
                                                                ? <Icon className={"user-icon" + (this.state.userIconName == "check circle" ? " success" : " failed")} name={this.state.userIconName}></Icon>
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        this.state.userInputError || this.state.userIconName == "times circle"
                                                            ? <label className="labelIncompleteData">{this.state.userInputErrorType == 0 ? "The User Name cannot be empty." : "That User Name is taken."}</label>
                                                            : null
                                                    }
                                                </div>
                                                <div class="col-lg-6">
                                                    <label for="inputEmail">E-mail *</label>
                                                    <div className='div-user-name'>
                                                        <input
                                                            id="inputEmail"
                                                            type="text"
                                                            class={"form-control inputTenders " + (this.state.mailInputError || this.state.mailIconName == "times circle" ? 'inputIncompleteData' : "")}
                                                            placeholder="example@example.com"
                                                            value={this.state.mail}
                                                            onChange={(e) => this.onChange(e, "Mail")}
                                                            onBlur={() => this.checkMail()}
                                                        />
                                                        {
                                                            this.state.mail != "" && this.state.mailIconName != ""
                                                                ? <Icon className={"user-icon" + (this.state.mailIconName == "check circle" ? " success" : " failed")} name={this.state.mailIconName}></Icon>
                                                                : null
                                                        }
                                                    </div>
                                                    {
                                                        this.state.mailInputError || this.state.mailIconName == "times circle"
                                                            ? <label className="labelIncompleteData">{this.state.mailInputErrorType == 0 ? "The E-mail cannot be empty." : (this.state.mailInputErrorType == 1 ? "That E-mail is taken." : "Your E-mail must be valid.")}</label>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col-lg-6">
                                                    <label for="inputFirstName">First Name *</label>
                                                    <input
                                                        id="inputFirstName"
                                                        type="text"
                                                        class={"form-control inputTenders " + (this.state.firstNameInputError ? 'inputIncompleteData' : "")}
                                                        placeholder="First Name"
                                                        value={this.state.firstName}
                                                        onChange={(e) => this.onChange(e, "FirstName")}
                                                    />
                                                    {
                                                        this.state.firstNameInputError
                                                            ? <label className="labelIncompleteData">The First Name cannot be empty.</label>
                                                            : null
                                                    }
                                                </div>
                                                <div class="col-lg-6">
                                                    <label for="inputLastName">Last Name *</label>
                                                    <input
                                                        id="inputLastName"
                                                        type="text"
                                                        class={"form-control inputTenders " + (this.state.lastNameInputError ? 'inputIncompleteData' : "")}
                                                        placeholder="Last Name"
                                                        value={this.state.lastName}
                                                        onChange={(e) => this.onChange(e, "LastName")}
                                                    />
                                                    {
                                                        this.state.lastNameInputError
                                                            ? <label className="labelIncompleteData">The Last Name cannot be empty.</label>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col-lg-6">
                                                    <label>Mobile Phone *</label>
                                                    <div class={"form-control inputTenders " + (this.state.workPhoneInputError ? 'inputIncompleteData' : "")}>
                                                        <PhoneInput
                                                            international
                                                            placeholder="Enter phone number"
                                                            value={this.state.workPhone}
                                                            onChange={(value) => this.onChangePhone(value)}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.workPhoneInputError
                                                            ? <label className="labelIncompleteData">{this.state.workPhoneInputErrorType == 0 ? "The Mobile Phone cannot be empty." : "Your Mobile Phone must be valid."}</label>
                                                            : null
                                                    }
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Language *</label>
                                                    <Dropdown
                                                        className={"language-dropdown form-control inputTenders " + (this.state.lastNameInputError ? 'inputIncompleteData' : "")}
                                                        options={languageOptions}
                                                        value={languageOptions[this.state.languageOption].text}
                                                        onOpen={() => { }}
                                                        onChange={(event, data) => this.changeLanguage(event, data)}
                                                    />
                                                    {
                                                        this.state.workPhoneInputError
                                                            ? <label className="labelIncompleteData">{this.state.workPhoneInputErrorType == 0 ? "The Mobile Phone cannot be empty." : "Your Mobile Phone must be valid."}</label>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="disclaimer mt-30">
                                                <p class="textDisclaimer">Sector</p>
                                                <div class="form-check form-check-inline">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="finance"
                                                        value="finance"
                                                        checked={this.state.sectorChecked == 0 ? true : false}
                                                        onClick={() => this.onChangeSector("finance", 0)}
                                                    />
                                                    <label class="form-check-label" for="finance">Finance</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="procurement"
                                                        value="procurement"
                                                        checked={this.state.sectorChecked == 1 ? true : false}
                                                        onChange={() => this.onChangeSector("procurement", 1)}
                                                    />
                                                    <label class="form-check-label" for="procurement">Procurement</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        id="suppliers"
                                                        value="suppliers"
                                                        checked={this.state.sectorChecked == 2 ? true : false}
                                                        onChange={() => this.onChangeSector("suppliers", 2)}
                                                    />
                                                    <label class="form-check-label" for="suppliers">Suppliers</label>
                                                </div>
                                            </div>
                                            <hr class="hr-divider" />

                                            <div class="form-group">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="gridCheck" checked={this.state.emailing} onChange={() => this.onClickCheckBox("Emailing")} />
                                                    <label class="form-check-label" onClick={() => this.onClickCheckBox("Emailing")}>
                                                        Receive exclusive email offers and promotions from Coowise and its subsidiaries.
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="gridCheck" checked={this.state.privacyPolicy} onChange={() => this.onClickCheckBox("PrivacyPolicy")} />
                                                    <label class="form-check-label">
                                                        <a onClick={() => this.onClickCheckBox("PrivacyPolicy")}>I have read and understand the</a>
                                                        &nbsp;
                                                        <a href={window.location.origin + "/terms-of-use-and-conditions"} target='_blank'>terms and conditions and privacy policy</a>
                                                        . *
                                                    </label>

                                                </div>
                                                {
                                                    this.state.privacyPolicyInputError
                                                        ? <label className="labelIncompleteData">Please accept terms and conditions to sign up.</label>
                                                        : null
                                                }
                                            </div>

                                            <Button className="buttonPrimary" onClick={() => this.onClickSubmit()}>SUBMIT</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.props.signUpError !== null
                                    ?
                                    <Snackbar
                                        className={"errorToast"}
                                        message={"An error has occurred. Please try again later."}
                                        actionText="X"
                                        onClose={() => this.props.changeSignUpStates()}
                                    />
                                    : null
                            }
                        </div>
                        <Footer
                            currentSection={this.props.currentSection}
                            changeCurrentSection={this.props.changeCurrentSection}
                            cookieUp={this.props.cookieUp}
                        />
                    </>
                );
            else{
                let planId = window.sessionStorage.getItem("planId");

                planId = planId != null ? planId : 1;
                planId = planId != 1 && planId != 2 ? 1 : planId;

                if(planId == 1)
                    return (<Spinner myClass="containerSpinnerGeneral" place="signup" />);
                else
                    return (<Spinner myClass="containerSpinnerGeneral" place="creating-payment-link" />);
            }
        }
        else
            return (<Spinner myClass="containerSpinnerGeneral" />);
    }
}

const mapStateToProps = (value) => {
    return {
        fetchingSignUpGeneralData: value.user.fetchingSignUpGeneralData,
        fetchedSignUpGeneralData: value.user.fetchedSignUpGeneralData,
        signUpGeneralDataError: value.user.signUpGeneralDataError,
        signUpGeneralDataDTO: value.user.signUpGeneralDataDTO,

        fetchingSignUp: value.user.fetchingSignUp,
        fetchedSignUp: value.user.fetchedSignUp,
        signUpError: value.user.signUpError,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (user) => dispatch(signUp(user)),
        changeSignUpStates: () => dispatch(changeSignUpStates()),
        GetSignUpGeneralData: () => dispatch(GetSignUpGeneralData()),
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);