import React, { Component } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

class ArticleModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReactModal onRequestClose={() => this.props.changeArticleModalFlag(null, "", "")} shouldCloseOnOverlayClick={true}  isOpen={this.props.articleModalFlag} className={"modal container " + this.props.imageClass + (this.props.reduceWidth ? " reduce-width" : "") + " animate__animated animate__fadeInDown animate__faster"}>
                <Button icon='close' onClick={() => this.props.changeArticleModalFlag(null, "", "")} className={"closeModal"} />
                <img src={this.props.image} />
            </ReactModal>
        );
    }
}

ArticleModal.propTypes = {
    articleModalFlag: PropTypes.bool.isRequired,
    changeArticleModalFlag: PropTypes.func.isRequired
};

export default (ArticleModal);