import React, { useState, useEffect } from 'react';
import {Button, Icon} from "semantic-ui-react";

const Carousel = ({ items, isVisible }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = () => {
    setActiveIndex((activeIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [activeIndex]);

  return (
    <div className={`features-carousel ${isVisible ? 'visible' : ''}`}>
      <Button onClick={prevSlide} className="carousel-button prev"><Icon name="angle left" ></Icon></Button>
      {items.map((item, index) => (
        <div className={`carousel-row ${index === activeIndex ? 'active' : ''}`}>
          {item}
        </div>
      ))}
      <Button onClick={nextSlide} className="carousel-button next"><Icon name="angle right" ></Icon></Button>
    </div>
  );
};

export default Carousel;