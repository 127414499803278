import * as actionTypes from '../actions/actionTypes';

const initialState = {
    userData: null,
    tokensDTO: null,
    fetchingLogin: false,
    fetchedLogin: false,
    loginError: null,

    //GetUserData

    fetchingGetUserData: false,
    fetchedGetUserData: false,
    errorGetUserData: null,

    //Go to app

    fetchingGoToApp: false,
    fetchedGoToApp: false,
    goToAppError: null,
    goToAppDTO: null
}

export default (state = initialState, action) => {

    switch (action.type){
        case actionTypes.SETUP_LOGIN_VARIABLES: {
            window.sessionStorage.setItem("loginError", null);
            window.sessionStorage.setItem("fetchingLogin", false);
            window.sessionStorage.setItem("fetchedLogin", true);

            return {
                ...state, 
                fetchingLogin: false,
                fetchedLogin: true,
                tokensDTO: action.payload.tokensDTO
            }
            break;
        }

        //Get User Data

        case actionTypes.FETCH_GETUSERDATA + "_PENDING": {
            return {
                ...state,
                fetchingGetUserData: true,
                fetchedGetUserData: false
            }
            break;
        }
        case actionTypes.FETCH_GETUSERDATA + "_REJECTED": {
            let error = null;
            if (action.payload.response == undefined)
                error = action.payload.message;
            else
                if (action.payload.response.data == undefined)
                    error = action.payload.message;
                else
                    error = action.payload.response.data;

            return {
                ...state,
                fetchingGetUserData: false,
                errorGetUserData: error
            }

            break;
        }
        case actionTypes.FETCH_GETUSERDATA + "_FULFILLED": {
            return {
                ...state,
                fetchingGetUserData: false,
                fetchedGetUserData: true,
                userData: action.payload.data
            }
            break;
        }

        //Clean Up User Data

        case actionTypes.CLEAN_UP_USERDATA: {
            return {
                ...state,
                userData: null
            }
            break;
        }

        //Go to app

        case actionTypes.FETCH_GO_TO_APP + "_PENDING": {
            return {
                ...state,
                fetchingGoToApp: true,
                fetchedGoToApp: false,
                goToAppError: null
            }
            break;
        }
        case actionTypes.FETCH_GO_TO_APP + "_REJECTED": {
            let error = null;
            if(action.payload.response == undefined)
                error = action.payload.message;
            else
            if (action.payload.response.data == undefined)
                error = action.payload.message;
            else
                error = action.payload.response.data;

            return {
                ...state,
                fetchingGoToApp: false,
                goToAppError: error
            }
            break;
        }
        case actionTypes.FETCH_GO_TO_APP + "_FULFILLED": {
            window.open(
                process.env.REACT_APP_GO_TO_APP_URL + "/tenders#" + action.payload.data.accessToken + "#" + action.payload.data.refreshToken + 
                "#" + (state.userData != null && state.userData.type == "External" ? "true" : "false"), 
                "_blank", 
                'noopener=true'
            );
            return {
                ...state,
                fetchingGoToApp: false,
                fetchedGoToApp: true,
                goToAppDTO: action.payload.data
            }
            break;
        }
    }
    return state;
}