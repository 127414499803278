import * as actionTypes from '../actions/actionTypes';

const initialState = {
    //SetUp

    fetchingSetUp: false,
    fetchedSetUp: false,
    setUpError: null,
    setUpDTO: null,

    //CreateCheckoutSession

    fetchingCreateCheckoutSession: false,
    fetchedCreateCheckoutSession: false,
    errorCreateCheckoutSession: null,
    sessionId: null,

    //CreateCustomerPortal

    fetchingCreateCustomerPortal: false,
    fetchedCreateCustomerPortal: false,
    createCustomerPortalError: null,
    customerPortalUrl: null
}

export default (state = initialState, action) => {

    switch (action.type){

        //SetUp

        case actionTypes.FETCH_SETUP + "_PENDING": {
            return {
                ...state, 
                fetchingSetUp: true,
                fetchedSetUp: false
            }
            break;
        }
        case actionTypes.FETCH_SETUP + "_REJECTED": {

            let error = null;
            if(action.payload.response == undefined)
                error = action.payload;
            else
            if (action.payload.response.data == undefined)
                error = action.payload;
            else
                error = action.payload.response.data;

            return {
                ...state, 
                fetchingSetUp: false,
                setUpError: error
            }
            break;
        }
        case actionTypes.FETCH_SETUP + "_FULFILLED": {

            return {
                ...state,
                fetchingSetUp: false,
                fetchedSetUp: true,
                setUpDTO: action.payload.data
            }
            break;
        }

        //CreateCheckoutSession

        case actionTypes.FETCH_CREATE_CHECKOUT_SESSION + "_PENDING": {

            return {
                ...state, 
                fetchingCreateCheckoutSession: true,
                fetchedCreateCheckoutSession: false
            }
            break;
        }
        case actionTypes.FETCH_CREATE_CHECKOUT_SESSION + "_REJECTED": {
            return {
                ...state, 
                fetchingCreateCheckoutSession: false,
                errorCreateCheckoutSession: action.payload
            }
            break;
        }
        case actionTypes.FETCH_CREATE_CHECKOUT_SESSION + "_FULFILLED": {
            return {
                ...state,
                fetchingCreateCheckoutSession: false,
                fetchedCreateCheckoutSession: true,
                sessionId: action.payload.data
            }
            break;
        }

        //CreateCustomerPortal

        case actionTypes.FETCH_CREATECUSTOMERPORTAL + "_PENDING": {
           
            return {
                ...state, 
                fetchingCreateCustomerPortal: true,
                fetchedCreateCustomerPortal: false,
                createCustomerPortalError: null
            }
            break;
        }
        case actionTypes.FETCH_CREATECUSTOMERPORTAL + "_REJECTED": {
          
            return {
                ...state, 
                fetchingCreateCustomerPortal: false,
                createCustomerPortalError: action.payload.response.data
            }
            break;
        }
        case actionTypes.FETCH_CREATECUSTOMERPORTAL + "_FULFILLED": {
            window.location.href = action.payload.data;
            return {
                ...state, 
                fetchingCreateCustomerPortal: false,
                fetchedCreateCustomerPortal: true,
                customerPortalUrl: action.payload.data
            }
            break;
        }
    }
    return state;
}