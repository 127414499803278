import React, { Component } from 'react';
import faqsPlus from '../images/faqsPlus.svg';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';

class Faqs extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("FAQs");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "FAQs", page: '/FAQs' });
    }

    render() {
        return (
            <section id="faqs" class="about-area pt-120" onMouseOver={this.props.currentSection != "FAQs" ? this.changeSection : () => { }}>
                <div class="container">
                    <div class="general-row">
                        <h3 class="title">FAQs</h3>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle className="toggleQuestion" as={Button} variant="link" eventKey="0">
                                            <div class="divQuestionTitle">
                                                What regions does Tender Notify currently cover and what are the future expansion plans?
                                            </div>
                                            <div class="divIconTitle">
                                                <img src={faqsPlus} />
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>Tender Notify currently covers all of Estonia, Ireland and Slovakia. 
                                            We are planning to expand our coverage to the entire European Union region in the near future.</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        <div class="col-lg-12">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle className="toggleQuestion" as={Button} variant="link" eventKey="0">
                                            <div class="divQuestionTitle">
                                                Is Tender Notify suitable for my business?
                                            </div>
                                            <div class="divIconTitle">
                                                <img src={faqsPlus} />
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        Tender Notify is designed for businesses of all sizes and industries. We offer three 
                                        different subscription plans to cater to various needs, ensuring that every company 
                                        can benefit from our application and improve their tendering process.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        <div class="col-lg-12">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle className="toggleQuestion" as={Button} variant="link" eventKey="0">
                                            <div class="divQuestionTitle">
                                            Do I need to provide my credit card information to start a free trial of Tender Notify?
                                            </div>
                                            <div class="divIconTitle">
                                                <img src={faqsPlus} />
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        No, you do not need to provide credit card information to start your free trial. You can try 
                                        any subscription plan for 15 days without any commitment. After the free trial period, you will 
                                        need to provide a credit or debit card to continue accessing the app's features.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        <div class="col-lg-12">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle className="toggleQuestion" as={Button} variant="link" eventKey="0">
                                            <div class="divQuestionTitle">
                                            How does Tender Notify ensure the security of the application and user information?
                                            </div>
                                            <div class="divIconTitle">
                                                <img src={faqsPlus} />
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        At Tender Notify, we prioritize the safety and privacy of our clients. We implement end-to-end 
                                        encryption for secure transactions and adhere to GDPR requirements to protect user privacy. 
                                        To learn more about our policies, please read our Terms of Use and Privacy Policy.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        <div class="col-lg-12">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle className="toggleQuestion" as={Button} variant="link" eventKey="0">
                                            <div class="divQuestionTitle">
                                            How and when does Tender Notify bill its customers?
                                            </div>
                                            <div class="divIconTitle">
                                                <img src={faqsPlus} />
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        For monthly subscriptions, an automatic charge will be made every month on the same date as 
                                        the subscription activation date. For 6-month and annual subscriptions, we will send a reminder 
                                        five days before the payment is due, notifying you that the current period is about to end. 
                                        This allows you to confirm whether you would like to continue using our service.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Faqs.propTypes = {
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

export default (Faqs);