import React, { Component } from 'react';
import blog from '../images/blog.png';
import api from '../images/api.png';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';

class ExploreResources extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }

        this.changeSection = this.changeSection.bind(this);
        this.ref = React.createRef();
    }

    changeSection() {
        this.props.changeCurrentSection("ExploreResources");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "ExploreResources", page: '/ExploreResources' });
    }

    componentDidMount() {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    this.setState({ isVisible: entry.isIntersecting });
                });
            },
            { threshold: 0.1 }
        );
        observer.observe(this.ref.current);
    }

    render() {
        return (
            <section id="exploreResources" class="resources-area pt-120" onMouseOver={this.props.currentSection != "ExploreResources" ? this.changeSection : () => { }}>
                <div class="container">
                    <div class="general-row">
                        <h3 class="title">Explore Our Resources</h3>
                        <p class="subtitle">
                            Explore our resources to keep up-to-date and informed. Whether you're curious about Tender Notify or want to make the most of our API, 
                            these resources are here for you
                        </p>
                    </div>

                    <div class="explore-resources-row" ref={this.ref}>
                        <div class={"resource-card" + (this.state.isVisible ? " visible" : "")}>
                            <div class="resource-card-info">
                                <div className='resource-card-header'>
                                    <img class="resource-icon" src={blog} alt="Blog Icon" />
                                    <h4 class="resource-title">Tender Notify Blog</h4>
                                </div>
                                <div className='resource-card-body'>
                                    <p>
                                        Read our latest insights, tips, and updates on public procurement and tendering. 
                                        Stay ahead with our expert knowledge and industry analysis.
                                    </p>
                                    <a href="/blog" target='_blank' class="resource-btn">Visit Our Blog</a>
                                </div>
                            </div>
                            <div class="resource-card-image">
                                <img class="resource-icon" src={blog} alt="Blog Icon" />
                            </div>
                        </div>

                        <div class={"resource-card" + (this.state.isVisible ? " visible" : "")}>
                            <div class="resource-card-info">
                                <div className='resource-card-header'>
                                    <img class="resource-icon" src={api} alt="API Icon" />
                                    <h4 class="resource-title">API Reference Docs</h4> 
                                </div>
                                <div className='resource-card-body'>
                                    <p>
                                        Get started with Tender Notify's API to access and integrate our extensive tender data into your applications. 
                                        Discover our comprehensive API documentation.
                                    </p>
                                    <a href="/api-reference" target='_blank' class="resource-btn">Explore API Docs</a>
                                </div>
                            </div>
                            <div class="resource-card-image">
                                <img class="resource-icon" src={api} alt="API Icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

ExploreResources.propTypes = {
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

export default ExploreResources;
