import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import trackTendersGif from '../../images/track-tenders-article/track-tenders.gif';
import trackTenders1 from '../../images/track-tenders-article/track-tenders-1.png';
import ReactGA4 from 'react-ga4';
import {
    EmailShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailIcon,
    TelegramIcon,
    WhatsappIcon
} from "react-share";
import ArticleModal from '../Modals/ArticleModal';
import PropTypes from 'prop-types';
import magnifyingGlassPrimaryColor from '../../images/magnifying-glass-primary-color.svg';

class TrackTendersArt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articleModalFlag: false,
            image: null,
            imageClass: ""
        }

        this.changeSection = this.changeSection.bind(this);
        this.changeArticleModalFlag = this.changeArticleModalFlag.bind(this);
    }

    changeArticleModalFlag(image, imageClass){
        this.setState({ 
            articleModalFlag: !this.state.articleModalFlag, 
            image: image, 
            imageClass: imageClass
        });
    }

    changeSection() {
        this.props.changeCurrentSection("Blog-Track-Tenders-Article");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'Blog-Track-Tenders-Article', page: '/Blog-Track-Tenders-Article' });
    }

    render() {
        return (
            <Container className="art-container article" onMouseOver={this.props.currentSection != "Blog-Track-Tenders-Article" ? this.changeSection : () => { }}>
                <section class="art-section articles">
                    <div>
                        <Row className="title-row navigation">
                            <div md="auto" className='title-col'>
                                <ul class='navigation'>
                                    <li>
                                        <a href={window.location.origin + "/"}>Home</a>
                                    </li>
                                    <li>
                                        <a href={window.location.origin + "/blog"}>Tender Notify Blog</a>
                                    </li>
                                    <li>
                                        Track Tenders so you don't miss anything
                                    </li>
                                </ul>
                            </div>
                            <img src={magnifyingGlassPrimaryColor} onClick={this.props.changeArticleNavModalFlag}/>
                        </Row>
                        <Row className="title-row">
                            <div md="auto">
                                <hr class="line-title"></hr>
                                <h1 className="title-art">Track Tenders</h1>
                                <p className='text-below-title'>Track Tenders so you don't miss anything</p>
                            </div>
                            <div className="second-title-col" md="auto">
                                <p className='published-day'>Updated on Jan 03 2023 10:00 AM • 2 minutes to read</p>
                            </div>
                        </Row>
                        <Row className="body-row">
                            <Col className="column-paragraph" md="auto">
                                <p className='art-paragraph'>
                                    The platform offers users the ability to track the tenders that are of interest to them and receive notifications whenever those tenders are updated, expired, or are awarded. Learn in this article how to take the most out of this feature.
                                </p>
                            </Col>
                            <Col className="column-paragraph with-image" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="first-column" md="auto">
                                        <h2 className="art-subtitle">Where you can find it?</h2>
                                        <p className='art-paragraph'>
                                            This action can be permormed from any grid in the platform. For instance, you may track tenders from the <span class='text'>Tenders Tab</span> as well as from the <span class='text'>Notification feature</span> screen.
                                        </p>
                                    </Col>
                                    <Col className="second-column" md="auto">
                                        <img class="image" src={trackTendersGif} onClick={() =>this.changeArticleModalFlag(trackTendersGif, "horizontal")}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="column-paragraph with-image" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="first-column second-img" md="auto">
                                        <h2 className="art-subtitle">How to set notifications over tracked tenders?</h2>
                                        <br></br>
                                        <p className='art-paragraph'><span>Updated:</span> Notifies the user about any change on the tender.</p>
                                        <p className='art-paragraph'><span>Expires soon:</span> Generates a warning when a tracked tender is about to expire.</p>
                                        <p className='art-paragraph'><span>Closed:</span> Notifies when a tender has been closed.</p>
                                        <p className='art-paragraph'><span>Awarded:</span> Generates an alert each time a tracked tender has been awarded.</p>
                                        <p className='art-paragraph'><span>Expired:</span> Gives a warning when a tender has expired.</p>
                                    </Col>
                                    <Col className="second-column second-img" md="auto">
                                        <img class="image" src={trackTenders1} onClick={() =>this.changeArticleModalFlag(trackTenders1, "vertical")}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="column-paragraph before-keywords" md="auto">
                                <p className='art-paragraph-below'>
                                    It is important to note that if the tracked tenders set by the user are quite relevant for your business, using this alert tracking feature you will be alerted right way whenever a change has been made so we strongly encourage you to give it a try.
                                </p>
                                <p className='art-paragraph-below'>
                                    Another advantage that allows you to track tenders is the possibility to create reports by creating charts and segmenting them according to your preferences. You will be able to visualise in-app as well as download, print and even export.
                                </p>
                                <p className='art-paragraph-below'>If you want to know how to configure notification preferences click <span onClick={()=>{window.location.pathname="/blog#notification-preferences"}}>here</span>.</p>
                            </Col>
                            <Col className="column-paragraph" md="auto">
                                <h2 className="art-subtitle">Keywords for this article</h2>
                                <p className='art-paragraph-below'>
                                    track tender, tenders, updated, expired soon, closed, awarded, expired, notification preferences, tracking, personal dashboard, segmentation.
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Container className='share-and-more-arts-container'>
                        <Container className='more-arts-container'>
                            <Row className="body-row">
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="more-arts-title">{"These articles may also interest you"}</h2>
                                    <ul className='list-margin'>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("smart-filters")}>
                                            How to boost your search using Smart Filters
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("notification-preferences")}>
                                            Notification Preferences: your smart ally
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("reporting-and-dashboard")}>
                                            Share, measure and grow
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("cpvs")}>
                                            Why is it important to know the potential of the CPV?
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='share-container'>
                            <Row className="body-row">
                                <Col md="auto">
                                    <h2 className="share-title">{"Share"}</h2>
                                </Col>
                                <Col className="column-share-buttons" md="auto">
                                    <TelegramShareButton
                                        children={<TelegramIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#track-tenders"}
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <EmailShareButton
                                        children={<EmailIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#track-tenders"}
                                        subject={"Track Tenders so you don't miss anything"}
                                        body={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <WhatsappShareButton
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                        children={<WhatsappIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#track-tenders"}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </section>
                <ArticleModal
                    articleModalFlag={this.state.articleModalFlag}
                    changeArticleModalFlag={this.changeArticleModalFlag}
                    image={this.state.image}
                    imageClass={this.state.imageClass}
                />
            </Container>
        );
    }
}

TrackTendersArt.propTypes = {
    onClickBlogSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    changeArticleNavModalFlag: PropTypes.func.isRequired
};

export default (TrackTendersArt);