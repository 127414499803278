import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';
import {
    goToApp
} from "../actions/securityActions";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { Icon } from "semantic-ui-react";
import ReactPlayer from 'react-player';
import irelandFlag from '../images/ireland-flag.png';
import estonianFlag from '../images/estonia-flag.png';

class Header extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Home");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Home", page: '/Home' });
    }

    render() {
        return (
            <section id="home" class="header-area" onMouseOver={this.props.currentSection != "Home" ? this.changeSection : () => { }}>
                <div class="header-hero bg_cover">
                    <div class="container pt-50">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="header-hero-content text-center">
                                    <h2 class="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s">
                                        You want to win more tenders.
                                        <br />
                                        We help you find them.
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 vertical-timeline-col video">
                                <ReactPlayer
                                    url='../../TenderNotifyPromoVideo.mp4' 
                                    poster="../../poster.png"
                                    controls={true}
                                    width='100%'
                                    height='auto'
                                    style={{
                                        borderRadius: '6px',
                                        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.2)',
                                        background: 'linear-gradient(145deg, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.1) 100%)'
                                    }}
                                    config={{
                                        file: {
                                            attributes: {
                                                poster: '../../poster.png'
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div class="col-lg-8">
                                <div class="header-hero-content second text-center">
                                    <h2 class="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s">
                                        What's New On Tender Notify
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 vertical-timeline-col">
                                <VerticalTimeline>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element"
                                        date="22 Feb 2024"
                                        icon={<Icon name='arrow circle up' size='huge' />}
                                    >
                                        <h3 className="vertical-timeline-element-title">Estonian Data Source</h3>
                                        <h4 className="vertical-timeline-element-subtitle">DATA SOURCE UPDATES</h4>
                                        <p className='paragraph'>
                                            Announcing that we've incorporated Documents and Tender Notices from the <span className='high-light'>Estonian data source</span>
                                        </p>
                                        <ul>
                                            <li className='firstone'>
                                                Source: <a href='https://www.fin.ee/en' target='_blank'>Public Procurement Register</a>
                                            </li>
                                            <li>
                                                {"Region: Estonia "}<img class="flag" src={estonianFlag} />
                                            </li>
                                        </ul>
                                    </VerticalTimelineElement>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element"
                                        date="17 Jan 2024"
                                        icon={<Icon name='arrow circle up' size='huge' />}
                                    >
                                        <h3 className="vertical-timeline-element-title">Ireland Data Source</h3>
                                        <h4 className="vertical-timeline-element-subtitle">DATA SOURCE UPDATES</h4>
                                        <p className='paragraph'>
                                            Announcing that we've incorporated Documents and Tender Notices from the <span className='high-light'>Ireland data source</span>
                                        </p>
                                        <ul>
                                            <li className='firstone'>
                                                Source: <a href='https://www.etenders.gov.ie' target='_blank'>eTenders</a>
                                            </li>
                                            <li>
                                                {"Region: Ireland "}<img class="flag" src={irelandFlag} />
                                            </li>
                                        </ul>
                                    </VerticalTimelineElement>
                                    <VerticalTimelineElement
                                            className="vertical-timeline-element"
                                        date="6 Dec 2023"
                                        icon={<Icon name='arrow circle up' size='huge' />}
                                    >
                                        <h3 className="vertical-timeline-element-title">New Plan Available</h3>
                                        <h4 className="vertical-timeline-element-subtitle">PRO Plan is now available</h4>
                                        <p className='paragraph'>
                                            Preparing for next-gen enhancements with AI-driven insights and predictive analytics for <span className='high-light'>Tender Notify</span>
                                        </p>
                                    </VerticalTimelineElement>
                                    <a href='/blog#release-notes' target='_blank' className='more'>
                                        <VerticalTimelineElement
                                            icon={<Icon className='right' name='arrow circle right' size='huge' />}
                                            date="Click to learn more"
                                        />
                                    </a>
                                </VerticalTimeline>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Header.propTypes = {
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

const mapStateToProps = (value) => {
    return {
        fetchingGetWebTendersUrls: value.general.fetchingGetWebTendersUrls,
        fetchedGetWebTendersUrls: value.general.fetchedGetWebTendersUrls,
        getWebTendersUrlsError: value.general.getWebTendersUrlsError,
        getWebTendersUrlsDTO: value.general.getWebTendersUrlsDTO
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        goToApp: () => dispatch(goToApp())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);