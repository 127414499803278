import React, { Component } from 'react';
import { Container } from 'semantic-ui-react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

class ArticleNavModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ReactModal onRequestClose={() => this.props.changeArticleNavModalFlag()} shouldCloseOnOverlayClick={true} isOpen={this.props.articleNavModalFlag} className={"modal nav animate__animated animate__slideInRight animate__faster"}>
                <Container className='api-reference-nav articles'>
                    <h1>BLOG</h1>
                    {/* <h2
                        className={"nav-item" + (this.props.blogSection == "getting-started" ? " active" : "")}
                        onClick={() => this.props.onClickBlogSection("getting-started")}
                    > 
                        Getting Started
                    </h2> */}
                    <h2
                        className={"nav-item" + (this.props.blogSection == "release-notes" ? " active" : "")}
                        onClick={() => this.props.onClickBlogSection("release-notes")}
                    >
                        Release Notes
                    </h2>
                    <h2
                        className={"nav-item" + (this.props.blogSection == "cpvs" ? " active" : "")}
                        onClick={() => this.props.onClickBlogSection("cpvs")}
                    >
                        CPV
                    </h2>
                    <h2
                        className={"nav-item" + (this.props.blogSection == "reporting-and-dashboard" ? " active" : "")}
                        onClick={() => this.props.onClickBlogSection("reporting-and-dashboard")}
                    >
                        Reporting and dashboard
                    </h2>
                    <h2
                        className={"nav-item" + (this.props.blogSection == "notification-preferences" ? " active" : "")}
                        onClick={() => this.props.onClickBlogSection("notification-preferences")}
                    >
                        Notification Preferences
                    </h2>
                    <h2
                        className={"nav-item" + (this.props.blogSection == "track-tenders" ? " active" : "")}
                        onClick={() => this.props.onClickBlogSection("track-tenders")}
                    >
                        Track tenders
                    </h2>
                    <h2
                        className={"nav-item" + (this.props.blogSection == "smart-filters" ? " active" : "")}
                        onClick={() => this.props.onClickBlogSection("smart-filters")}
                    >
                        Smart filters
                    </h2>
                    <h2
                        className={"nav-item" + (this.props.blogSection == "translate-extension" ? " active" : "")}
                        onClick={() => this.props.onClickBlogSection("translate-extension")}
                    >
                        Translate Extension
                    </h2>
                </Container>
            </ReactModal>
        );
    }
}

ArticleNavModal.propTypes = {
    articleNavModalFlag: PropTypes.bool.isRequired,
    changeArticleNavModalFlag: PropTypes.func.isRequired,
    onClickBlogSection: PropTypes.func.isRequired,
    blogSection: PropTypes.string.isRequired
};

export default (ArticleNavModal);