import React, { useState, useEffect } from 'react';
import update from '../../../images/update.svg';
import fire from '../../../images/fire.svg';
import calendar from '../../../images/calendar.svg';
import newtenders from '../../../images/new-tenders.svg';
import soon from '../../../images/soon.svg';

const TrendingCards = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const nextSlide = () => {
        setActiveIndex((activeIndex + 1) % 2);
    };

    const prevSlide = () => {
        setActiveIndex(activeIndex === 0 ? 2 - 1 : activeIndex - 1);
    };

    useEffect(() => {
        const intervalId = setInterval(nextSlide, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, [activeIndex]);

    return (
        <div className='real-time-card'>
            <div className={"real-time-card-container"}>
                <div className={"real-time-card-section " + (activeIndex === 0 ? 'active' : '')}>
                    <div className='header'>

                        {
                            !props.fetchingGetHeaderSection
                                ?
                                    <div className='title-container'>
                                        <img src={fire} />
                                        <h3>Global Trend</h3>
                                    </div>
                                :
                                    <div className='loader-bar'>
                                        <div className='bar-progress'></div>
                                    </div>
                        }
                    </div>
                    <div className='body'>
                        <div className='body-row'>
                            {
                                !props.fetchingGetHeaderSection
                                    ?
                                        <>
                                            <div className='title-container'>
                                                <img src={newtenders} />
                                                <h3>Tender Opportunities</h3>
                                            </div>
                                            <p className='number blue'>{props.headerSection != null ? props.headerSection.dailyNews : 0}</p>
                                        </>
                                    :
                                        <div className='loader-bar'>
                                            <div className='bar-progress'></div>
                                        </div>
                            }
                        </div>
                        <div className='body-row'>
                            {
                                !props.fetchingGetHeaderSection
                                    ?
                                        <>
                                            <div className='title-container'>
                                                <img src={update} />
                                                <h3>Tender Updates</h3>
                                            </div>
                                            <p className='number green'>{props.headerSection != null ? props.headerSection.dailyUpdates : 0}</p>
                                        </>
                                    :
                                        <div className='loader-bar'>
                                            <div className='bar-progress'></div>
                                        </div>
                            }
                        </div>
                        <div className='body-row'>
                            {
                                !props.fetchingGetHeaderSection
                                    ?
                                        <>
                                            <div className='title-container'>
                                                <img src={soon} />
                                                <h3>Closing Soon</h3>
                                            </div>
                                            <p className='number yellow'>{props.headerSection != null ? props.headerSection.dailyExpiresSoon : 0}</p>
                                        </>
                                    :
                                        <div className='loader-bar'>
                                            <div className='bar-progress'></div>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={"real-time-card-section " + (activeIndex === 1 ? 'active' : '')}>
                    <div className='header'>
                        {
                            !props.fetchingGetHeaderSection
                                ?
                                    <div className='title-container'>
                                        <img src={calendar} />
                                        <h3>Weekly Tender Trends</h3>
                                    </div>
                                :
                                    <div className='loader-bar'>
                                        <div className='bar-progress'></div>
                                    </div>
                        }
                    </div>
                    <div className='body'>
                        <div className='body-row'>
                            {
                                !props.fetchingGetHeaderSection
                                    ?
                                        <>
                                            <div className='title-container'>
                                                <img src={newtenders} />
                                                <h3>Tender Opportunities</h3>
                                            </div>
                                            <p className='number blue'>{props.headerSection != null ? props.headerSection.weeklyNews : 0}</p>
                                        </>
                                    :
                                        <div className='loader-bar'>
                                            <div className='bar-progress'></div>
                                        </div>
                            }
                        </div>
                        <div className='body-row'>
                            {
                                !props.fetchingGetHeaderSection
                                    ?
                                        <>
                                            <div className='title-container'>
                                                <img src={update} />
                                                <h3>Tender Updates</h3>
                                            </div>
                                            <p className='number green'>{props.headerSection != null ? props.headerSection.weeklyUpdates : 0}</p>
                                        </>
                                    :
                                        <div className='loader-bar'>
                                            <div className='bar-progress'></div>
                                        </div>
                            }
                        </div>
                        <div className='body-row'>
                            {
                                !props.fetchingGetHeaderSection
                                    ?
                                        <>
                                            <div className='title-container'>
                                                <img src={soon} />
                                                <h3>Closing Soon</h3>
                                            </div>
                                            <p className='number yellow'>{props.headerSection != null ? props.headerSection.weeklyExpiresSoon : 0}</p>
                                        </>
                                    :
                                        <div className='loader-bar'>
                                            <div className='bar-progress'></div>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button className={"button" + (activeIndex === 0 ? "" : " inactive")} onClick={activeIndex === 0 ? () => { } : nextSlide} disabled={activeIndex === 0} />
                <button className={"button" + (activeIndex === 1 ? "" : " inactive")} onClick={activeIndex === 1 ? () => { } : prevSlide} disabled={activeIndex === 1} />
            </div>
        </div>
    );
}

export default TrendingCards;