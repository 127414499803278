import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../../actions/generalActions";

class CookiePolicy extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Cookies-Policy");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'Cookies-Policy', page: '/Cookies-Policy' });
    }

    render() {
        return (
            <section id="privacy-section" class="privacy-section" onMouseOver={this.props.currentSection != "Cookies-Policy" ? this.changeSection : () => { }}>
                <Container>
                    <Row className="justify-content-md-left">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h1 className="title-cookies-policy">{"Cookies Policy"}</h1>
                            <p className='text-below-title'>
                                {"Valid from January 19, 2021"}
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"Summary"}</h2>
                            <ul>
                                <li className='summary-item'>
                                    {"Cookies used: Own and Third Party"}
                                </li>
                                <li className='summary-item'>
                                    {"Are they used to obtain statistics? Yes, the legal basis being our legitimate interest in carrying out a better service management."}
                                </li>
                                <li className='summary-item'>
                                    {"Third-party services that install cookies through this website: Google Analytics."}
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{this.props.language == "ES" ? "Cómo configurar y deshabilitar las cookies" : "How to configure and disable cookies"}</h2>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                        : "You can allow, block or delete the cookies installed on your device from your web browser. If you do not allow the installation of cookies on your device, the browsing experience may deteriorate. Below you can find how to configure cookies in each browser:"
                                }
                            </p>
                            <ul>
                                <li className='summary-item'>
                                    <a href="http://windows.microsoft.com/es-es/windows-10/edge-privacy-faq" target="_blank">Edge</a>
                                </li>
                                <li className='summary-item'>
                                    <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">Google Chrome</a>
                                </li>
                                <li className='summary-item'>
                                    <a href="https://support.google.com/chrome/answer/114662?hl=es&visit_id=1-636664625754483085-1648636281&rd=1&co=GENIE.Platform%3DAndroid&oco=1" target="_blank">Google Android</a>
                                </li>
                                <li className='summary-item'>
                                    <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer 8</a>
                                </li>
                                <li className='summary-item'>
                                    <a href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer" target="_blank">Internet Explorer 9</a>
                                </li>
                                <li className='summary-item'>
                                    <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10" target="_blank">Internet Explorer 11</a>
                                </li>
                                <li className='summary-item'>
                                    <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank">Mozilla Firefox</a>
                                </li>

                                <li className='summary-item'>
                                    <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera</a>
                                </li>
                                <li className='summary-item'>
                                    <a href="https://support.apple.com/kb/ph21411?locale=es_ES" target="_blank">Safari</a>
                                </li>
                                <li className='summary-item'>
                                    <a href="https://support.apple.com/es-es/HT201265" target="_blank">Safari Mobile</a>
                                </li>
                            </ul>

                            <Col md="auto" className='sub-columns'>
                                <hr class="line-title"></hr>
                                <h4 className="policy-subtitle">{this.props.language == "ES" ? "1. Tipos y usos" : "1. Types and uses"}</h4>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "El servicio usa cookies propias y de terceros para mejorar la navegación, proporcionar el servicio y obtener estadísticas sobre el uso de la página web."
                                            : "The service uses its own and third-party cookies to improve navigation, provide the service and obtain statistics on the use of the website."
                                    }
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns'>
                                <hr class="line-title"></hr>
                                <h4 className="policy-subtitle">{this.props.language == "ES" ? "2. ¿Qué es una cookie y para qué sirve?" : "2. What is a cookie and what is it for?"}</h4>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Una cookie es un fichero de texto que se envía al navegador web de tu ordenador, móvil o tablet y que sirve para almacenar y recuperar información sobre la navegación realizada. Por ejemplo, recordar tu nombre de usuario y contraseña o las preferencias de tu perfil."
                                            : "A cookie is a text file that is sent to the web browser of your computer, mobile or tablet and that is used to store and retrieve information about the navigation carried out. For example, remembering your username and password or your profile preferences."
                                    }
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns'>
                                <hr class="line-title"></hr>
                                <h4 className="policy-subtitle">{this.props.language == "ES" ? "3. ¿Qué tipos de cookies hay?" : "3. What types of cookies are there?"}</h4>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "According to who manages them:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "Own cookies; they are sent to your browser from our computers or web domains."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Third party cookies; they are sent to your browser from a computer or web domain that we do not manage directly, but from a third entity such as Google."
                                        }
                                    </li>
                                </ul>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "En función del tiempo que están activas, hay:"
                                            : "Depending on the time they are active, there are:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de sesión; permanecen en el archivo de cookies de tu navegador hasta que abandonas la página web, de modo que ninguna queda en tu dispositivo."
                                                : "Session cookies; they remain in the cookie file of your browser until you leave the website, so that none remain on your device."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies persistentes; permanecen en tu dispositivo y nuestra web las lee cada vez que realizas una nueva visita. Estas cookies dejan de funcionar pasada una fecha concreta."
                                                : "Persistent cookies; they remain on your device and our website reads them every time you make a new visit. These cookies stop working after a specific date."
                                        }
                                    </li>
                                </ul>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Finalmente, y según su finalidad, tenemos:"
                                            : "Finally, and according to its purpose, we have:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies técnicas; mejoran la navegación y el buen funcionamiento de la web."
                                                : "Technical cookies; improve navigation and the proper functioning of the web."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de personalización; permiten acceder al servicio con unas características predefinidas en función de una serie de criterios."
                                                : "Personalization cookies; they allow access to the service with predefined characteristics based on a series of criteria."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies persistentes; permanecen en tu dispositivo y nuestra web las lee cada vez que realizas una nueva visita. Estas cookies dejan de funcionar pasada una fecha concreta."
                                                : "Analysis cookies; they allow us to statistically measure and analyze the use made of the service provided."
                                        }
                                    </li>
                                </ul>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies usadas por Tender Notify:"
                                            : "Cookies used by Tender Notify:"
                                    }
                                </p>
                                <div class='divtable'>
                                    <table className="cookies-policy-table">
                                        <thead>
                                            <tr className='tr-head'>
                                                <td className='thead-td-table'>
                                                    <p className='thead-text'>{this.props.language == "ES" ? "Nombre" : "Name"}</p>
                                                </td>
                                                <td className='thead-td-table'>
                                                    <p className='thead-text'>{this.props.language == "ES" ? "Origen" : "Origin"}</p>
                                                </td>
                                                <td className='thead-td-table'>
                                                    <p className='thead-text'>{this.props.language == "ES" ? "Finalidad" : "Purpose"}</p>
                                                </td>
                                                <td className='thead-td-table'>
                                                    <p className='thead-text'>{this.props.language == "ES" ? "Tipo" : "Type"}</p>
                                                </td>
                                                <td className='thead-td-table'>
                                                    <p className='thead-text'>{this.props.language == "ES" ? "Caducidad" : "Expiration"}</p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>_ga</p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'><a href={window.location.origin} target="_blank">{window.location.origin}</a></p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>{this.props.language == "ES" ? "Analítica (rendimiento)" : "Analytics (performance)"}</p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>{this.props.language == "ES" ? "Propia" : "Own"}</p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>{this.props.language == "ES" ? "2 años" : "2 years"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>_gat</p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'><a href={window.location.origin} target="_blank">{window.location.origin}</a></p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>{this.props.language == "ES" ? "Analítica (rendimiento)" : "Analytics (performance)"}</p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>{this.props.language == "ES" ? "Propia" : "Own"}</p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>{this.props.language == "ES" ? "Sesión" : "Session"}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>_gid</p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'><a href={window.location.origin} target="_blank">{window.location.origin}</a></p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>{this.props.language == "ES" ? "Analítica (rendimiento)" : "Analytics (performance)"}</p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>{this.props.language == "ES" ? "Propia" : "Own"}</p>
                                                </td>
                                                <td className='tbody-td-table'>
                                                    <p className='tbody-text'>{this.props.language == "ES" ? "1 día" : "1 day"}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Lista de cookies de tercero usadas por Tender Notify:"
                                            : "List of third-party cookies used by Tender Notify:"
                                    }
                                </p>

                                <ul className='list-margin'>
                                    <li className='summary-item'>
                                        <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank">Google Analytics</a>
                                        {
                                            this.props.language == "ES"
                                                ? ": Sus cookies (como por ejemplo _ga) permiten contabilizar las veces que un usuario visita una web, cómo, cuándo y desde dónde lo hace."
                                                : ": Its cookies (such as _ga) allow to count the times a user visits a website, how, when and from where they do so. "
                                        }
                                    </li>
                                </ul>

                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "En cualquier caso, puedes inhabilitar las cookies de Google Analytics desde "
                                            : "In any case, you can disable Google Analytics cookies from "
                                    }
                                    <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">{this.props.language == "ES" ? "aquí." : "here."}</a>
                                </p>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CookiePolicy);