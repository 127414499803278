import React, { Component } from 'react';
import iconCheck from '../../images/cw_switch_enabled.svg';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ChangeSubscriptionModal from '../Modals/ChangeSubscriptionModal';
import RenewMembershipModal from '../Modals/RenewMembershipModal';

class PlusPlan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changeSubscriptionModalFlag: false,
            renewMembershipModalFlag: false,
            planId: 0,
            planName: ""
        }

        this.changeChangeSubscriptionModalFlag = this.changeChangeSubscriptionModalFlag.bind(this);
        this.changeRenewMembershipModalFlag = this.changeRenewMembershipModalFlag.bind(this);
    }

    changeChangeSubscriptionModalFlag(planId, planName) {
        this.setState({ 
            changeSubscriptionModalFlag: !this.state.changeSubscriptionModalFlag,
            planId: planId,
            planName: planName
        });
    }

    changeRenewMembershipModalFlag(planId, planName){
        this.setState({ 
            renewMembershipModalFlag: !this.state.renewMembershipModalFlag,
            planId: planId,
            planName: planName
        });
    }

    render() {
        let monthly = window.sessionStorage.getItem("monthly");
        let monthlyPrice = false;

        if(monthly != null && monthly == "false")
            monthlyPrice = true;

        return (
            <>
                <div class="containerHeaderPlan">
                    <div class="containerTitlePlan">
                        <h3 class="titlePlan">PRO</h3>
                        <h8 class="text-gray">The best option for individuals and companies</h8>
                        <h2 className='price'>{!monthlyPrice ? "$ 20" : "$ 16"}<span className='mo'>{"/ mo"}</span></h2>
                    </div>
                    {
                        
                        this.props.membership == null
                            ?
                                <Link to={"/sign-up"} className="pricing">
                                    <Button content='Get Started' className='get-started' onClick={() => {window.sessionStorage.setItem("planId", 2);}}/>
                                </Link>
                            : 
                                this.props.membership.id == 2
                                    ? 
                                        !this.props.membership.expired
                                            ? <Button content='Active' className='get-started active'/>
                                            : <Button content='Expired' className='get-started active' /* onClick={() => this.changeRenewMembershipModalFlag(2, "PRO")} *//>
                                    : <Button content='Change to PRO' className='get-started' onClick={() => this.changeChangeSubscriptionModalFlag(2, "PRO")}/>
                            
                    }
                    
                </div>

                <div class="containerChecklist">
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Email Notifications</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Smart Filters</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Track Tender</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Basic Finder</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Charts and Statistics</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Export Data</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Reports</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">SMS Notifications</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Web API Access</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">AI Capabilities</span>
                    </div>
                </div>

                <ChangeSubscriptionModal
                    changeSubscriptionModalFlag={this.state.changeSubscriptionModalFlag}
                    changeChangeSubscriptionModalFlag={this.changeChangeSubscriptionModalFlag}
                    planId = {this.state.planId}
                    planName = {this.state.planName}
                />

                <RenewMembershipModal
                    renewMembershipModalFlag={this.state.renewMembershipModalFlag}
                    changeRenewMembershipModalFlag={this.changeRenewMembershipModalFlag}
                    planId={this.state.planId}
                    planName={this.state.planName}
                />
            </>
        );
    }
}

export default (PlusPlan);