import React, { Component } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { connect } from "react-redux";
import {
    goToApp
} from "../../actions/securityActions";

class DropdownUser extends Component {
    render() { 
        return ( 
            <NavDropdown title={this.props.userData.userName} className= "dropdownTitle" id="basic-nav-dropdown">
                <NavDropdown.Item 
                    onClick = {this.props.goToApp}
                >
                        GO TO APP
                </NavDropdown.Item>
                <NavDropdown.Item 
                    className='sign-out'
                    onClick = {()=>{window.sessionStorage.clear(); window.location.reload();}}>Sign Out
                </NavDropdown.Item>
            </NavDropdown>
         );
    }
}
 
const mapStateToProps = (value) => {
    return {
        fetchingGetUserData: value.security.fetchingGetUserData,
        fetchedGetUserData: value.security.fetchedGetUserData,
        userData: value.security.userData
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        goToApp: () => dispatch(goToApp())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownUser);