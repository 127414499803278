import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import smartFilterGif from '../../images/smart-filter-article/smart-filter.gif';
import smartFilter1 from '../../images/smart-filter-article/smart-filter-1.png';
import smartFilter2 from '../../images/smart-filter-article/smart-filter-2.png';
import smartFilter3 from '../../images/smart-filter-article/smart-filter-3.png';
import ReactGA4 from 'react-ga4';
import {
    EmailShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailIcon,
    TelegramIcon,
    WhatsappIcon
} from "react-share";
import ArticleModal from '../Modals/ArticleModal';
import PropTypes from 'prop-types';
import magnifyingGlassPrimaryColor from '../../images/magnifying-glass-primary-color.svg';
import magnifyingGlassDarkColor from '../../images/magnifying-glass-dark-color.svg';

class SmartFilterArt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articleModalFlag: false,
            image: null,
            imageClass: ""
        }

        this.changeSection = this.changeSection.bind(this);
        this.changeArticleModalFlag = this.changeArticleModalFlag.bind(this);
    }

    changeArticleModalFlag(image, imageClass){
        this.setState({ 
            articleModalFlag: !this.state.articleModalFlag, 
            image: image, 
            imageClass: imageClass
        });
    }

    changeSection() {
        this.props.changeCurrentSection("Blog-Smart-Filter-Article");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'Blog-Smart-Filter-Article', page: '/Blog-Smart-Filter-Article' });
    }

    render() {
        let ligthMode = window.localStorage.getItem("ligth-mode");
        ligthMode = ligthMode != null ? JSON.parse(ligthMode) : false;

        return (
            <Container className="art-container article" onMouseOver={this.props.currentSection != "Blog-Smart-Filter-Article" ? this.changeSection : () => { }}>
                <section class="art-section articles">
                    <div>
                        <Row className="title-row navigation">
                            <div md="auto" className='title-col'>
                                <ul class='navigation'>
                                    <li>
                                        <a href={window.location.origin + "/"}>Home</a>
                                    </li>
                                    <li>
                                        <a href={window.location.origin + "/blog"}>Tender Notify Blog</a>
                                    </li>
                                    <li>
                                        How to boost your search using Smart Filters
                                    </li>
                                </ul>
                            </div>
                            <img src={ligthMode ? magnifyingGlassPrimaryColor : magnifyingGlassDarkColor} onClick={this.props.changeArticleNavModalFlag}/>
                        </Row>
                        <Row className="title-row">
                            <div md="auto">
                                <hr class="line-title"></hr>
                                <h1 className="title-art">Smart Filters</h1>
                                <p className='text-below-title'>How to boost your search using Smart Filters</p>
                            </div>
                            <div className="second-title-col" md="auto">
                                <p className='published-day'>Updated on Jan 03 2023 10:00 AM • 4 minutes to read</p>
                            </div>
                        </Row>
                        <Row className="body-row">
                            <Col className="column-paragraph" md="auto">
                                <p className='art-paragraph'>
                                    Smart Filter invites you to discover new business opportunities without wasting time and energy. Discover in this article how to achieve strategic results by creating smart filters to increase your company's return on investment.
                                </p>
                                <p className='art-paragraph'>
                                    Smart Filter was devised to facilitate and speed up the search for business opportunities for our users. One of the core feature offered by the platform is to create autonomous search conditions or combine them using groups.
                                </p>
                            </Col>
                            <Col className="column-paragraph with-image" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="first-column smart-filters" md="auto">
                                        <h2 className="art-subtitle">What is the difference between Condition vs Conditions Group?</h2>
                                        <p className='art-paragraph'>When you create a Smart Filter from scratch you can add a single / multiple condition or create a complex filter structure using groups.</p>
                                        <p className='art-paragraph'>The groups of conditions allow you to segment the filters and display the tenders you are interested in to find more specific results.</p>
                                    </Col>
                                    <Col className="second-column smart-filters" md="auto">
                                        <img class="image" src={smartFilter1} onClick={() =>this.changeArticleModalFlag(smartFilter1, "horizontal")}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="column-paragraph" md="auto">
                                <p className='art-paragraph'>
                                    Independent Condition Filters are commonly used for more general and not so specific searches. According to the complexity or segmentation of what you are looking for, you might need to use a broad or accurate criteria.
                                </p>
                            </Col>
                            <Col className="column-paragraph with-gif smart-gif" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="first-column smart-filters-gif" md="auto">
                                        <h2 className="art-subtitle">How does each search field work?</h2>
                                        <p className='art-paragraph'>Each filter is composed of four data inputs: the entity being searched, the field within that entity, the operator used to compare the values, and the value being searched for. </p>
                                        <p className='art-paragraph'>In the case of using a group, a fifth field is used to determine the operator between the different conditions.</p>
                                    </Col>
                                    <Col className="second-column smart-filters-gif" md="auto">
                                        <img class="image" src={smartFilterGif} onClick={() =>this.changeArticleModalFlag(smartFilterGif, "horizontal")}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="column-paragraph" md="auto">
                            </Col>
                            <Col className="column-paragraph" md="auto">
                                <p className='art-paragraph'>Once the filters have been created, the results can be downloaded in CSV format, shared with others or printed for easy access. Additionally, the filters can be linked to the notification feature, allowing users to be automatically notified when new results are found and meet the search criteria.</p>
                            </Col>
                            <Col className="column-paragraph with-image" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="second-column smart-filters-one-image" md="auto">
                                        <img class="image" src={smartFilter2} onClick={() =>this.changeArticleModalFlag(smartFilter2, "horizontal")}/>
                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col className="column-paragraph with-image" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="first-column second-img" md="auto">
                                        <h2 className="art-subtitle">How to link Smart Filter with the notification feature?</h2>
                                        <p className='art-paragraph'>Linking a Smart Filter with the notifications enables the platform to send automatic alerts to each user when new results are found that match the search criteria specified in the filters. This feature allows users to stay up to date on the latest information relevant to their interests, without having to manually check for updates. The notifications can be delivered through various channels, such as email, SMS, push notification or in-app notification, depending on the preferences of the user and the capabilities of the platform. By linking the Smart Filter and the notifications, users can save time and stay informed about the most important developments in their field of interest.</p>
                                        <p className='art-paragraph'>This is a key benefit because it allows users to receive automatic alerts about new opportunities without having to log in to the platform on a daily basis. This can save time and effort, as Tender Notify takes care to notify the user when a new opportunity becomes available. In addition to individual notifications, the platform also offers the ability to generate collective alerts and automate the communication of new opportunities to a group of users. This can be useful for organizations that want to keep their team members informed about new business opportunities and ensure that they are not missed. Overall, the ability to receive automatic notifications can greatly improve the efficiency and effectiveness of the user's search for new opportunities.</p>
                                    </Col>
                                    <Col className="second-column second-img smart-img" md="auto">
                                        <img class="image" src={smartFilter3} onClick={() =>this.changeArticleModalFlag(smartFilter3, "vertical")}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="column-paragraph" md="auto">
                                <p className='art-paragraph'>The Notification feature allows users to specify one or more recipients for their alerts, based on their membership status. By activating notifications, users can receive automated messages with a predetermined frequency, depending on the conditions specified in the commands. This allows users to customize the notifications to suit their needs and preferences. For example, they can choose to receive notifications immediately when a new opportunity becomes available or they can opt to receive a daily summary of new opportunities that meet their search criteria. Find out how to do it <span className="link-to" onClick={() => this.props.onClickBlogSection("notification-preferences")}>here</span>.</p>
                            </Col>
                            <Col id='cpvDescription' className="column-paragraph after-cpv-description" md="auto">
                                <hr class="line-title"></hr>
                                <p className='art-paragraph cpv-description'><span class='text'>CPV:</span> A code used to indicate the subject of a tender. Common Procurement Vocabulary Code. The code consists of eight digits with different levels of classification: 45221100 ( Construction work for bridges)</p>
                                <p className='art-paragraph cpv-description'>The first two digits indicate the department or division of the subject.</p>
                                <p className='art-paragraph cpv-description'>The first three digits indicate the group the subject .</p>
                                <p className='art-paragraph cpv-description'>The first four digits indicate the class the subject.</p>
                                <p className='art-paragraph cpv-description'>Categories are indicated with the first five digits of the code.</p>
                                <p className='art-paragraph cpv-description'>The last three digits represent an extra precision within a category.</p>
                            </Col>
                            <Col className="column-paragraph" md="auto">
                                <h2 className="art-subtitle">Keywords for this article</h2>
                                <p className='art-paragraph'>smart filter, conditions, conditions group, conditions group, notifications, notification preferences, opportunities, search, entity, cpv, tender, tender notice, authority, authority supplier information, authority appeal.</p>
                            </Col>
                        </Row>
                    </div>
                    <Container className='share-and-more-arts-container'>
                        <Container className='more-arts-container'>
                            <Row className="body-row">
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="more-arts-title">{"These articles may also interest you"}</h2>
                                    <ul className='list-margin'>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("track-tenders")}>
                                            Track Tenders so you don't miss anything
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("notification-preferences")}>
                                            Notification Preferences: your smart ally
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("reporting-and-dashboard")}>
                                            Share, measure and grow
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("cpvs")}>
                                            Why is it important to know the potential of the CPV?
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='share-container'>
                            <Row className="body-row">
                                <Col md="auto">
                                    <h2 className="share-title">{"Share"}</h2>
                                </Col>
                                <Col className="column-share-buttons" md="auto">
                                    <TelegramShareButton
                                        children={<TelegramIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#smart-filters"}
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <EmailShareButton
                                        children={<EmailIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#smart-filters"}
                                        subject={"How to boost your search using Smart Filters"}
                                        body={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <WhatsappShareButton
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                        children={<WhatsappIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#smart-filters"}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </section>
                <ArticleModal
                    articleModalFlag={this.state.articleModalFlag}
                    changeArticleModalFlag={this.changeArticleModalFlag}
                    image={this.state.image}
                    imageClass={this.state.imageClass}
                />
            </Container>
        );
    }
}

SmartFilterArt.propTypes = {
    onClickBlogSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    changeArticleNavModalFlag: PropTypes.func.isRequired
};

export default (SmartFilterArt);