//Payment

export const FETCH_SETUP = "FETCH_SETUP";
export const FETCH_CREATE_CHECKOUT_SESSION = "FETCH_CREATE_CHECKOUT_SESSION";
export const FETCH_CREATECUSTOMERPORTAL = "FETCH_CREATECUSTOMERPORTAL";

//User

export const FETCH_SIGNUP = "FETCH_SIGNUP";
export const CHANGE_SIGN_UP_STATES = "CHANGE_SIGN_UP_STATES";
export const FETCH_SIGNUPGENERALDATA = "FETCH_SIGNUPGENERALDATA";
export const FETCH_CHANGE_SUBSCRIPTION = "FETCH_CHANGE_SUBSCRIPTION";
export const FETCH_RENEW_SUBSCRIPTION = "FETCH_RENEW_SUBSCRIPTION";
export const FETCH_SEND_SATISFACTION_SURVEY = "FETCH_SEND_SATISFACTION_SURVEY";
export const CHANGE_SEND_SATISFACTION_SURVEY_STATES = "CHANGE_SEND_SATISFACTION_SURVEY_STATES";

//General

export const CHANGE_CURRENTPAGE = "CHANGE_CURRENTPAGE";
export const CHANGE_HASHID = "CHANGE_HASHID";
export const FETCH_CREATEPROSPECT = "FETCH_CREATEPROSPECT";
export const CHANGE_CREATEPROSPECT_STATES = "CHANGE_CREATEPROSPECT_STATES";
export const FETCH_GETWEBTENDERSURLS = "FETCH_GETWEBTENDERSURLS";
export const CHANGE_CURRENTSECTION = "CHANGE_CURRENTSECTION";
export const ACCEPT_COOKIES = "ACCEPT_COOKIES";
export const FETCH_GET_HEADER_SECTION = "FETCH_GET_HEADER_SECTION";
export const FETCH_GET_SERVICE_STATUS_SECTION = "FETCH_GET_SERVICE_STATUS_SECTION";

//Security

export const SETUP_LOGIN_VARIABLES = "SETUP_LOGIN_VARIABLES";
export const FETCH_GETUSERDATA = "FETCH_GETUSERDATA";
export const CLEAN_UP_USERDATA = "CLEAN_UP_USERDATA";
export const FETCH_GO_TO_APP = "FETCH_GO_TO_APP";