import React, { Component } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import translatorExtensionGif from '../../images/translator-extension/translator.gif';
import horizontalTranslatorExtensionGif from '../../images/translator-extension/horizontal-translator.gif';
import ReactGA4 from 'react-ga4';
import {
    EmailShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailIcon,
    TelegramIcon,
    WhatsappIcon
} from 'react-share';
import ArticleModal from '../Modals/ArticleModal';
import PropTypes from 'prop-types';
import magnifyingGlassPrimaryColor from '../../images/magnifying-glass-primary-color.svg';
import magnifyingGlassDarkColor from '../../images/magnifying-glass-dark-color.svg';

class TranslatorExtensionArt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articleModalFlag: false,
            image: null,
            imageClass: ''
        };

        this.changeSection = this.changeSection.bind(this);
        this.changeArticleModalFlag = this.changeArticleModalFlag.bind(this);
    }

    changeArticleModalFlag(image, imageClass) {
        this.setState({
            articleModalFlag: !this.state.articleModalFlag,
            image,
            imageClass
        });
    }

    changeSection() {
        this.props.changeCurrentSection('Blog-Translator-Extension-Article');
        if (this.props.cookieUp) {
            ReactGA4.send({
                hitType: 'pageview',
                title: 'Blog-Translator-Extension-Article',
                page: '/Blog-Translator-Extension-Article'
            });
        }
    }

    render() {
        const lightMode = window.localStorage.getItem('light-mode') === 'true';

        return (
            <Container
                className="art-container article"
                onMouseOver={this.props.currentSection !== 'Blog-Translator-Extension-Article' ? this.changeSection : () => {}}
            >
                <section className="art-section articles">
                    <div>
                        <Row className="title-row navigation">
                            <Col className="title-col">
                                <ul className="navigation">
                                    <li>
                                        <a href={window.location.origin + '/'}>Home</a>
                                    </li>
                                    <li>
                                        <a href={window.location.origin + '/blog'}>Tender Notify Blog</a>
                                    </li>
                                    <li>Translate to any language in seconds</li>
                                </ul>
                            </Col>
                            <Col>
                                <img
                                    src={lightMode ? magnifyingGlassPrimaryColor : magnifyingGlassDarkColor}
                                    onClick={this.props.changeArticleNavModalFlag}
                                    alt="Navigation icon"
                                />
                            </Col>
                        </Row>
                        <Row className="title-row">
                            <Col>
                                <hr className="line-title" />
                                <h1 className="title-art">Translate Extension</h1>
                                <p className="text-below-title">Translate to any language in seconds</p>
                            </Col>
                            <Col className="second-title-col">
                                <p className="published-day">Updated on April 24, 2024 • 5 minutes to read</p>
                            </Col>
                        </Row>
                        <Row className="body-row translate">
                            <Col className="column-paragraph">
                                <p className="art-paragraph">
                                    Incorporating a translate extension into Tender Notify is crucial for improving accessibility and empowering users to effectively navigate the global tender landscape.
                                </p>
                                <p className="art-paragraph">
                                    With tender data available in languages such as English, Estonian, Slovak, or Spanish, users may encounter difficulties in comprehending essential information. The translator extension ensures that users can grasp tender details in their preferred language, facilitating better decision-making.
                                </p>
                            </Col>
                            <Col>
                                <Card className='steps-card'>
                                    <Card.Body>
                                        <Card.Title>Installing the Translate Extension</Card.Title>
                                        <Card.Text>
                                            <ol>
                                                <li>
                                                    Open your browser (Edge, Google Chrome, or another). Click the browser's settings icon, typically in the top-right corner. Navigate to "Extensions".
                                                </li>
                                                <li>
                                                    In "Manage Extensions" section, look for "Chrome Web Store" or "Edge Add-ons Store" and click on it. Use the search function to find the Google Translate extension.
                                                </li>
                                                <li>
                                                    Click on the Google Translate extension, then click "Add to Chrome" or "Add to Edge" to install it.
                                                </li>
                                                <li>
                                                    Confirm the installation by selecting "Add extension." Wait for the extension to download and install.
                                                </li>
                                                <li>
                                                    Once installed, click the extension icon in the browser's toolbar to use it. You can translate text or entire pages.
                                                </li>
                                            </ol>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="body-row vertical-gif">
                            <img
                                className="image"
                                src={translatorExtensionGif}
                                onClick={() => this.changeArticleModalFlag(translatorExtensionGif, 'vertical')}
                                alt="Translation demonstration"
                            />
                        </Row>
                        <Row className="body-row horizontal-gif">
                            <img
                                className="image"
                                src={horizontalTranslatorExtensionGif}
                                onClick={() => this.changeArticleModalFlag(horizontalTranslatorExtensionGif, 'horizontal')}
                                alt="Translation demonstration"
                            />
                        </Row>
                        <Container className="share-and-more-arts-container">
                            <Container className="more-arts-container">
                                <Row className="body-row">
                                    <Col className="column-paragraph">
                                        <h2 className="more-arts-title">These articles may also interest you:</h2>
                                        <ul className="list-margin">
                                            <li className="summary-item" onClick={() => this.props.onClickBlogSection("track-tenders")}>
                                                Track Tenders: Never Miss Anything
                                            </li>
                                            <li
                                                className="summary-item"
                                                onClick={() => this.props.onClickBlogSection("notification-preferences")}
                                            >
                                                Notification Preferences: Your Smart Ally
                                            </li>
                                            <li className="summary-item" onClick={() => this.props.onClickBlogSection("reporting-and-dashboard")}>
                                                Share, Measure, and Grow
                                            </li>
                                            <li className="summary-item" onClick={() => this.props.onClickBlogSection("cpvs")}>
                                                Why Knowing the CPV Is Important
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                            <Container className="share-container">
                                <Row className="body-row">
                                    <Col>
                                        <h2 className="share-title">Share this article:</h2>
                                    </Col>
                                    <Col className="column-share-buttons" md="auto">
                                        <TelegramShareButton
                                            children={<TelegramIcon size={35} round={false} />}
                                            url={window.location.origin + "/blog#translate-extension"}
                                            title={"Check out this great article on Coowise Blog!"}
                                        />
                                        <EmailShareButton
                                            children={<EmailIcon size={35} round={false} />}
                                            url={window.location.origin + "/blog#translate-extension"}
                                            subject={"Translate to any language in seconds"}
                                            body={"Found this article on Coowise Blog. Check it out!"}
                                        />
                                        <WhatsappShareButton
                                            title={"Check out this great article on Coowise Blog!"}
                                            children={<WhatsappIcon size={35} round={false} />}
                                            url={window.location.origin + "/blog#translate-extension"}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                        <ArticleModal
                            articleModalFlag={this.state.articleModalFlag}
                            changeArticleModalFlag={this.changeArticleModalFlag}
                            image={this.state.image}
                            imageClass={this.state.imageClass}
                        />
                    </div>
                </section>
            </Container>
        );
    }
}

TranslatorExtensionArt.propTypes = {
    onClickBlogSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    changeArticleNavModalFlag: PropTypes.func.isRequired,
};

export default TranslatorExtensionArt;
