import React, { Component } from 'react';
import 'react-phone-number-input/style.css';
import { connect } from "react-redux";
import {
    getLiveReportHeaderSection
} from "../../../actions/generalActions";
import { Snackbar } from '@material/react-snackbar';
import TrendingCards from './TrendingCards';
import star from '../../../images/star.svg';
import globe from '../../../images/globe.svg';
import Flag_Ireland from '../../../images/Flag_Ireland.svg';
import Flag_Estonia from '../../../images/Flag_Estonia.svg';
import Flag_Slovakia from '../../../images/Flag_Slovakia.svg';
import Spinner from '../../Spinner';

const scrollToChart = (chartId) => {
    let element = document.getElementById(chartId);
    if (element != null) {
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
};

class HeaderSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.fetchingGetHeaderSection && this.props.headerSection == null && this.props.getHeaderSectionError == null)
            this.props.getLiveReportHeaderSection();

        return (
            <>
                <TrendingCards fetchingGetHeaderSection={this.props.fetchingGetHeaderSection} headerSection={this.props.headerSection} />
                <div className='real-time-card'>
                    <div className={"real-time-card-container one-section"}>
                        <div className={"real-time-card-section active"}>
                            <div className='header'>
                                {
                                    !this.props.fetchingGetHeaderSection
                                        ?
                                        <div className='title-container'>
                                            <img src={star} />
                                            <h3>Top Active Industries</h3>
                                        </div>
                                        :
                                        <div className='loader-bar'>
                                            <div className='bar-progress'></div>
                                        </div>
                                }
                            </div>
                            <div className='body'>
                                <div className='body-row'>
                                    {
                                        !this.props.fetchingGetHeaderSection
                                            ?
                                            <>
                                                <div className='title-container'>
                                                    <p>1</p>
                                                    <h3 className='title'>
                                                        {
                                                            this.props.headerSection != null && this.props.headerSection.firstAuthority != null
                                                                ? this.props.headerSection.firstAuthority.name
                                                                : 0
                                                        }
                                                    </h3>
                                                </div>
                                                <p className='number green'>
                                                    {
                                                        this.props.headerSection != null && this.props.headerSection.firstAuthority != null
                                                            ? this.props.headerSection.firstAuthority.amountOfTenders
                                                            : 0
                                                    }
                                                </p>
                                            </>
                                            :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                    }
                                </div>
                                <div className='body-row'>
                                    {
                                        !this.props.fetchingGetHeaderSection
                                            ?
                                            <>
                                                <div className='title-container'>
                                                    <p>2</p>
                                                    <h3 className='title'>
                                                        {
                                                            this.props.headerSection != null && this.props.headerSection.secondAuthority != null
                                                                ? this.props.headerSection.secondAuthority.name
                                                                : 0
                                                        }
                                                    </h3>
                                                </div>
                                                <p className='number green'>
                                                    {
                                                        this.props.headerSection != null && this.props.headerSection.secondAuthority != null
                                                            ? this.props.headerSection.secondAuthority.amountOfTenders
                                                            : 0
                                                    }
                                                </p>
                                            </>
                                            :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                    }
                                </div>
                                <div className='body-row'>
                                    {
                                        !this.props.fetchingGetHeaderSection
                                            ?
                                            <>
                                                <div className='title-container'>
                                                    <p>3</p>
                                                    <h3 className='title'>
                                                        {
                                                            this.props.headerSection != null && this.props.headerSection.lastAuthority != null
                                                                ? this.props.headerSection.lastAuthority.name
                                                                : 0
                                                        }
                                                    </h3>
                                                </div>
                                                <p className='number green'>
                                                    {
                                                        this.props.headerSection != null && this.props.headerSection.lastAuthority != null
                                                            ? this.props.headerSection.lastAuthority.amountOfTenders
                                                            : 0
                                                    }
                                                </p>
                                            </>
                                            :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='real-time-card'>
                    <div className={"real-time-card-container one-section"}>
                        <div className={"real-time-card-section active"}>
                            <div className='header'>
                                {
                                    !this.props.fetchingGetHeaderSection
                                        ?
                                        <div className='title-container'>
                                            <img src={globe} />
                                            <h3>Service status by Country</h3>
                                        </div>
                                        :
                                        <div className='loader-bar'>
                                            <div className='bar-progress'></div>
                                        </div>
                                }
                            </div>
                            <div className='body'>
                                <div className='body-row'>
                                    {
                                        !this.props.fetchingGetHeaderSection
                                            ?
                                            <>
                                                <div className='title-container'>
                                                    <img src={Flag_Ireland} className='flag' />
                                                    <h3 className='title'>Ireland</h3>
                                                </div>
                                                <p className='number green'>Available</p>
                                            </>
                                            :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                    }
                                </div>
                                <div className='body-row'>
                                    {
                                        !this.props.fetchingGetHeaderSection
                                            ?
                                            <>
                                                <div className='title-container'>
                                                    <img src={Flag_Estonia} className='flag' />
                                                    <h3 className='title'>Estonia</h3>
                                                </div>
                                                <p className='number green'>Available</p>
                                            </>
                                            :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                    }
                                </div>
                                <div className='body-row'>
                                    {
                                        !this.props.fetchingGetHeaderSection
                                            ?
                                            <>
                                                <div className='title-container'>
                                                    <img src={Flag_Slovakia} className='flag' />
                                                    <h3 className='title'>Slovakia</h3>
                                                </div>
                                                <p className='number yellow'>Under Adjustment</p>
                                            </>
                                            :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

    }
}

const mapStateToProps = (value) => {
    return {
        fetchingGetHeaderSection: value.general.fetchingGetHeaderSection,
        fetchedGetHeaderSection: value.general.fetchedGetHeaderSection,
        getHeaderSectionError: value.general.getHeaderSectionError,
        headerSection: value.general.headerSection,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveReportHeaderSection: () => dispatch(getLiveReportHeaderSection())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSection);