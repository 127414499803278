import React, { Component } from 'react';
import 'react-phone-number-input/style.css';
import { connect } from "react-redux";
import {
    getLiveReportServiceStatusSection
} from "../../../actions/generalActions";
import Flag_Ireland from '../../../images/Flag_Ireland.svg';
import Flag_Estonia from '../../../images/Flag_Estonia.svg';
import Flag_Slovakia from '../../../images/Flag_Slovakia.svg';
import { Icon } from "semantic-ui-react";

class ServiceStatusSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.fetchingGetServiceStatusSection && this.props.serviceStatusSection == null && this.props.getServiceStatusSectionError == null)
            this.props.getLiveReportServiceStatusSection();

        let irelandRegion =  this.props.serviceStatusSection != null && this.props.serviceStatusSection.regionStatusDTOs != null && this.props.serviceStatusSection.regionStatusDTOs.some(x => x.regionId == 1) 
            ? this.props.serviceStatusSection.regionStatusDTOs.find(x => x.regionId == 1) : null;

        let estonianRegion = this.props.serviceStatusSection != null && this.props.serviceStatusSection.regionStatusDTOs != null && this.props.serviceStatusSection.regionStatusDTOs.some(x => x.regionId == 1) 
        ? this.props.serviceStatusSection.regionStatusDTOs.find(x => x.regionId == 2) : null;

        return (
            <div class='divtable'>
                <table className="cookies-policy-table">
                    <thead>
                        <tr className='tr-head'>
                            <td className='thead-td-table'>
                                <p className='thead-text'>Region</p>
                            </td>
                            <td className='thead-td-table'>
                                <p className='thead-text'>Source</p>
                            </td>
                            <td className='thead-td-table'>
                                <p className='thead-text'>Service status</p>
                            </td>
                            <td className='thead-td-table'>
                                <p className='thead-text'>Opportunities (Weekly)</p>
                            </td>
                            <td className='thead-td-table'>
                                <p className='thead-text'>Tender Updates</p>
                            </td>
                            <td className='thead-td-table'>
                                <p className='thead-text'>Closing Soon</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='tbody-td-table'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <div className='region'>
                                                <img src={Flag_Estonia} className='flag' />
                                                <h3 className='title'>Estonia</h3>
                                            </div>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <p className='tbody-text'><a href="https://riigihanked.riik.ee/rhr-web/" target="_blank">Estonian Public Procurement Register</a></p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <p className='green'>Available</p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table center'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   <p className='blue'>{estonianRegion != null ? estonianRegion.news : 0}</p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table center icon'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <Icon className='close-icon' name='close'/>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table center'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   <p className='yellow'>{estonianRegion != null ? estonianRegion.expiresSoon : 0}</p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className='tbody-td-table'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <div className='region'>
                                                <img src={Flag_Ireland} className='flag' />
                                                <h3 className='title'>Ireland</h3>
                                            </div>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <p className='tbody-text'><a href="https://www.etenders.gov.ie/epps/home.do" target="_blank">eTenders</a></p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <p className='green'>Available</p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table center'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   <p className='blue'>{irelandRegion != null ? irelandRegion.news : 0}</p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table center'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   <p className='green'>{irelandRegion != null ? irelandRegion.updates : 0}</p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table center'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   <p className='yellow'>{irelandRegion != null ? irelandRegion.expiresSoon : 0}</p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className='tbody-td-table'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <div className='region'>
                                                <img src={Flag_Slovakia} className='flag' />
                                                <h3 className='title'>Slovakia</h3>
                                            </div>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <p className='tbody-text'><a href="https://www.uvo.gov.sk/" target="_blank">Public Procurement Office (UVO)</a></p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <p className='yellow'>Under Adjustment</p>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table center'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <div className='icon'>
                                                <Icon className='close-icon' name='close'/>
                                            </div>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table center'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <div className='icon'>
                                                <Icon className='close-icon' name='close'/>
                                            </div>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                            <td className='tbody-td-table center'>
                                {
                                    !this.props.fetchingGetServiceStatusSection
                                        ?   
                                            <div className='icon'>
                                                <Icon className='close-icon' name='close'/>
                                            </div>
                                        :
                                            <div className='loader-bar'>
                                                <div className='bar-progress'></div>
                                            </div>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );

    }
}

const mapStateToProps = (value) => {
    return {
        fetchingGetServiceStatusSection: value.general.fetchingGetServiceStatusSection,
        fetchedGetServiceStatusSection: value.general.fetchedGetServiceStatusSection,
        getServiceStatusSectionError: value.general.getServiceStatusSectionError,
        serviceStatusSection: value.general.serviceStatusSection
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveReportServiceStatusSection: () => dispatch(getLiveReportServiceStatusSection())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceStatusSection);