import React, { Component } from 'react';
import { Button } from "semantic-ui-react";
import {
    createProspect,
    changeCreateProspectStates
} from "../actions/generalActions";
import { connect } from "react-redux";
import Spinner from './Spinner';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';

class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            phone: "",
            comment: "",
            source: "Web Tenders",
            productId: 2,    //Tender Notify
            nameInputError: false,
            emailInputError: false,
            emailInputErrorType: 0,
            phoneInputError: false,
            firstTimeClicked: true,
            clicked: false,
            commentInputError: false
        }

        this.onChangeInput = this.onChangeInput.bind(this);
        this.onClickSend = this.onClickSend.bind(this);
        this.checkSend = this.checkSend.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onClickPhone = this.onClickPhone.bind(this);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("ContactUs");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "ContactUs", page: '/ContactUs' });
    }

    onChangeInput(e, input) {
        if (input == "Name" && e.target.value.length < 50) {
            const re = /^[a-zA-Z0-9 _]*$/;
            if (re.test(String(e.target.value).toLowerCase()))
                this.setState({ name: e.target.value, nameInputError: false });
        }
        else
            if (input == "Mail")
                this.setState({ email: e.target.value, emailInputError: false, emailInputErrorType: 0 });
            else
                if (input == "Comment")
                    this.setState({ comment: e.target.value, commentInputError: false });
    }

    onChangePhone(value) {
        if (value != undefined)
            this.setState({ phone: value, phoneInputError: false, clicked: false });
        else
            this.setState({ phone: "", phoneInputError: false, clicked: false });
    }

    onClickPhone(){
        this.setState({firstTimeClicked: false, clicked: true, phone: "+353"});
    }

    onClickSend() {
        if(this.checkSend()){
            if (this.props.cookieUp)
            {
                ReactGA4.gtag('event', 'click', {
                    'event_category': 'User',
                    'event_label': 'Click on Send Message',
                    'value':'contact'
                });
            }

            this.props.createProspect(
                this.state.name.trim(), 
                this.state.email, 
                this.state.phone, 
                this.state.comment.trim(), 
                this.state.source, 
                this.state.productId,
                document.cookie.includes("non_essential_cookies=true")
            );
            
            this.setState({ name: "", email: "", phone: "", comment: "" });
        }
        else{
            let validPhoneNumber = isValidPhoneNumber(this.state.phone);

            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let validMail = regex.test(String(this.state.email).toLowerCase());
            
            this.setState({
                nameInputError: this.state.name != "" ? false : true,
                lastNameInputError: this.state.lastName != "" ? false : true,
                emailInputError: this.state.email != "" && validMail ? false : true,
                emailInputErrorType: this.state.email != "" && validMail ? 0 : (this.state.email == "" ? 0 : 1),
                phoneInputError: (this.state.phone == "" || (this.state.phone != "" && validPhoneNumber)) ? false : true,
                commentInputError: this.state.comment != "" ? false : true
            });
        }
    }

    checkSend() 
    {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let flag = re.test(String(this.state.email).toLowerCase());

        if (this.state.name !== "" && flag !== "" && this.state.comment !== "" && (this.state.phone == "" || (this.state.phone != "" && isValidPhoneNumber(this.state.phone))))
            return true;
        else
            return false;
    }

    render() {
        const containerStyle = {
            background: 'linear-gradient(to bottom, rgba(40, 40, 114, 1) 0%, rgba(36,36,153,50) 50%, #242499 100%)',
            paddingBottom: '50px'
        }; 

        return (
            <>
                <section id="contact" class="about-area pt-120 pb-80" onMouseOver={this.props.currentSection != "ContactUs" ? this.changeSection : () => { }}>
                    <div class="container">
                        <div class="general-row">
                            <h3 class="title">Contact Us</h3>
                        </div>

                        {
                            this.props.fetchingCreateProspect == false
                                ? this.props.createProspectError == null
                                    ?
                                    <div class="row justify-content-center">
                                        <div class="col-lg-12">
                                            <div class="row form-group">
                                                <div class="col-lg-4">
                                                    <input
                                                        id="inputName"
                                                        type="text"
                                                        class="form-control inputTenders"
                                                        placeholder="Name"
                                                        value={this.state.name}
                                                        onChange={(e) => this.onChangeInput(e, "Name")}
                                                    />
                                                    {
                                                        this.state.nameInputError
                                                            ? <label className="labelIncompleteData">This field cannot be empty.</label>
                                                            : null
                                                    }
                                                </div>
                                                <div class="col-lg-4">
                                                    <input
                                                        id="inputEmail"
                                                        type="text"
                                                        class="form-control inputTenders"
                                                        placeholder="Email"
                                                        value={this.state.email}
                                                        onChange={(e) => this.onChangeInput(e, "Mail")}
                                                    />
                                                    {
                                                        this.state.emailInputError
                                                            ?
                                                                <label className="labelIncompleteData">
                                                                    {
                                                                        this.state.emailInputErrorType == 0
                                                                            ? "The E-mail cannot be empty."
                                                                            : "Your E-mail must be valid."
                                                                    }
                                                                </label>
                                                            : null
                                                    }
                                                </div>
                                                <div class="col-lg-4">
                                                    <div className="form-control inputTenders" onClick={this.state.firstTimeClicked ? this.onClickPhone : () => {}}>
                                                        <PhoneInput
                                                            international
                                                            defaultCountry={this.state.clicked ? "IE" : ""}
                                                            placeholder="Enter phone number (optional)"
                                                            value={this.state.phone}
                                                            onChange={(value) => this.onChangePhone(value)}
                                                        />
                                                    </div>
                                                    {
                                                        this.state.phoneInputError
                                                            ? <label className="labelIncompleteData">Your Phone must be valid.</label>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="row form-group">
                                                <div class="col">
                                                    <textarea
                                                        id="inputMessage"
                                                        class="form-control inputTenders"
                                                        placeholder="Message"
                                                        rows="4"
                                                        value={this.state.comment}
                                                        onChange={(e) => this.onChangeInput(e, "Comment")}
                                                    />
                                                    {
                                                        this.state.commentInputError
                                                            ? <label className="labelIncompleteData">The Message cannot be empty.</label>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <Button className="buttonPrimary" onClick={() => this.onClickSend()}>SEND</Button>
                                        </div>
                                    </div>
                                    :
                                    <div class="row justify-content-center">
                                        <div class="col-lg-12">
                                            <Button
                                                className="buttonPrimary"
                                                onClick={this.props.changeCreateProspectStates}
                                            >
                                                RETRY
                                            </Button>
                                        </div>
                                    </div>
                                :
                                <div class="row justify-content-center">
                                    <div class="col-lg-12">
                                        <div className="divSpinnerContact">
                                            <Spinner myClass="containerSpinnerSecondary" />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </section>
                <div style={containerStyle}></div>
            </>
        );
    }
}

ContactUs.propTypes = {
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

const mapStateToProps = (value) => {
    return {
        fetchingCreateProspect: value.general.fetchingCreateProspect,
        fetchedCreateProspect: value.general.fetchedCreateProspect,
        createProspectError: value.general.createProspectError
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createProspect: (userProspectName, userProspectMail, userProspectPhone, comment, source, productId, cookieUp) => dispatch(createProspect(userProspectName, userProspectMail, userProspectPhone, comment, source, productId, cookieUp)),
        changeCreateProspectStates: () => dispatch(changeCreateProspectStates())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);