import React, { Component } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import { connect } from "react-redux";
import {
    renewSubscription
} from "../../actions/userActions";

class RenewMembershipModal extends Component {
    constructor(props) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(event) {
        if (event === "Refresh"){
            window.location.href += "#pricing";
            window.location.reload();
        }
        else
            if (event === "buttonCancelModal")
                this.props.changeRenewMembershipModalFlag(event);
            else
                this.props.renewSubscription(this.props.planId);
    }

    render() {
        if (this.props.fetchingRenewSubscription == false) {
            if (this.props.fetchedRenewSubscription == false && this.props.renewSubscriptionError == null)
                return (
                    <ReactModal isOpen={this.props.renewMembershipModalFlag} className="modal changeSubscription animate__animated animate__fadeInDown animate__faster">
                        <Grid columns={2} divided>
                            <Grid.Row className="modalHeader">
                                <Grid.Column width={12} className="gridColumn">
                                    <h2 className="titleModal">Renew subscription</h2>
                                </Grid.Column>

                                <Grid.Column textAlign={"right"} width={4} className="gridColumn">
                                    <Button icon='close' onClick={() => this.closeModal("buttonCancelModal")} className="closeModal" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <div className="containerModal changeSubscription">
                            <div className="contentModal">
                                <p className="modalParagraph">
                                    If you want to renew your <a className='plan'> {this.props.planName}</a> plan click on CONFIRM.
                                </p>
                            </div>
                        </div>

                        <div className="modalFooter">
                            <div className="divEspaciado"></div>
                            <Button onClick={() => this.closeModal("buttonCancelModal")} className="buttonLabel">CANCEL</Button>
                            <Button content='CONFIRM' className="buttonPrimary" onClick={() => this.closeModal("buttonConfirmModal")} />
                        </div>
                    </ReactModal>
                );
            else
                return (
                    <ReactModal isOpen={this.props.renewMembershipModalFlag} className="modal changeSubscription animate__animated animate__fadeInDown animate__faster">
                        <Grid columns={2} divided>
                            <Grid.Row className="modalHeader">
                                <Grid.Column width={12} className="gridColumn">
                                    <h2 className="titleModal">Renew subscription</h2>
                                </Grid.Column>

                                <Grid.Column textAlign={"right"} width={4} className="gridColumn">
                                    <Button icon='close' onClick={() => this.closeModal("Refresh")} className="closeModal" />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>


                        {
                            this.props.fetchedRenewSubscription
                                ?
                                <div className="containerModal changeSubscription">
                                    <div className="contentModal">
                                        <p className="successfullChangeSubscription">
                                            Congratulations!
                                        </p>
                                        <p className="modalParagraph">
                                            You are subscribed to the  <a className='plan'> {this.props.planName}</a> plan.
                                        </p>
                                    </div>
                                </div>
                                :
                                <div className="containerModal changeSubscription">
                                    <div className="contentModal">
                                        <p className="failedChangeSubscription">
                                            An error has ocurred.
                                        </p>
                                        <p className="modalParagraph">
                                            You are not subscribed to the  <a className='plan'> {this.props.planName}</a> plan.
                                        </p>
                                    </div>
                                </div>
                        }
                    </ReactModal>
                );
        }
        else
            return (
                <ReactModal isOpen={this.props.renewMembershipModalFlag} className="modal changeSubscription animate__animated animate__fadeInDown animate__faster">
                    <Spinner myClass="containerSpinnerSecondary" />
                </ReactModal>
            );
    }
}

RenewMembershipModal.propTypes = {
    renewMembershipModalFlag: PropTypes.bool.isRequired,
    changeRenewMembershipModalFlag: PropTypes.func.isRequired,
    planId: PropTypes.number.isRequired,
    planName: PropTypes.string.isRequired
};

const mapStateToProps = (value) => {
    return {
        fetchingRenewSubscription: value.user.fetchingRenewSubscription,
        fetchedRenewSubscription: value.user.fetchedRenewSubscription,
        renewSubscriptionError: value.user.renewSubscriptionError
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        renewSubscription: () => dispatch(renewSubscription())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewMembershipModal);