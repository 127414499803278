import * as actionTypes from '../actions/actionTypes';
import ReactGA4 from 'react-ga4';

const initialState = {
    //Change Current Page

    currentPage: "",

    //Change Hash Id

    hashId: null,

    //Create Prospect

    fetchingCreateProspect: false,
    fetchedCreateProspect: false,
    createProspectError: null,

    //Get webtenders urls

    fetchingGetWebTendersUrls: false,
    fetchedGetWebTendersUrls: true,
    getWebTendersUrlsError: null,
    getWebTendersUrlsDTO: {"goToAppUrl": process.env.REACT_APP_GO_TO_APP_URL},

    //Current Section

    currentSection: "Home",

    //Accept cookies

    cookieUp: document.cookie.includes("essential_cookies=true"),

    //Get Live Report Header Section

    fetchingGetHeaderSection: false,
    fetchedGetHeaderSection: false,
    getHeaderSectionError: null,
    headerSection: null,

    //Get Live Report Service Status Section

    fetchingGetServiceStatusSection: false, 
    fetchedGetServiceStatusSection: false,
    serviceStatusSection: null,
    getServiceStatusSectionError: null
}

export default (state = initialState, action) => {

    switch (action.type){

        //Change Current Page

        case actionTypes.CHANGE_CURRENTPAGE: {
            return {
                ...state,
                currentPage: action.payload.currentPage
            }
            break;
        }

        //Change Hash Id

        case actionTypes.CHANGE_HASHID: {
            return {
                ...state,
                hashId: action.payload.hashId
            }
            break;
        }

        //Create Prospect

        case actionTypes.FETCH_CREATEPROSPECT + "_PENDING": {

            return {
                ...state, 
                fetchingCreateProspect: true,
                fetchedCreateProspect: false
            }
            break;
        }
        case actionTypes.FETCH_CREATEPROSPECT + "_REJECTED": {

            if (state.cookieUp)
            {
                ReactGA4.gtag('event', 'click', {
                    'event_category': 'User',
                    'event_label': 'Message not sent',
                    'value': state.language
                });
            }

            let error = null;
            if (action.payload.response == undefined)
                error = action.payload.message;
            else
                if (action.payload.response.data == undefined)
                    error = action.payload.message;
                else
                    error = action.payload.response.data;

            return {
                ...state, 
                fetchingCreateProspect: false,
                createProspectError: error
            }
            break;
        }
        case actionTypes.FETCH_CREATEPROSPECT + "_FULFILLED": {

            if (state.cookieUp)
            {
                ReactGA4.gtag('event', 'click', {
                    'event_category': 'User',
                    'event_label': 'Message sent',
                    'value': state.language
                });
            }
            
            return {
                ...state,
                fetchingCreateProspect: false,
                fetchedCreateProspect: true
            }
            break;
        }

        //Retry Create Prospect

        case actionTypes.CHANGE_CREATEPROSPECT_STATES: {
            return {
                ...state,
                createProspectError: null,
                fetchedCreateProspect: false
            }
            break;
        }

        //Get webtenders urls

        case actionTypes.FETCH_GETWEBTENDERSURLS + "_PENDING": {
            return {
                ...state, 
                fetchingGetWebTendersUrls: true,
                fetchedGetWebTendersUrls: false
            }
            break;
        }
        case actionTypes.FETCH_GETWEBTENDERSURLS + "_REJECTED": {
            let error = null;
            if (action.payload.response == undefined)
                error = action.payload.message;
            else
                if (action.payload.response.data == undefined)
                    error = action.payload.message;
                else
                    error = action.payload.response.data;

            return {
                ...state, 
                fetchingGetWebTendersUrls: false,
                getWebTendersUrlsError: error
            }
            break;
        }
        case actionTypes.FETCH_GETWEBTENDERSURLS + "_FULFILLED": {

            return {
                ...state,
                fetchingGetWebTendersUrls: false,
                fetchedGetWebTendersUrls: true,
                getWebTendersUrlsDTO: action.payload.data
            }
            break;
        }

        //Change Current Section

        case actionTypes.CHANGE_CURRENTSECTION: {
            return {
                ...state,
                currentSection: action.payload.currentSection
            }
            break;
        }

        //Accept cookies

        case actionTypes.ACCEPT_COOKIES: {
            return {
                ...state,
                cookieUp: true
            }
            break;
        }

        //Get Live Report Header Section

        case actionTypes.FETCH_GET_HEADER_SECTION + "_PENDING": {
            return {
                ...state, 
                fetchingGetHeaderSection: true,
                fetchedGetHeaderSection: false
            }
            break;
        }
        case actionTypes.FETCH_GET_HEADER_SECTION + "_REJECTED": {
            debugger;
            return {
                ...state, 
                fetchingGetHeaderSection: false,
                getHeaderSectionError: "An error has occurred. Please refresh the page."
            }
            break;
        }
        case actionTypes.FETCH_GET_HEADER_SECTION + "_FULFILLED": {          
            return {
                ...state, 
                fetchingGetHeaderSection: false, 
                fetchedGetHeaderSection: true,
                headerSection: action.payload.data
            }
            break;
        }

        //Get Live Report Service Status Section

        case actionTypes.FETCH_GET_SERVICE_STATUS_SECTION + "_PENDING": {
            return {
                ...state, 
                fetchingGetServiceStatusSection: true,
                fetchedGetServiceStatusSection: false
            }
            break;
        }
        case actionTypes.FETCH_GET_SERVICE_STATUS_SECTION + "_REJECTED": {
            debugger;
            return {
                ...state, 
                fetchingGetServiceStatusSection: false,
                getServiceStatusSectionError: "An error has occurred. Please refresh the page."
            }
            break;
        }
        case actionTypes.FETCH_GET_SERVICE_STATUS_SECTION + "_FULFILLED": {          
            return {
                ...state, 
                fetchingGetServiceStatusSection: false, 
                fetchedGetServiceStatusSection: true,
                serviceStatusSection: action.payload.data
            }
            break;
        }
    }
    return state;
}

const parseError = (payload) => {
    debugger;
    let error = "An error has occurred. Please refresh the page.";
    if(payload.reponse == undefined)
        error = payload.message;
    else
    if (payload.response.data == undefined)
        error = payload.message;
    else
    if(payload.response.data.errorMessage == undefined)
        error = payload.response.data;
    else
        error = payload.response.data.errorMessage

    return error;
}