import React, { Component } from 'react';
import Header from './Header';
import ExploreResources from './ExploreResources';
import Features from './Features';
import Pricing from './Pricing';
import Faqs from './Faqs';
import ContactUs from './ContactUs';
import Footer from './Footer';
import { connect } from "react-redux";
import {
    changeCreateProspectStates,
    changeCurrentSection
} from "../actions/generalActions";
import { Snackbar } from '@material/react-snackbar';
import '@material/react-snackbar/dist/snackbar.css';

class Home extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let hash = window.location.hash;

        if (hash != "") {
            window.history.pushState("", document.title, window.location.pathname + window.location.search);

            let hashes = hash.split("#");

            let hashToScroll = hashes.find(x => (x == "exploreResources" || x == "features" || x == "pricing" || x == "contact" || x == "faqs"));

            if (hashToScroll != null) {
                let element = document.getElementById(hashToScroll);
                if (element != null)
                    element.scrollIntoView();
            }
        }
    }

    componentDidUpdate() {
        let hash = window.location.hash;

        if (hash != "") {
            window.history.pushState("", document.title, window.location.pathname + window.location.search);

            let hashes = hash.split("#");

            let hashToScroll = hashes.find(x => (x == "exploreResources" || x == "features" || x == "pricing" || x == "contact" || x == "faqs"));

            if (hashToScroll != null) {
                
                let element = document.getElementById(hashToScroll);
                if (element != null)
                    element.scrollIntoView();
            }
        }
    }

    render() {
        return (
            <>
                <Header
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <ExploreResources
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <Features
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <Pricing
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <Faqs
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <ContactUs
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                <Footer
                    currentSection={this.props.currentSection}
                    changeCurrentSection={this.props.changeCurrentSection}
                    cookieUp={this.props.cookieUp}
                />

                {
                    this.props.createProspectError !== null || this.props.fetchedCreateProspect
                        ?
                        <Snackbar
                            className={this.props.fetchedCreateProspect ? "toastSuccess" : "errorToast"}
                            message=
                            {
                                this.props.fetchedCreateProspect
                                    ? "Your message was successfully sent."
                                    : "An error has occurred. Try again."
                            }
                            actionText="X"
                            onClose=
                            {
                                this.props.fetchedCreateProspect
                                    ? () => this.props.changeCreateProspectStates()
                                    : () => { }
                            }
                        />
                        : null
                }

                {
                    window.sessionStorage.getItem("errorLogin") !== null || this.props.goToAppError != null
                        ?
                        <Snackbar
                            className={"errorToast"}
                            message={window.sessionStorage.getItem("errorLogin") !== null ? window.sessionStorage.getItem("errorLogin") : this.props.goToAppError}
                            actionText="X"
                            onClose={() => { window.sessionStorage.removeItem("errorLogin")}}
                        />
                        : null
                }

                {
                    window.sessionStorage.getItem("sessionRefreshed") !== null
                        ?
                        <Snackbar
                            className={"infoToast"}
                            message={"Your session has been refreshed"}
                            actionText="X"
                            onClose={() => { window.sessionStorage.removeItem("sessionRefreshed")}}
                        />
                        : null
                }
            </>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        hashId: value.general.hashId,

        currentPage: value.general.currentPage,

        createProspectError: value.general.createProspectError,
        fetchedCreateProspect: value.general.fetchedCreateProspect,

        goToAppError: value.security.goToAppError,

        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCreateProspectStates: () => dispatch(changeCreateProspectStates()),
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);