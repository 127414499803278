import React, { Component } from 'react';
import smartFilters from '../images/feature-section/smart-filters.png';
import trackTenders from '../images/feature-section/track-tenders.png';
import notification1 from '../images/feature-section/notifications1.png';
import notification2 from '../images/feature-section/notifications2.png';
import notification3 from '../images/feature-section/notifications3.png';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';
import Carousel from './Carousel';
import { Link } from 'react-router-dom';

class Features extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
            currentNotificationImage: notification1,
            notificationImages: [notification1, notification2, notification3],
            notificationImageIndex: 0
        }

        this.changeSection = this.changeSection.bind(this);
        this.ref = React.createRef();
        this.transitionNotificationImages = this.transitionNotificationImages.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Features");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Features", page: '/Features' });
    }

    componentDidMount() {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    this.setState({ isVisible: entry.isIntersecting });
                });
            },
            { threshold: 0.1 }
        );
        observer.observe(this.ref.current);

        this.notificationImageInterval = setInterval(this.transitionNotificationImages, 3000); // Change image every 3 seconds
    }

    componentWillUnmount() {
        clearInterval(this.notificationImageInterval);
    }

    transitionNotificationImages() {
        this.setState(prevState => {
            let newIndex = (prevState.notificationImageIndex + 1) % prevState.notificationImages.length;
            return {
                notificationImageIndex: newIndex,
                currentNotificationImage: prevState.notificationImages[newIndex]
            };
        });
    }

    render() {

        let items = [
            <div class="row feature-row">
                <div class="feature-content">
                    <div className='feature-content-header'>
                        <h3>Relevant Alerts</h3>
                    </div>
                    <div className='feature-content-body'>
                        <p class="text">
                            Whether you want to receive an SMS or email alert as soon as a new government contract is available, 
                            or be notified every time an update is made in a tender that you already follow, we have you covered. 
                            We put all the information you need to respond quickly to government RFQs and RFPs in your hands.
                        </p>
                        <div className='button-container'>
                            <Link to={"/blog#notification-preferences"} target="_blank">
                                <button>Learn more...</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="feature-image">
                    <img src={this.state.currentNotificationImage} alt="about" />
                </div>
            </div>,
            <div class="row feature-row">
                <div class="feature-content">
                    <div className='feature-content-header'>
                        <h3>Smart Filters</h3>
                    </div>
                    <div className='feature-content-body'>
                        <p class="text">
                            Customize your tender searches according to your specific requirements, 
                            and find every contract opportunity in our database that fits perfectly with what your business needs. 
                            As soon as our system finds a new tender that meets all the criteria of your search, 
                            you will receive an immediate alert in your inbox.
                        </p>
                        <div className='button-container'>
                            <Link to={"/blog#smart-filters"} target="_blank">
                                <button>Explore Smart Filtering</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="feature-image">
                    <img src={smartFilters} alt="about" />
                </div>
            </div>,
            <div class="row feature-row reverse">
                <div class="feature-image">
                    <img src={trackTenders} alt="about" />
                </div>
                <div class="feature-content">
                    <div className='feature-content-header'>
                        <h3>Track Tenders</h3>
                    </div>
                    <div className='feature-content-body'>
                        <p class="text">
                            You want to improve the efficiency of your bidding process, we have the technology to make it possible. 
                            Keep track of all the bids your company has submitted or is interested in. 
                            Monitor tender status, crucial deadlines, and any important changes in the contracts.
                        </p>
                        <div className='button-container'>
                            <Link to={"/blog#track-tenders"} target="_blank">
                                <button>Track Your Tenders Now</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>   
        ];

        return (
            <section id="features" class="about-area pt-120" onMouseOver={this.props.currentSection != "Features" ? this.changeSection : () => { }}>
                <div id="features2" class="container" ref={this.ref}>
                    <div class="general-row">
                        <h3 class="title">Features</h3>
                    </div>
                    <Carousel items={items} isVisible={this.state.isVisible}/>
                </div>
            </section>
        );
    }
}

Features.propTypes = {
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

export default Features;