import React, { Component } from 'react';
import iconNoCheck from '../../images/cw_switch_disabled.svg';
import iconCheck from '../../images/cw_switch_enabled.svg';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class Free extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div class="containerHeaderPlan">
                    <div class="containerTitlePlan">
                        <h3 class="titlePlan">FREE</h3>
                        <h8 class="text-gray">To discover our platform and its features</h8>
                        <h2 className='price'>$ 0<span className='mo'>/ mo</span></h2>
                    </div>
                    {
                        this.props.membership == null
                            ?
                                <Link to={"/sign-up"} className="pricing">
                                    <Button content='Get Started' className='get-started' onClick={() => {window.sessionStorage.setItem("planId", 1);}}/>
                                </Link>
                            : 
                                this.props.membership.id == 1
                                    ? 
                                        !this.props.membership.expired
                                            ? <Button content='Active' className='get-started active'/>
                                            : <Button content='Expired' className='get-started active'/>
                                    : <Button content='Used' className='get-started used'/>
                            
                    }
                </div>
                
                <div class="containerChecklist">
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Email Notifications</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Smart Filters *</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Track Tender *</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Basic Finder</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Charts and Statistics</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Export Data</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconCheck} />
                        <span class="textCheck">Reports</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconNoCheck} />
                        <span class="textDisabled">SMS Notifications</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconNoCheck} />
                        <span class="textDisabled">Web API Access</span>
                    </div>
                    <div class="itemCheck">
                        <img src={iconNoCheck} />
                        <span class="textDisabled">AI Capabilities</span>
                    </div>
                </div>
                <div className='plan-paragraph'>
                    <p>* Limited access</p>
                </div>
            </>
        );
    }
}

Free.propTypes = {
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

export default (Free);