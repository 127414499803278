import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';

const DarkModeToggle = () => {
    let ligthMode = window.localStorage.getItem("ligth-mode");
    const [active, setActive] = useState(ligthMode != null ? JSON.parse(ligthMode) : false);

    const setDarkMode = () => {
        let body = document.getElementsByTagName('body')[0];

        if (active)
            body.classList.add("dark-mode-body");
        else
            body.classList.remove("dark-mode-body");

        window.localStorage.setItem("ligth-mode", !active);

        setActive(!active);
    };

    return (
        <label className="react-switch">
            <input
                checked={active}
                onChange={setDarkMode}
                className="react-switch-checkbox"
                type="checkbox"
            />
            <div className="react-switch-button" />
            <div className="react-switch-labels">
                <span><Icon className='sun' name='sun outline' /></span>
                <span><Icon className='moon' name='moon outline' /></span>
            </div>
        </label>
    );
};

export default DarkModeToggle;