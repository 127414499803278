import React, { Component } from 'react';
import NavbarWeb from './Components/Navbar/NavbarWeb';
import Routes from './Components/Routes';
import { BrowserRouter } from "react-router-dom";
import Spinner from './Components/Spinner';
import { connect } from "react-redux";
import {
  getUrls,
  acceptCookies
} from "./actions/generalActions";
import {
  getUserData,
  setupLoginVariables
} from "./actions/securityActions";
import './css/styles.scss';
import ReactGA4 from 'react-ga4';
import '@material/react-snackbar/dist/snackbar.css';
import 'react-vertical-timeline-component/style.min.css';
import {
  CookieContextProvider,
  CookieBanner,
  CookieDialog,
  NonEssencialCookies,
  EssencialCookies
} from '@coowise/cookies-preferences-package';

class App extends Component {
  constructor(props) {
    super(props);

    this.takeAccessToken = this.takeAccessToken.bind(this);
  }

  componentDidMount() {
    window.onpopstate = () => {
      window.location.reload();
    };
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  takeAccessToken() {
    let hash = window.location.hash;
    let array = hash.split("#");

    array.forEach((element, index) => {
      if (element == "") {
        array.splice(index, 1);
        index--;
      }
    });

    if (array.length === 3) {
      window.sessionStorage.setItem("accessToken", array[1]);
      window.sessionStorage.setItem("refreshToken", array[2]);
      let newHash = hash.replace("#"+array[1]+"#"+array[2], "")
      window.location.hash = newHash;
      let tokensDTO = { 'accessToken': array[1], 'refreshToken': array[2] };
      this.props.setupLoginVariables(tokensDTO);
    }
    else
      if (array.length === 2 && window.location.pathname == "/satisfaction-survey") {
        window.sessionStorage.setItem("accessToken", array[0]);
        window.sessionStorage.setItem("refreshToken", array[1]);
        let newHash = hash.replace("#" + array[0] + "#" + array[1], "")
        window.location.hash = newHash;
        let tokensDTO = { 'accessToken': array[1], 'refreshToken': array[2] };
        this.props.setupLoginVariables(tokensDTO);
      }
  }

  render() {
    if (this.props.fetchingGoToApp == false) {

      if (window.localStorage.getItem("ligth-mode") != null) {
        if (JSON.parse(window.localStorage.getItem("ligth-mode"))) {
          let body = document.getElementsByTagName('body')[0];
          body.classList.remove("dark-mode-body");
        }
        else {
          let body = document.getElementsByTagName('body')[0];
          body.classList.add("dark-mode-body");
        }
      }
      else{
        window.localStorage.setItem("ligth-mode", false);
        let body = document.getElementsByTagName('body')[0];
        body.classList.add("dark-mode-body");
      }

      let token = window.sessionStorage.getItem("accessToken");

      if (token == null)
        this.takeAccessToken()

      let pathName = window.location.pathname;

      if (pathName != "/" && pathName != "/cookies-policy" && pathName != "/sign-up" && pathName != "/privacy-policy" && pathName != "/blog"
        && pathName != "/terms-of-use-and-conditions" && pathName != "/satisfaction-survey" && pathName != "/api-reference" /* && pathName != "/live-report"  */
        && pathName != "/tender-notify-beta-closed")
        window.location.pathname = "/";

      if (this.props.cookieUp) {
        ReactGA4.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID); // Your Measurement ID
        ReactGA4.send({ hitType: "pageview", title: "Main", page: '/Main' });
      }

      let fetchingLogin = window.sessionStorage.getItem("fetchingLogin") != null ? JSON.parse(window.sessionStorage.getItem("fetchingLogin")) : this.props.fetchingLogin;
      let fetchedLogin = window.sessionStorage.getItem("fetchedLogin") != null ? JSON.parse(window.sessionStorage.getItem("fetchedLogin")) : this.props.fetchedLogin;
      let accessToken = window.sessionStorage.getItem("accessToken") != null ? window.sessionStorage.getItem("accessToken") : (this.props.tokensDTO != null ? this.props.tokensDTO.accessToken : this.props.tokensDTO);

      if (fetchingLogin == false) {
        if (accessToken != null && fetchedLogin == true) {

          if (pathName != "/" && pathName != "/cookies-policy" && pathName != "/privacy-policy" && pathName != "/terms-of-use-and-conditions" 
            && pathName != "/blog" && pathName != "/api-reference" && pathName != "/satisfaction-survey" /* && pathName != "/live-report"  */
            && pathName != "/tender-notify-beta-closed")
            window.location.pathname = "/";

          if (this.props.userData == null && this.props.fetchingGetUserData == false)
            this.props.getUserData();

          if (this.props.fetchedGetUserData) {
            if (this.props.fetchingGetWebTendersUrls == false && this.props.getWebTendersUrlsDTO == null && this.props.getWebTendersUrlsError == null)
              this.props.getUrls();

            if (this.props.getWebTendersUrlsDTO != null || this.props.getWebTendersUrlsError != null)
              return (
                <div className="App">
                  <BrowserRouter>
                    <NavbarWeb />
                    <Routes />
                  </BrowserRouter>
                  <CookieContextProvider source="web-tenders">
                    <CookieBanner
                      title={"Your privacy"}
                      message={"We use our own and third-party cookies to improve our services and show you advertising related to your preferences, by analyzing your browsing habits. Click on ACCEPT to confirm that you have read and accepted the information presented."}
                      policyText={"To find out more, read our updated cookies policy in the link"}
                      policyLinkText={"Cookies Policy"}
                      policyLink={"/cookies-policy"}
                      acceptButtonText={"ACCEPT"}
                      manageConsentText={"Manage Preferences"}
                      onAccept={this.props.acceptCookies}
                    >
                      <CookieDialog
                        title={"Your privacy options"}
                        message={"Please review and manage your privacy settings belows"}
                        confirmText={"Save Preferences"}
                      >
                        <NonEssencialCookies
                          title={"Non-essential"}
                          message={"The following cookies are NOT strictly necessary for enabling access and use of our Platform and can be disabled. "}
                          policyText={"For more information on how these cookies work, please review our "}
                          policyLinkText={"Privacy Policy"}
                          policyLink="/cookies-policy"
                          messages={["By checking this box, I consent to Coowise (CoowiseIT OU), its affiliates, and service providers processing my personal data for the purposes described in the Privacy Policy. I understand that I can withdraw my consent at any time."]}
                        />
                        <EssencialCookies
                          title={"Essential cookies (required)"}
                          message={"These cookies are strictly necessary to enable access and use of our Platform. They provide necessary functionality to ensure the proper performance, security and functionality of our Platform. These cookies cannot be disabled."}
                          policyText=""
                          policyLinkText=""
                          policyLink=""
                        />
                      </CookieDialog>
                    </CookieBanner>
                  </CookieContextProvider>
                </div>
              );
            else
              return (<Spinner myClass="containerSpinnerGeneral" />);
          }
          else
            return (<Spinner myClass="containerSpinnerGeneral" />);
        }
        else {
          if (pathName != "/" && pathName != "/cookies-policy" && pathName != "/sign-up" && pathName != "/privacy-policy" 
          && pathName != "/terms-of-use-and-conditions" && pathName != "/blog" && pathName != "/api-reference" /* && pathName != "/live-report"  */
          && pathName != "/tender-notify-beta-closed")
            window.location.pathname = "/";

          if (this.props.fetchingGetWebTendersUrls == false && this.props.getWebTendersUrlsDTO == null && this.props.getWebTendersUrlsError == null)
            this.props.getUrls();

          if (this.props.getWebTendersUrlsDTO != null || this.props.getWebTendersUrlsError != null)
            return (
              <div className="App">
                <BrowserRouter>
                  <NavbarWeb />
                  <Routes />
                </BrowserRouter>
                <CookieContextProvider source="web-tenders">
                  <CookieBanner
                    title={"Your privacy"}
                    message={"We use our own and third-party cookies to improve our services and show you advertising related to your preferences, by analyzing your browsing habits. Click on ACCEPT to confirm that you have read and accepted the information presented."}
                    policyText={"To find out more, read our updated cookies policy in the link"}
                    policyLinkText={"Cookies Policy"}
                    policyLink={"/cookies-policy"}
                    acceptButtonText={"ACCEPT"}
                    manageConsentText={"Manage Preferences"}
                    onAccept={this.props.acceptCookies}
                  >
                    <CookieDialog
                      title={"Your privacy options"}
                      message={"Please review and manage your privacy settings belows"}
                      confirmText={"Save Preferences"}
                    >
                      <NonEssencialCookies
                        title={"Non-essential"}
                        message={"The following cookies are NOT strictly necessary for enabling access and use of our Platform and can be disabled. "}
                        policyText={"For more information on how these cookies work, please review our "}
                        policyLinkText={"Privacy Policy"}
                        policyLink="/cookies-policy"
                        messages={["By checking this box, I consent to Coowise (CoowiseIT OU), its affiliates, and service providers processing my personal data for the purposes described in the Privacy Policy. I understand that I can withdraw my consent at any time."]}
                      />
                      <EssencialCookies
                        title={"Essential cookies (required)"}
                        message={"These cookies are strictly necessary to enable access and use of our Platform. They provide necessary functionality to ensure the proper performance, security and functionality of our Platform. These cookies cannot be disabled."}
                        policyText=""
                        policyLinkText=""
                        policyLink=""
                      />
                    </CookieDialog>
                  </CookieBanner>
                </CookieContextProvider>
              </div>
            );
          else
            return (<Spinner myClass="containerSpinnerGeneral" />);
        }

      }
      else
        return (<Spinner myClass="containerSpinnerGeneral" />);
    }
    else
      return (<Spinner myClass="containerSpinnerGeneral" />);
  }
}

const mapStateToProps = (value) => {
  return {
    fetchingGetWebTendersUrls: value.general.fetchingGetWebTendersUrls,
    fetchedGetWebTendersUrls: value.general.fetchedGetWebTendersUrls,
    getWebTendersUrlsError: value.general.getWebTendersUrlsError,
    getWebTendersUrlsDTO: value.general.getWebTendersUrlsDTO,

    cookieUp: value.general.cookieUp,

    userData: value.security.userData,
    tokensDTO: value.security.tokensDTO,
    fetchingLogin: value.security.fetchingLogin,
    fetchedLogin: value.security.fetchedLogin,
    loginError: value.security.loginError,

    fetchingGetUserData: value.security.fetchingGetUserData,
    fetchedGetUserData: value.security.fetchedGetUserData,

    fetchingGoToApp: value.security.fetchingGoToApp
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUrls: () => dispatch(getUrls()),
    getUserData: () => dispatch(getUserData()),
    setupLoginVariables: (tokensDTO) => dispatch(setupLoginVariables(tokensDTO)),
    acceptCookies: () => dispatch(acceptCookies())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);