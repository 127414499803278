import axios from 'axios';

export const IS_DEVELOPMENT = process.env.REACT_APP_IS_DEVELOPMENT;
export const SERVER_HOSTNAME = process.env.REACT_APP_SERVER_HOSTNAME;
export const SERVER_PORT = process.env.REACT_APP_API_PORT;
export const STRIPE_SERVER_HOSTNAME = process.env.REACT_APP_STRIPE_SERVER_HOSTNAME;
export const STRIPE_SERVER_PORT =process.env.REACT_APP_STRIPE_API_PORT;

export function axiosConfig() {
    axios.interceptors.request.use(async function (config) {

        if (IS_DEVELOPMENT)
            config.baseURL = `${SERVER_HOSTNAME}:${SERVER_PORT}/`;
        else
            config.baseURL = `${window.location.hostname}:${SERVER_PORT}/`;

        config.headers['Content-Type'] = 'application/json';
        config.cors = true;
        config.timeout = 100000000000000;

        let fetchedLogin = window.sessionStorage.getItem("fetchedLogin") != null ? JSON.parse(window.sessionStorage.getItem("fetchedLogin")) : false;
        let accessToken = window.sessionStorage.getItem("accessToken") != null ? window.sessionStorage.getItem("accessToken") : null;
        
        if ((fetchedLogin == true && accessToken != null))
            config.headers.Authorization = "Bearer " + accessToken;

        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        return response;
    }, async function (error) {
        if (error.response != undefined && error.response.status === 401) {
            let errorCode = error.response.data.ErrorCode;
            if (errorCode == 1) {
                let refreshToken = window.sessionStorage.getItem("refreshToken") != null ? window.sessionStorage.getItem("refreshToken") : null;

                if (refreshToken != null) {
                    let refreshTokenDTO = {
                        "RefreshToken": refreshToken
                    };

                    await axios
                        .post("api/login/webtendersrefreshtoken", refreshTokenDTO)
                        .then((response) => {
                            window.sessionStorage.setItem("accessToken", response.data.accessToken);
                            window.sessionStorage.setItem("refreshToken", response.data.refreshToken);
                            window.sessionStorage.setItem("sessionRefreshed", "");
                            window.location.reload();
                        })
                        .catch((er) => {
                            window.sessionStorage.clear();
                            window.sessionStorage.setItem("errorLogin", er.response.data.errorMessage);
                            window.location.pathname = "/";
                        });
                }
                else {
                    window.sessionStorage.clear();
                    window.sessionStorage.setItem("errorLogin", "Your session has expired.");
                    window.location.pathname = "/";
                }
            }
            else {
                window.sessionStorage.clear();
                window.sessionStorage.setItem("errorLogin", errorCode == 2 ? "User not valid." : "There was an error with your session.");
                window.location.pathname = "/";
            }
        }

        return Promise.reject(error);
    });
}