import {combineReducers} from 'redux';
import paymentReducer from "./paymentReducer";
import userReducer from './userReducer';
import generalReducer from './generalReducer';
import securityReducer from './securityReducer';

const allReducers = combineReducers({
    payment: paymentReducer,
    user: userReducer,
    general: generalReducer,
    security: securityReducer
});

const rootReducer = (state, action) => {
    return allReducers(state, action)
};

export default rootReducer;