import * as actionTypes from '../actions/actionTypes';
export const REACT_APP_GO_TO_APP_URL = process.env.REACT_APP_GO_TO_APP_URL;

const initialState = {

    //SignUp

    fetchingSignUp: false,
    fetchedSignUp: false,
    signUpError: null,
    signUpDTO: null,

    //GetSignUpGeneralData

    fetchingSignUpGeneralData: false,
    fetchedSignUpGeneralData: false,
    signUpGeneralDataError: null,
    signUpGeneralDataDTO: null,

    //Change Subscription

    fetchingChangeSubscription: false,
    fetchedChangeSubscription: false,
    changeSubscriptionError: null,

    //Renew Subscription

    fetchingRenewSubscription: false,
    fetchedRenewSubscription: false,
    renewSubscriptionError: null,

    //Send Satisfaction Survey

    fetchingSendSatisfactionSurvey: false,
    fetchedSendSatisfactionSurvey: false,
    sendSatisfactionSurveyError: null
}

export default (state = initialState, action) => {

    switch (action.type){

        //SignUp

        case actionTypes.FETCH_SIGNUP + "_PENDING": {
            return {
                ...state, 
                fetchingSignUp: true,
                fetchedSignUp: false
            }
            break;
        }
        case actionTypes.FETCH_SIGNUP + "_REJECTED": {
            let error = null;
            if(action.payload.response == undefined)
                error = action.payload.message;
            else
            if (action.payload.response.data == undefined)
                error = action.payload.message;
            else
                error = action.payload.response.data;

            return {
                ...state, 
                fetchingSignUp: false,
                signUpError: error
            }
            break;
        }
        case actionTypes.FETCH_SIGNUP + "_FULFILLED": {

            let planId = window.sessionStorage.getItem("planId");

            if(planId == 1)
                window.location.href = REACT_APP_GO_TO_APP_URL + "#successfullsubscribed";
            else
            {
                if(action.payload.data != null)
                    window.location.href = action.payload.data;
            }

            return {
                ...state,
                fetchingSignUp: false,
                fetchedSignUp: true,
                signUpDTO: action.payload.data
            }
            break;
        }

        //Change Sign-up states

        case actionTypes.CHANGE_SIGN_UP_STATES: {
            return {
                ...state,
                signUpError: null
            }
            break;
        }

        //GetSignUpGeneralData

        case actionTypes.FETCH_SIGNUPGENERALDATA + "_PENDING": {
            return {
                ...state, 
                fetchingSignUpGeneralData: true,
                fetchedSignUpGeneralData: false
            }
            break;
        }
        case actionTypes.FETCH_SIGNUPGENERALDATA + "_REJECTED": {
            let error = null;
            if(action.payload.response == undefined)
                error = action.payload.message;
            else
            if (action.payload.response.data == undefined)
                error = action.payload.message;
            else
                error = action.payload.response.data;

            return {
                ...state, 
                fetchingSignUpGeneralData: false,
                signUpGeneralDataError: error
            }
            break;
        }
        case actionTypes.FETCH_SIGNUPGENERALDATA + "_FULFILLED": {
            return {
                ...state,
                fetchingSignUpGeneralData: false,
                fetchedSignUpGeneralData: true,
                signUpGeneralDataDTO: action.payload.data
            }
            break;
        }

        //Change Subscription

        case actionTypes.FETCH_CHANGE_SUBSCRIPTION + "_PENDING": {
            return {
                ...state, 
                fetchingChangeSubscription: true,
                fetchedChangeSubscription: false
            }
            break;
        }
        case actionTypes.FETCH_CHANGE_SUBSCRIPTION + "_REJECTED": {
            let error = null;
            if(action.payload.response == undefined)
                error = action.payload.message;
            else
            if (action.payload.response.data == undefined)
                error = action.payload.message;
            else
                error = action.payload.response.data;

            return {
                ...state, 
                fetchingChangeSubscription: false,
                changeSubscriptionError: error
            }
            break;
        }
        case actionTypes.FETCH_CHANGE_SUBSCRIPTION + "_FULFILLED": {
            if(action.payload.data != null)
                    window.location.href = action.payload.data;
                
            return {
                ...state,
                fetchingChangeSubscription: false,
                fetchedChangeSubscription: true
            }
            break;
        }

        //Renew Subscription

        case actionTypes.FETCH_RENEW_SUBSCRIPTION + "_PENDING": {
            return {
                ...state, 
                fetchingRenewSubscription: true,
                fetchedRenewSubscription: false
            }
            break;
        }
        case actionTypes.FETCH_RENEW_SUBSCRIPTION + "_REJECTED": {
            let error = null;
            if(action.payload.response == undefined)
                error = action.payload.message;
            else
            if (action.payload.response.data == undefined)
                error = action.payload.message;
            else
                error = action.payload.response.data;

            return {
                ...state, 
                fetchingRenewSubscription: false,
                renewSubscriptionError: error
            }
            break;
        }
        case actionTypes.FETCH_RENEW_SUBSCRIPTION + "_FULFILLED": {
            return {
                ...state,
                fetchingRenewSubscription: false,
                fetchedRenewSubscription: true
            }
            break;
        }

        //Send Satisfaction Survey

        case actionTypes.FETCH_SEND_SATISFACTION_SURVEY + "_PENDING": {
            return {
                ...state, 
                fetchingSendSatisfactionSurvey: true,
                fetchedSendSatisfactionSurvey: false
            }
            break;
        }
        case actionTypes.FETCH_SEND_SATISFACTION_SURVEY + "_REJECTED": {
            let error = null;
            if(action.payload.response == undefined)
                error = action.payload.message;
            else
            if (action.payload.response.data == undefined)
                error = action.payload.message;
            else
                error = action.payload.response.data;

            return {
                ...state, 
                fetchingSendSatisfactionSurvey: false,
                sendSatisfactionSurveyError: error
            }
            break;
        }
        case actionTypes.FETCH_SEND_SATISFACTION_SURVEY + "_FULFILLED": {
            return {
                ...state,
                fetchingSendSatisfactionSurvey: false,
                fetchedSendSatisfactionSurvey: true
            }
            break;
        }

        //Change Send satisfaction survey states

        case actionTypes.CHANGE_SEND_SATISFACTION_SURVEY_STATES: {
            return {
                ...state,
                fetchedSendSatisfactionSurvey: false,
                sendSatisfactionSurveyError: null
            }
            break;
        }
    }
    return state;
}