import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import notificationPreferenceGif from '../../images/notification-preference-article/notification-preference.gif';
import ReactGA4 from 'react-ga4';
import {
    EmailShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailIcon,
    TelegramIcon,
    WhatsappIcon
} from "react-share";
import ArticleModal from '../Modals/ArticleModal';
import PropTypes from 'prop-types';
import magnifyingGlassPrimaryColor from '../../images/magnifying-glass-primary-color.svg';

class NotificationPreferenceArt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articleModalFlag: false,
            image: null,
            imageClass: ""
        }

        this.changeSection = this.changeSection.bind(this);
        this.changeArticleModalFlag = this.changeArticleModalFlag.bind(this);
    }

    changeArticleModalFlag(image, imageClass){
        this.setState({ 
            articleModalFlag: !this.state.articleModalFlag, 
            image: image, 
            imageClass: imageClass
        });
    }

    changeSection() {
        this.props.changeCurrentSection("Blog-Notification-Preference-Article");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'Blog-Notification-Preference-Article', page: '/Blog-Notification-Preference-Article' });
    }

    render() {
        return (
            <Container className="art-container article" onMouseOver={this.props.currentSection != "Blog-Notification-Preference-Article" ? this.changeSection : () => { }}>
                <section class="art-section articles">
                    <div>
                        <Row className="title-row navigation">
                            <div md="auto" className='title-col'>
                                <ul class='navigation'>
                                    <li>
                                        <a href={window.location.origin + "/"}>Home</a>
                                    </li>
                                    <li>
                                        <a href={window.location.origin + "/blog"}>Tender Notify Blog</a>
                                    </li>
                                    <li>
                                        Notification Preferences: your smart ally
                                    </li>
                                </ul>
                            </div>
                            <img src={magnifyingGlassPrimaryColor} onClick={this.props.changeArticleNavModalFlag}/>
                        </Row>
                        <Row className="title-row">
                            <div md="auto">
                                <hr class="line-title"></hr>
                                <h1 className="title-art">Notification Preferences</h1>
                                <p className='text-below-title'>Notification Preferences: your smart ally</p>
                            </div>
                            <div className="second-title-col" md="auto">
                                <p className='published-day'>Updated on Jan 03 2023 10:00 AM • 2 minutes to read</p>
                            </div>
                        </Row>
                        <Row className="body-row">
                            <Col className="column-paragraph before-np-image" md="auto">
                                <p className='art-paragraph'>
                                    Tender Notify is designed so that users only need to log into the app when receiving a notification around something new or an important change is happening in the trends they are following according to their selected preferences. This type of action is key as it allows our customers to have more effective time management and business predictability in an automated way.
                                </p>
                                <p className='art-paragraph'>
                                    To create <span class='text'>notification preferences</span> the first thing you need to define is the recipients, you may be able to create a certain number of recipients according to your membership. Each of them will be able to unsubscribe at the moment they receive the notification or they can do it from the platform in the notification screen.
                                </p>
                                <br></br>
                                <h2 className="art-subtitle">Set it up in 3 simple steps</h2>
                                <br></br>
                                <p className='art-paragraph'>
                                    <span class='text'>Choose a channel:</span> Tender Notify gives you the possibility to choose whether you want to receive your notifications via email, SMS or WhatsApp (Coming soon), and you can select multiple channels depending on the recipient.
                                </p>
                            </Col>
                            <Col className="column-paragraph with-gif before-keywords" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="first-column np-gif" md="auto">
                                        <p className='art-paragraph'>
                                            <span class='text'>Select a frequency:</span> According to your level of interest in certain tenders it is important to choose how often you want to receive alerts (e.g. daily, weekly, or monthly).
                                        </p>
                                        <p className='art-paragraph'>
                                            <span class='text'>Associate an event:</span> You can select to receive notifications when the <span class='text'>tracked tenders</span> have any modification or associate an alert.
                                        </p>
                                        <p className='art-paragraph'>
                                            Overall, this is a useful tool for staying informed about the latest opportunities and making informed decisions.
                                        </p>
                                    </Col>
                                    <Col className="second-column" md="auto">
                                        <img class="image" src={notificationPreferenceGif} onClick={() =>this.changeArticleModalFlag(notificationPreferenceGif, "horizontal")}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="column-paragraph" md="auto">
                                <h2 className="art-subtitle">Keywords for this article</h2>
                                <p className='art-paragraph'>
                                    track tender, tenders, updated, expired soon, closed, awarded, expired, notification preferences, tracking, recipient, smart filter.
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Container className='share-and-more-arts-container'>
                        <Container className='more-arts-container'>
                            <Row className="body-row">
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="more-arts-title">{"These articles may also interest you"}</h2>
                                    <ul className='list-margin'>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("track-tenders")}>
                                            Track Tenders so you don't miss anything
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("smart-filters")}>
                                            How to boost your search using Smart Filters
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("reporting-and-dashboard")}>
                                            Share, measure and grow
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("cpvs")}>
                                            Why is it important to know the potential of the CPV?
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='share-container'>
                            <Row className="body-row">
                                <Col md="auto">
                                    <h2 className="share-title">{"Share"}</h2>
                                </Col>
                                <Col className="column-share-buttons" md="auto">
                                    <TelegramShareButton
                                        children={<TelegramIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#notification-preferences"}
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <EmailShareButton
                                        children={<EmailIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#notification-preferences"}
                                        subject={"Notification Preferences: your smart ally"}
                                        body={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <WhatsappShareButton
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                        children={<WhatsappIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#notification-preferences"}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </section>
                <ArticleModal
                    articleModalFlag={this.state.articleModalFlag}
                    changeArticleModalFlag={this.changeArticleModalFlag}
                    image={this.state.image}
                    imageClass={this.state.imageClass}
                />
            </Container>
        );
    }
}

NotificationPreferenceArt.propTypes = {
    onClickBlogSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    changeArticleNavModalFlag: PropTypes.func.isRequired
};

export default (NotificationPreferenceArt);