import axios from "axios";
import * as actionTypes from "./actionTypes";

export const changeCurrentPage = (currentPage) => {
    return {
        type: actionTypes.CHANGE_CURRENTPAGE,
        payload: {
            currentPage: currentPage
        }
    };
};

export const changeHashId = (hashId) => {
    return {
        type: actionTypes.CHANGE_HASHID,
        payload: {
            hashId: hashId
        }
    };
};

export const createProspect = (name, mail, phone, comment, source, productId, cookieUp) => {

    let params = {
        'UserProspectName': name,
        'UserProspectMail': mail,
        'UserProspectPhone': phone,
        'Comment': comment,
        'ProductId': productId,
        'Source': source,
        'CookieUp': cookieUp
    };

    return {
        type: actionTypes.FETCH_CREATEPROSPECT,
        payload: axios.post("api/crm/createprospect", params)
    }
};

export const changeCreateProspectStates = () => {
    return {
        type: actionTypes.CHANGE_CREATEPROSPECT_STATES
    };
};

export const getUrls = () => {
    return {
        type: actionTypes.FETCH_GETWEBTENDERSURLS,
        payload: axios.get("api/generaldata/getwebtendersurls")
    }
};

export const changeCurrentSection = (currentSection) => {
    return {
        type: actionTypes.CHANGE_CURRENTSECTION,
        payload: {
            currentSection: currentSection
        }
    };
};

export const acceptCookies = () => {
    return {
        type: actionTypes.ACCEPT_COOKIES
    };
};

export const getLiveReportHeaderSection = () => {
    return {
        type: actionTypes.FETCH_GET_HEADER_SECTION,
        payload: axios.get("api/livereport/getheadersection")
    }
};

export const getLiveReportServiceStatusSection = () => {
    return {
        type: actionTypes.FETCH_GET_SERVICE_STATUS_SECTION,
        payload: axios.get("api/livereport/getservicestatussection")
    }
};