import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../actions/generalActions";
import {
    sendSurvey,
    changeSendSurveyStates
} from "../actions/userActions";
import Spinner from './Spinner';
import Footer from './Footer';
import { Snackbar } from '@material/react-snackbar';

class SatisfactionSurvey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            performanceSatisfaction: '',
            issueOption: 1,
            issueDescription: '',
            frecuency: '',
            recommendation: '',
            improvement: '',
            nullInputs: false,
            errorToast: false
        }

        this.changeSection = this.changeSection.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onClickSend = this.onClickSend.bind(this);
        this.closeToast = this.closeToast.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Satisfaction-Survey");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Satisfaction-Survey", page: '/Satisfaction-Survey' });
    }

    onChangeInput(e, state){
        if(state == "PerformanceSatisfaction")
            this.setState({ performanceSatisfaction: e.target.value });
        else
        if(state == "IssueDescription")
            this.setState({ issueDescription: e.target.value });
        else
        if(state == "Frecuency")
            this.setState({ frecuency: e.target.value });
        else
        if(state == "Recommendation")
            this.setState({ recommendation: e.target.value });
        else
            this.setState({ improvement: e.target.value });
    }

    onChangeCheckbox(option){
        this.setState({ issueOption: this.state.issueOption == option ? this.state.issueOption : option });
    }

    onClickSend(){
        if(this.state.performanceSatisfaction == '' || (this.state.issueOption == 0 && this.state.issueDescription == '') || this.state.frecuency == '' 
            || this.state.recommendation == '' || this.state.improvement == '')
            this.setState({ nullInputs: true, errorToast: true });
        else
            this.props.sendSurvey(
            this.state.performanceSatisfaction, 
            this.state.issueDescription, 
            this.state.frecuency, 
            this.state.recommendation, this.state.improvement
        );
    }

    closeToast(){
        this.setState({ errorToast: false });
    }

    render() {
        if (this.props.fetchingSendSatisfactionSurvey == false)
            return (
                <div className='satisfaction-survey-div'>
                    <section class="art-section" onMouseOver={this.props.currentSection != "Satisfaction-Survey" ? this.changeSection : () => { }}>
                        <Container className='art-container'>
                            <Row className="title-row">
                                <div md="auto" className='title-col'>
                                    <ul class='navigation'>
                                        <li>
                                            <a href={window.location.origin + "/"}>Home</a>
                                        </li>
                                        <li>
                                            Satisfaction survey
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                            <Row className="title-row">
                                <div md="auto">
                                    <hr class="line-title"></hr>
                                    <h1 className="title">Satisfaction survey</h1>
                                </div>
                                <div className="second-title-col" md="auto">
                                    <p className='published-day'>Updated on Jan 10 2023 10:00 AM</p>
                                </div>
                            </Row>
                            <Row className="body-row">
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="art-subtitle">How satisfied are you with the overall performance of Tender Notify?</h2>
                                    <textarea
                                        class={"input" + (this.state.performanceSatisfaction == '' && this.state.nullInputs ? ' error' : '')}
                                        placeholder="Answer"
                                        rows="4"
                                        value={this.state.performanceSatisfaction}
                                        onChange={(e) => this.onChangeInput(e, "PerformanceSatisfaction")}
                                    />
                                </Col>
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="art-subtitle">Have you experienced any issues while using Tender Notify?</h2>
                                    <div class="option">
                                        <input
                                            class="check-input"
                                            type="checkbox"
                                            id="procurement"
                                            value="procurement"
                                            checked={this.state.issueOption == 0 ? true : false}
                                            onChange={() => this.onChangeCheckbox(0)}
                                        />
                                        <label class="label" for="procurement">Yes</label>
                                    </div>
                                    <div class="option last-option">
                                        <input
                                            class="check-input"
                                            type="checkbox"
                                            id="procurement"
                                            value="procurement"
                                            checked={this.state.issueOption == 1 ? true : false}
                                            onChange={() => this.onChangeCheckbox(1)}
                                        />
                                        <label class="label" for="procurement">No</label>
                                    </div>

                                    <h2 className="art-subtitle">If so, please describe.</h2>
                                    <textarea
                                        class={"input" + (this.state.issueOption == 1 ? " disabled" : "") + (this.state.issueDescription == '' && this.state.issueOption == 0 && this.state.nullInputs ? ' error' : '')}
                                        placeholder="Answer"
                                        rows="4"
                                        disabled={this.state.issueOption == 1 ? true : false}
                                        value={this.state.issueDescription}
                                        onChange={(e) => this.onChangeInput(e, "IssueDescription")}
                                    />
                                </Col>
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="art-subtitle">How often do you use Tender Notify?</h2>
                                    <textarea
                                        class={"input" + (this.state.frecuency == '' && this.state.nullInputs ? ' error' : '')}
                                        placeholder="Answer"
                                        rows="4"
                                        value={this.state.frecuency}
                                        onChange={(e) => this.onChangeInput(e, "Frecuency")}
                                    />
                                </Col>
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="art-subtitle">How likely are you to recommend Tender Notify to a colleague or friend?</h2>
                                    <textarea
                                        class={"input" + (this.state.recommendation == '' && this.state.nullInputs ? ' error' : '')}
                                        placeholder="Answer"
                                        rows="4"
                                        value={this.state.recommendation}
                                        onChange={(e) => this.onChangeInput(e, "Recommendation")}
                                    />
                                </Col>
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="art-subtitle">Is there anything specific you would like to see added or improved in Tender Notify?</h2>
                                    <textarea
                                        class={"input" + (this.state.improvement == '' && this.state.nullInputs ? ' error' : '')}
                                        placeholder="Answer"
                                        rows="4"
                                        value={this.state.improvement}
                                        onChange={(e) => this.onChangeInput(e, "Improvement")}
                                    />
                                </Col>
                                <Col className="column-paragraph button" md="auto">
                                    <Button className="buttonPrimary" onClick={() => this.onClickSend()}>SEND SURVEY</Button>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <div className='footerAbsolut'>
                        <Footer
                            currentSection={this.props.currentSection}
                            changeCurrentSection={this.props.changeCurrentSection}
                            cookieUp={this.props.cookieUp}
                        />
                    </div>
                    {
                        this.state.errorToast
                            ?
                            <Snackbar
                                className="errorToast"
                                message="Please complete all details."
                                actionText="X"
                                onClose={this.closeToast}
                            />
                            : null
                    }
                    {
                        this.props.sendSatisfactionSurveyError !== null || this.props.fetchedSendSatisfactionSurvey
                            ?
                            <Snackbar
                                className={this.props.fetchedSendSatisfactionSurvey ? "toastSuccess" : "errorToast"}
                                message=
                                {
                                    this.props.fetchedSendSatisfactionSurvey
                                        ? "Your survey was successfully sent."
                                        : "An error has occurred. Try again."
                                }
                                actionText="X"
                                onClose=
                                {
                                    this.props.fetchedSendSatisfactionSurvey
                                        ? () => this.props.changeSendSurveyStates()
                                        : () => { }
                                }
                            />
                            : null
                    }
                </div>
            );
        else
            return (<Spinner myClass="containerSpinnerGeneral" />);
    }
}

const mapStateToProps = (value) => {
    return {
        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp,

        fetchingSendSatisfactionSurvey: value.user.fetchingSendSatisfactionSurvey,
        fetchedSendSatisfactionSurvey: value.user.fetchedSendSatisfactionSurvey,
        sendSatisfactionSurveyError: value.user.sendSatisfactionSurveyError
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection)),
        sendSurvey: (performanceSatisfaction, issueDescription, frecuency, recommendation, improvement) => dispatch(sendSurvey(performanceSatisfaction, issueDescription, frecuency, recommendation, improvement)),
        changeSendSurveyStates: () => dispatch(changeSendSurveyStates()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SatisfactionSurvey);