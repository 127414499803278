import React, { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

class Spinner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seconds: 0
        }

        this.onStart = this.onStart.bind(this);
        this.timer = this.timer.bind(this);
    }

    onStart = () => {
        this.setState({ seconds: this.state.seconds + 0.5 });
    }
    timer = () => {
        this.f = setInterval(this.onStart, 750);
    }

    render() {
        if (this.props.place != undefined && (this.props.place === "signup" || this.props.place === "creating-payment-link") && this.state.seconds == 0)
            this.timer();

        return (
            <div className={this.props.myClass}>
                <Dimmer active>
                    <Loader>
                        {
                            this.props.place == undefined
                                ? "Loading"
                                : this.props.place === "signup"
                                    ? this.state.seconds < 1
                                        ? "Loading"
                                        : this.state.seconds >= 1 && this.state.seconds <= 2
                                            ? "Creating profile"
                                            : this.state.seconds >= 2 && this.state.seconds <= 3
                                                ? "Configuring account"
                                                : "Creating user"
                                    : this.props.place === "creating-payment-link"
                                        ? this.state.seconds < 1
                                            ? "Loading"
                                            : "Creating payment link"
                                        : "Loading"
                        }
                    </Loader>
                </Dimmer>
            </div>
        );
    }
}

export default Spinner;