import React, { Component } from 'react';
import ReactGA4 from 'react-ga4';
import { connect } from "react-redux";
import {
    changeCurrentSection,
} from "../../actions/generalActions";
import { Link } from 'react-router-dom';

class ButtonsSign extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection(section) {
        this.props.changeCurrentSection(section);
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: section, page: '/' + section });
    }

    render() {
        return (
            <div className='sign-buttons'>
                <Link to={"/tender-notify-beta-closed"}>
                    <a 
                        class="main-btn buttonSignUp" 
                        data-scroll-nav="0" 
                        rel="nofollow" 
                        onClick={()=>this.changeSection("Beta-Screen")}
                    >
                        SIGN IN
                    </a>
                </Link>
            </div>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsSign);