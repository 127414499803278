import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';
import PlusPlan from './Plans/PlusPlan';
import Free from './Plans/Free';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';
import { connect } from "react-redux";
import {
    goToApp
} from "../actions/securityActions";


class Pricing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changeToggle: false
        }

        this.changeSection = this.changeSection.bind(this);
        this.changeMonthly = this.changeMonthly.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Pricing");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: "Pricing", page: '/Pricing' });
    }

    changeMonthly(){
        let monthly = window.sessionStorage.getItem("monthly");

        if(monthly != null){
            if(monthly == "false")
                window.sessionStorage.setItem("monthly", "true");
            else
                window.sessionStorage.setItem("monthly", "false");
        }
        else
            window.sessionStorage.setItem("monthly", "false");

        this.setState({ changeToggle: !this.state.changeToggle });
    }

    checkToggle(){
        let monthly = window.sessionStorage.getItem("monthly");

        if(monthly != null){
            if(monthly == "false")
                return true;
            else
                return false;
        }
        else
            return false;
    }

    render() {
        return (
            <div onMouseOver={this.props.currentSection != "Pricing" ? this.changeSection : () => { }}>
                <section id="pricing" class="about-area pt-120 pb-100">
                    <div class="container pricing">
                        <div class="general-row">
                            <h3 class="title">Choose your pricing plan</h3>
                        </div>
                        
                        <div className='switch-row'>
                            <div className='switch-div'>
                                <p className='text'>Monthly</p>
                                <Checkbox toggle className='switch' checked={this.checkToggle()} onClick={this.changeMonthly}/>
                                <p className='text'>Yearly</p>
                                <label className='label'>-20% off</label>
                            </div>
                        </div>
                        
                        <div class="pricing-row">
                            <div class="plan-container plan">
                                {
                                    this.props.userData != null 
                                    ?
                                        <div
                                            class=
                                            {
                                                "containerPlan free" + 
                                                    (
                                                        this.props.userData.membershipDTO != null && this.props.userData.membershipDTO.id == 1
                                                            ? this.props.userData.membershipDTO.expired
                                                                ? " containerExpiredPlanSelected"
                                                                : " containerPlanSelected"
                                                            : ""
                                                    )
                                            }
                                        >
                                            <Free membership={this.props.userData.membershipDTO}/>
                                        </div>
                                    :
                                        <div class="containerPlan free">
                                            <Free membership={null}/>
                                        </div>
                                }
                            </div>
                            <div class="plan-container plan">
                                {
                                    this.props.userData != null 
                                    ?
                                        <div
                                            class=
                                            {
                                                "containerPlan pro" + 
                                                (
                                                    this.props.userData.membershipDTO != null && this.props.userData.membershipDTO.id == 2
                                                        ? this.props.userData.membershipDTO.expired
                                                            ? " containerExpiredPlanSelected"
                                                            : " containerPlanSelected"
                                                        : ""
                                                )
                                            }
                                        >
                                            <PlusPlan membership={this.props.userData.membershipDTO}/>
                                        </div>
                                    :
                                        <div class="containerPlan pro">
                                            <PlusPlan membership={null}/>
                                        </div>

                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

Pricing.propTypes = {
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired
};

const mapStateToProps = (value) => {
    return {
        userData: value.security.userData
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        goToApp: () => dispatch(goToApp())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);