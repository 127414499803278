import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import dashboard1 from '../../images/dashboard-article/dashboard-1.png';
import dashboardGif from '../../images/dashboard-article/dashboard.gif';
import ReactGA4 from 'react-ga4';
import {
    EmailShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailIcon,
    TelegramIcon,
    WhatsappIcon
} from "react-share";
import ArticleModal from '../Modals/ArticleModal';
import PropTypes from 'prop-types';
import magnifyingGlassPrimaryColor from '../../images/magnifying-glass-primary-color.svg';

class DashboardArt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articleModalFlag: false,
            image: null,
            imageClass: ""
        }

        this.changeSection = this.changeSection.bind(this);
        this.changeArticleModalFlag = this.changeArticleModalFlag.bind(this);
    }

    changeArticleModalFlag(image, imageClass){
        this.setState({ 
            articleModalFlag: !this.state.articleModalFlag, 
            image: image, 
            imageClass: imageClass
        });
    }

    changeSection() {
        this.props.changeCurrentSection("Blog-Reporting-And-Dashboard-Article");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'Blog-Reporting-And-Dashboard-Article', page: '/Blog-Reporting-And-Dashboard-Article' });
    }

    render() {
        return (
            <Container className="art-container article" onMouseOver={this.props.currentSection != "Blog-Reporting-And-Dashboard-Article" ? this.changeSection : () => { }}>
                <section class="art-section articles">
                    <div>
                        <Row className="title-row navigation">
                            <div md="auto" className='title-col'>
                                <ul class='navigation'>
                                    <li>
                                        <a href={window.location.origin + "/"}>Home</a>
                                    </li>
                                    <li>
                                        <a href={window.location.origin + "/blog"}>Tender Notify Blog</a>
                                    </li>
                                    <li>
                                        Share, measure and grow
                                    </li>
                                </ul>
                            </div>
                            <img src={magnifyingGlassPrimaryColor} onClick={this.props.changeArticleNavModalFlag}/>
                        </Row>
                        <Row className="title-row">
                            <div md="auto">
                                <hr class="line-title"></hr>
                                <h1 className="title-art">Reporting and Dashboard</h1>
                                <p className='text-below-title'>Share, measure and grow</p>
                            </div>
                            <div className="second-title-col" md="auto">
                                <p className='published-day'>Updated on Jan 03 2023 10:00 AM • 3 minutes to read</p>
                            </div>
                        </Row>
                        <Row className="body-row">
                            <Col className="column-paragraph" md="auto">
                                <p className='art-paragraph'>
                                    Tender Notify joins in the era where everything is shared, giving users the ability to share the information you monitor over the dashboard panel and reports so that you can make increasingly strategic decisions for your business.
                                </p>
                            </Col>
                            <Col className="column-paragraph with-image" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="first-column" md="auto">
                                        <p className='art-paragraph'>
                                            It will also allow you to share or download anything from tracked or not-tracked tenders data to even notifications information, so that you can continue to work on them offline.
                                        </p>
                                    </Col>
                                    <Col className="second-column" md="auto">
                                        <img class="image" src={dashboard1} onClick={() =>this.changeArticleModalFlag(dashboard1, "horizontal")}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="column-paragraph" md="auto">
                                <p className='art-paragraph'>
                                    The supported download format is .csv and the channels to share these reports can be via <span class='text'>Mail</span>, <span class='text'>Telegram</span> and <span class='text'>WhatsApp</span>.
                                </p>
                                <p className='art-paragraph'>
                                    On the other hand, from the <span class='text'>Dashboard Tab</span> it will be possible to observe general indicators based on all tenders published on the e-tender platform. In addition to the general indicators, users can also customise the dashboard to display only the information that is relevant to them. This can be done by selecting specific parameters and filters to display the data in a more meaningful ways. 
                                </p>
                            </Col>
                            <Col className="column-paragraph with-image" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="first-column with-out-margin-top" md="auto">
                                        <p className='art-paragraph'>
                                            <span class='text'>Tenders over time:</span> Shows the amount of tenders according to the parameters per year and status. On the other hand, you can also visualize the number of expired, awarded or published tenders per year according to: CVP, Procedures and Authorities.
                                        </p>
                                        <p className='art-paragraph'>
                                            The information in these Charts, as well as in all the information displayed, contains real-time data, which makes them key statistics for decision making in each business.
                                        </p>
                                    </Col>
                                    <Col className="second-column" md="auto">
                                        <img class="image" src={dashboardGif} onClick={() =>this.changeArticleModalFlag(dashboardGif, "horizontal")}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="column-paragraph before-keywords" md="auto">
                                <p className='art-paragraph-below'>
                                    Would you like these dashboards to be customized according to the information that is only of interest to you?
                                </p>
                            </Col>
                            <Col className="column-paragraph" md="auto">
                                <h2 className="art-subtitle">Keywords for this article</h2>
                                <p className='art-paragraph-below'>
                                    dashboard, reporting, download, formats, channels, sharing, strategic decisions.
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Container className='share-and-more-arts-container'>
                        <Container className='more-arts-container'>
                            <Row className="body-row">
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="more-arts-title">These articles may also interest you</h2>
                                    <ul className='list-margin'>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("track-tenders")}>
                                            Track Tenders so you don't miss anything
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("smart-filters")}>
                                            How to boost your search using Smart Filters
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("notification-preferences")}>
                                            Notification Preferences: your smart ally
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("cpvs")}>
                                            Why is it important to know the potential of the CPV?
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='share-container'>
                            <Row className="body-row">
                                <Col md="auto">
                                    <h2 className="share-title">{"Share"}</h2>
                                </Col>
                                <Col className="column-share-buttons" md="auto">
                                    <TelegramShareButton
                                        children={<TelegramIcon size={40} round={false} />}
                                        url={window.location.origin + "/blog#reporting-and-dashboard"}
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <EmailShareButton
                                        children={<EmailIcon size={40} round={false} />}
                                        url={window.location.origin + "/blog#reporting-and-dashboard"}
                                        subject={"Share, measure and grow"}
                                        body={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <WhatsappShareButton
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                        children={<WhatsappIcon size={40} round={false} />}
                                        url={window.location.origin + "/blog#reporting-and-dashboard"}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </section>
                <ArticleModal
                    articleModalFlag={this.state.articleModalFlag}
                    changeArticleModalFlag={this.changeArticleModalFlag}
                    image={this.state.image}
                    imageClass={this.state.imageClass}
                />
            </Container>
        );
    }
}

DashboardArt.propTypes = {
    onClickBlogSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    changeArticleNavModalFlag: PropTypes.func.isRequired
};

export default (DashboardArt);