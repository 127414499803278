import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Icon } from "semantic-ui-react";
import {
    EmailShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailIcon,
    TelegramIcon,
    WhatsappIcon
} from "react-share";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import irelandFlag from '../../images/ireland-flag.png';
import estoniaFlag from '../../images/estonia-flag.png';
import slovakiaFlag from '../../images/slovakia-flag.png';
import PropTypes from 'prop-types';
import ReactGA4 from 'react-ga4';
import magnifyingGlassPrimaryColor from '../../images/magnifying-glass-primary-color.svg';

class ReleaseNotesArt extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Blog-Release-Notes-Article");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'Blog-Release-Notes-Article', page: '/Blog-Release-Notes-Article' });
    }

    render() {
        return (
            <Container className="art-container article" onMouseOver={this.props.currentSection != "Blog-Release-Notes-Article" ? this.changeSection : () => { }}>
                <section class="art-section articles">
                    <div>
                        <Row className="title-row navigation">
                            <div md="auto" className='title-col'>
                                <ul class='navigation'>
                                    <li>
                                        <a href={window.location.origin + "/"}>Home</a>
                                    </li>
                                    <li>
                                        <a href={window.location.origin + "/blog"}>Tender Notify Blog</a>
                                    </li>
                                    <li>
                                        Release Notes
                                    </li>
                                </ul>
                            </div>
                            <img src={magnifyingGlassPrimaryColor} onClick={this.props.changeArticleNavModalFlag}/>
                        </Row>
                        <Row className="title-row">
                            <div md="auto">
                                <hr class="line-title"></hr>
                                <h1 className="title-art">Release Notes</h1>
                                <p className='text-below-title'>Explore the ever-evolving world of Tender Notify</p>
                            </div>
                            <div className="second-title-col" md="auto">
                                <p className='published-day'>Updated on Mar 25 2024 10:00 AM • 4 minutes to read</p>
                            </div>
                        </Row>
                        <Row className="body-row">
                            <Col className="column-paragraph" md="auto">
                                <p className='art-paragraph'>
                                    In this evolving space, we continuously strive to enhance our product, making it easier for businesses and contractors to discover and engage with relevant tenders. Here, we bring you a centralized source for all the latest releases, updates, and announcements on  Tender Notify Platform.
                                </p>
                                <p className='art-paragraph'>
                                    Our mission is to empower users with the best information, tools, and resources to help them stay ahead in the competitive world of tenders and procurement. This Release Notes article is designed to keep you informed on the most recent improvements, feature additions, bug fixes, and news related to our innovative Tender Notify product.
                                </p>
                                <p className='art-paragraph'>
                                    As we embark on this journey together, we invite you to explore, engage, and discover the ever-evolving world of Tender Notify. Happy bidding!
                                </p>
                            </Col>
                            <VerticalTimeline>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="22 Feb 2024"
                                    icon={<Icon name='arrow circle up' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">Estonian Data Source</h3>
                                    <h4 className="vertical-timeline-element-subtitle">DATA SOURCE UPDATES</h4>
                                    <p className='paragraph'>
                                        Announcing that we've incorporated Documents and Tender Notices from the <span className='bold'>Estonian data source</span>
                                    </p>
                                    <ul>
                                        <li className='firstone'>
                                            Source: <a href='https://www.fin.ee/en' target='_blank'>Public Procurement Register</a>
                                        </li>
                                        <li>
                                            {"Region: Estonia "}<img class="flag" src={estoniaFlag} />
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="17 Jan 2024"
                                    icon={<Icon name='arrow circle up' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">Ireland Data Source</h3>
                                    <h4 className="vertical-timeline-element-subtitle">DATA SOURCE UPDATES</h4>
                                    <p className='paragraph'>
                                        Announcing that we've incorporated Documents and Tender Notices from the <span className='bold'>Ireland data source</span>
                                    </p>
                                    <ul>
                                        <li className='firstone'>
                                            Source: <a href='https://www.etenders.gov.ie' target='_blank'>eTenders</a>
                                        </li>
                                        <li>
                                            {"Region: Ireland "}<img class="flag" src={irelandFlag} />
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="17 Dec 2023"
                                    icon={<Icon name='arrow circle up' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">New Plan Available</h3>
                                    <h4 className="vertical-timeline-element-subtitle">PRO Plan is now available</h4>
                                    <p className='paragraph'>
                                        Preparing for next-gen enhancements with AI-driven insights and predictive analytics for <span className='high-light'>Tender Notify</span>
                                    </p>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="24 Jul 2023"
                                    icon={<Icon name='arrow circle up' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">New Onboarding</h3>
                                    <h4 className="vertical-timeline-element-subtitle">UPGRADE</h4>
                                    <p className='paragraph'>
                                        New release of some exciting new features for <span className='high-light'>Tender Notify</span>
                                    </p>
                                    <ul className='circle'>
                                        <li>
                                            New onboarding process introduced for a smooth user experience
                                        </li>
                                        <li>
                                            It enhances the platform's usability, ensuring users get the most relevant and timely updates tailored to their needs
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="11 Jul 2023"
                                    icon={<Icon name='arrow circle up' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">New Features</h3>
                                    <h4 className="vertical-timeline-element-subtitle">UPGRADE</h4>
                                    <p className='paragraph'>
                                        New release of some exciting new features for <span className='high-light'>Tender Notify</span>
                                    </p>
                                    <ul className='circle'>
                                        <li>
                                            Introducing Single Sign-On (SSO) for Microsoft Accounts. Securely log in with just a single click
                                        </li>
                                        <li>
                                            AI Capabilities: New Summarization available
                                        </li>
                                        <li>
                                            Plus, we've squashed some bugs to ensure an even smoother, seamless experience
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="25 May 2023"
                                    icon={<Icon name='arrow circle up' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">New Features</h3>
                                    <h4 className="vertical-timeline-element-subtitle">UPGRADE</h4>
                                    <p className='paragraph'>
                                        New release of some exciting new features for <span className='high-light'>Tender Notify</span>
                                    </p>
                                    <ul className='circle'>
                                        <li>
                                            AI Capabilities
                                        </li>
                                        <li>
                                            New Language Added: Estonian
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="15 Apr 2023"
                                    icon={<Icon name='arrow circle up' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">New Features</h3>
                                    <h4 className="vertical-timeline-element-subtitle">UPGRADE</h4>
                                    <p className='paragraph'>
                                        New release of some exciting new features for <span className='high-light'>Tender Notify</span>
                                    </p>
                                    <ul className='circle'>
                                        <li>
                                            <a href="/api-reference">API Connectivity</a>
                                        </li>
                                        <li>
                                            New Language Added: Spanish
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="30 Mar 2023"
                                    icon={<Icon className='file' name='file alternate' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">Estonian Data Source</h3>
                                    <h4 className="vertical-timeline-element-subtitle">NEW DATA SOURCE ADDED</h4>
                                    <p className='paragraph'>
                                        Announcing that we've incorporated a new <span className='bold'>data source</span>
                                    </p>
                                    <ul>
                                        <li className='firstone'>
                                            Source: <a href='https://www.fin.ee/en' target='_blank'>Public Procurement Register</a>
                                        </li>
                                        <li>
                                            {"Region: Estonia "}<img class="flag" src={estoniaFlag} />
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="28 Feb 2023"
                                    icon={<Icon name='arrow circle up' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">New Features</h3>
                                    <h4 className="vertical-timeline-element-subtitle">UPGRADE</h4>
                                    <p className='paragraph'>
                                        New release of some exciting new features for <span className='high-light'>Tender Notify</span>
                                    </p>
                                    <ul className='circle'>
                                        <li>
                                            Dark Mode and UX Enhancements
                                        </li>
                                        <li>
                                            Response Time performance improved
                                        </li>
                                        <li>
                                            Changes on Data Model (Standard)
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="30 Jan 2023"
                                    icon={<Icon className='file' name='file alternate' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">Slovak Data Source</h3>
                                    <h4 className="vertical-timeline-element-subtitle">NEW DATA SOURCE ADDED</h4>
                                    <p className='paragraph'>
                                        Announcing that we've incorporated a new <span className='bold'>data source</span>
                                    </p>
                                    <ul>
                                        <li className='firstone'>
                                            Source: <a href='https://www.uvo.gov.sk/' target='_blank'>UVO</a>
                                        </li>
                                        <li>
                                            {"Region: Slovakia "}<img class="flag" src={slovakiaFlag} />
                                        </li>

                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="30 Dic 2022"
                                    icon={<Icon name='arrow circle up' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">Public Access</h3>
                                    <h4 className="vertical-timeline-element-subtitle">UPGRADE</h4>
                                    <p className='paragraph'>
                                        New release of some exciting new features for <span className='high-light'>Tender Notify</span>
                                    </p>
                                    <ul className='circle'>
                                        <li>
                                            Public Access
                                        </li>
                                        <li>
                                            Reports & Dashboard improvements
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                                <VerticalTimelineElement
                                    className="vertical-timeline-element art"
                                    date="30 Nov 2022"
                                    icon={<Icon className='file' name='file alternate' size='huge' />}
                                >
                                    <h3 className="vertical-timeline-element-title">Ireland Data Source</h3>
                                    <h4 className="vertical-timeline-element-subtitle">NEW DATA SOURCE ADDED</h4>
                                    <p className='paragraph'>
                                        Announcing that we've incorporated a new <span className='bold'>data source</span>
                                    </p>
                                    <ul>
                                        <li className='firstone'>
                                            Source: <a href='https://www.etenders.gov.ie' target='_blank'>eTenders</a>
                                        </li>
                                        <li>
                                            {"Region: Ireland "}<img class="flag" src={irelandFlag} />
                                        </li>
                                    </ul>
                                </VerticalTimelineElement>
                            </VerticalTimeline>
                        </Row>
                    </div>
                    <Container className='share-and-more-arts-container'>
                        <Container className='more-arts-container'>
                            <Row className="body-row">
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="more-arts-title">{"These articles may also interest you"}</h2>
                                    <ul className='list-margin'>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("track-tenders")}>
                                            Track Tenders so you don't miss anything
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("smart-filters")}>
                                            How to boost your search using Smart Filters
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("notification-preferences")}>
                                            Notification Preferences: your smart ally
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("reporting-and-dashboard")}>
                                            Share, measure and grow
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='share-container'>
                            <Row className="body-row">
                                <Col md="auto">
                                    <h2 className="share-title">{"Share"}</h2>
                                </Col>
                                <Col className="column-share-buttons" md="auto">
                                    <TelegramShareButton
                                        children={<TelegramIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#release-notes"}
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <EmailShareButton
                                        children={<EmailIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#release-notes"}
                                        subject={"Why is it important to know the potential of the CPV?"}
                                        body={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <WhatsappShareButton
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                        children={<WhatsappIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#release-notes"}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </section>
            </Container>
        );
    }
}

ReleaseNotesArt.propTypes = {
    onClickBlogSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    changeArticleNavModalFlag: PropTypes.func.isRequired
};

export default (ReleaseNotesArt);