import { applyMiddleware, createStore} from 'redux';
import rootReducer from './reducers/rootReducer';
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

const middleware = [
    promise,
    thunk
];

const store = createStore(rootReducer, applyMiddleware(...middleware) );

export default store;