import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from "react-redux";
import {
    changeCurrentSection,
} from "../../actions/generalActions";
import ReleaseNotesArt from './ReleaseNotesArt';
import CpvArt from './CpvArt';
import DashboardArt from './DashboardArt';
import SmartFilterArt from './SmartFilterArt';
import TrackTendersArt from './TrackTendersArt';
import NotificationPreferenceArt from './NotificationPreferenceArt';
import ArticleNavModal from '../Modals/ArticlesNavModal';
/* import ValuePropositionArt from './ValuePropositionArt'; */
import TranslateExtensionArt from './TranslateExtensionArt';

class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blogSection: "release-notes",
            articleNavModalFlag: false
        }

        this.onClickBlogSection = this.onClickBlogSection.bind(this);
        this.changeArticleNavModalFlag = this.changeArticleNavModalFlag.bind(this);
    }

    onClickBlogSection(blogSection) {
        this.setState({ blogSection: blogSection, articleNavModalFlag: false});
        let element = document.getElementById("blog-div");
        if (element != null)
            element.scrollIntoView();
    }

    componentDidMount() {
        let hash = window.location.hash;

        if (hash != "") {
            let hashes = hash.split("#");

            let hashToScroll = hashes.find(x => (x == "release-notes" || x == "cpvs" || x == "reporting-and-dashboard" || x == "notification-preferences" 
                || x == "track-tenders" || x == "smart-filters" || /* x == "getting-started" || */ x == "translate-extension"));

            if (hashToScroll != null) {
                this.onClickBlogSection(hashToScroll)
                window.location.hash = "";
            }
        }
    }

    componentDidUpdate() {
        let hash = window.location.hash;

        if (hash != "") {
            let hashes = hash.split("#");

            let hashToScroll = hashes.find(x => (x == "release-notes" || x == "cpvs" || x == "reporting-and-dashboard" || x == "notification-preferences" 
                || x == "track-tenders" || x == "smart-filters" || /* x == "getting-started" || */ x == "translate-extension"));

            if (hashToScroll != null) {
                this.onClickBlogSection(hashToScroll)
                window.location.hash = "";
            }
        }
    }

    changeArticleNavModalFlag(){
        this.setState({articleNavModalFlag: !this.state.articleNavModalFlag});
    }

    render() {
        return (
            <div className='blog-div' id="blog-div">
                <section class="art-section api-reference">
                    <Container className='api-reference-body'>
                        <Container className='api-reference-nav articles'>
                            <h1>BLOG</h1>
                            {/* <h2
                                className={"nav-item" + (this.state.blogSection == "getting-started" ? " active" : "")}
                                onClick={() => this.onClickBlogSection("getting-started")}
                            >
                                Getting Started
                            </h2> */}
                            <h2
                                className={"nav-item" + (this.state.blogSection == "release-notes" ? " active" : "")}
                                onClick={() => this.onClickBlogSection("release-notes")}
                            >
                                Release Notes
                            </h2>
                            <h2
                                className={"nav-item" + (this.state.blogSection == "cpvs" ? " active" : "")}
                                onClick={() => this.onClickBlogSection("cpvs")}
                            >
                                CPV
                            </h2>
                            <h2
                                className={"nav-item" + (this.state.blogSection == "reporting-and-dashboard" ? " active" : "")}
                                onClick={() => this.onClickBlogSection("reporting-and-dashboard")}
                            >
                                Reporting and Dashboard
                            </h2>
                            <h2
                                className={"nav-item" + (this.state.blogSection == "notification-preferences" ? " active" : "")}
                                onClick={() => this.onClickBlogSection("notification-preferences")}
                            >
                                Notification Preferences
                            </h2>
                            <h2
                                className={"nav-item" + (this.state.blogSection == "track-tenders" ? " active" : "")}
                                onClick={() => this.onClickBlogSection("track-tenders")}
                            >
                                Track Tenders
                            </h2>
                            <h2
                                className={"nav-item" + (this.state.blogSection == "smart-filters" ? " active" : "")}
                                onClick={() => this.onClickBlogSection("smart-filters")}
                            >
                                Smart Filters
                            </h2>
                            <h2
                                className={"nav-item" + (this.state.blogSection == "translate-extension" ? " active" : "")}
                                onClick={() => this.onClickBlogSection("translate-extension")}
                            >
                                Translate Extension
                            </h2>
                        </Container>
                        {/* { 
                            this.state.blogSection == "getting-started" 
                                ? 
                                    <ValuePropositionArt 
                                        onClickBlogSection={this.onClickBlogSection} 
                                        cookieUp={this.props.cookieUp} 
                                        currentSection={this.props.currentSection} 
                                        changeCurrentSection={this.props.changeCurrentSection}
                                        changeArticleNavModalFlag={this.changeArticleNavModalFlag}
                                    /> 
                                : null 
                        } */}
                        { 
                            this.state.blogSection == "release-notes" 
                                ? 
                                    <ReleaseNotesArt 
                                        onClickBlogSection={this.onClickBlogSection} 
                                        cookieUp={this.props.cookieUp} 
                                        currentSection={this.props.currentSection} 
                                        changeCurrentSection={this.props.changeCurrentSection}
                                        changeArticleNavModalFlag={this.changeArticleNavModalFlag}
                                    /> 
                                : null 
                        }
                        { 
                            this.state.blogSection == "cpvs" 
                                ? 
                                    <CpvArt
                                        onClickBlogSection={this.onClickBlogSection} 
                                        cookieUp={this.props.cookieUp} 
                                        currentSection={this.props.currentSection} 
                                        changeCurrentSection={this.props.changeCurrentSection}
                                        changeArticleNavModalFlag={this.changeArticleNavModalFlag}
                                    /> 
                                : null 
                        }
                        { 
                            this.state.blogSection == "reporting-and-dashboard" 
                                ? 
                                    <DashboardArt
                                        onClickBlogSection={this.onClickBlogSection} 
                                        cookieUp={this.props.cookieUp} 
                                        currentSection={this.props.currentSection} 
                                        changeCurrentSection={this.props.changeCurrentSection}
                                        changeArticleNavModalFlag={this.changeArticleNavModalFlag}
                                    /> 
                                : null 
                        }
                        { 
                            this.state.blogSection == "notification-preferences" 
                                ? 
                                    <NotificationPreferenceArt
                                        onClickBlogSection={this.onClickBlogSection} 
                                        cookieUp={this.props.cookieUp} 
                                        currentSection={this.props.currentSection} 
                                        changeCurrentSection={this.props.changeCurrentSection}
                                        changeArticleNavModalFlag={this.changeArticleNavModalFlag}
                                    /> 
                                : null 
                        }
                        { 
                            this.state.blogSection == "track-tenders" 
                                ? 
                                    <TrackTendersArt
                                        onClickBlogSection={this.onClickBlogSection} 
                                        cookieUp={this.props.cookieUp} 
                                        currentSection={this.props.currentSection} 
                                        changeCurrentSection={this.props.changeCurrentSection}
                                        changeArticleNavModalFlag={this.changeArticleNavModalFlag}
                                    /> 
                                : null 
                        }
                        { 
                            this.state.blogSection == "smart-filters" 
                                ? 
                                    <SmartFilterArt
                                        onClickBlogSection={this.onClickBlogSection} 
                                        cookieUp={this.props.cookieUp} 
                                        currentSection={this.props.currentSection} 
                                        changeCurrentSection={this.props.changeCurrentSection}
                                        changeArticleNavModalFlag={this.changeArticleNavModalFlag}
                                    /> 
                                : null 
                        }
                        { 
                            this.state.blogSection == "translate-extension" 
                                ? 
                                    <TranslateExtensionArt
                                        onClickBlogSection={this.onClickBlogSection} 
                                        cookieUp={this.props.cookieUp} 
                                        currentSection={this.props.currentSection} 
                                        changeCurrentSection={this.props.changeCurrentSection}
                                        changeArticleNavModalFlag={this.changeArticleNavModalFlag}
                                    /> 
                                : null 
                        }
                    </Container>
                </section>
                <ArticleNavModal 
                    articleNavModalFlag={this.state.articleNavModalFlag} 
                    changeArticleNavModalFlag={this.changeArticleNavModalFlag}
                    onClickBlogSection={this.onClickBlogSection}
                    blogSection={this.state.blogSection}
                />
            </div>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);