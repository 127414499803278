import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import cpv1 from '../../images/cpv-article/cpv-1.png';
import ReactGA4 from 'react-ga4';
import {
    EmailShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailIcon,
    TelegramIcon,
    WhatsappIcon
} from "react-share";
import ArticleModal from '../Modals/ArticleModal';
import PropTypes from 'prop-types';
import magnifyingGlassPrimaryColor from '../../images/magnifying-glass-primary-color.svg';

class CpvArt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articleModalFlag: false,
            image: null,
            imageClass: ""
        }

        this.changeSection = this.changeSection.bind(this);
        this.changeArticleModalFlag = this.changeArticleModalFlag.bind(this);
    }

    changeArticleModalFlag(image, imageClass){
        this.setState({ 
            articleModalFlag: !this.state.articleModalFlag, 
            image: image, 
            imageClass: imageClass
        });
    }

    changeSection() {
        this.props.changeCurrentSection("Blog-CPV-Article");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'Blog-CPV-Article', page: '/Blog-CPV-Article' });
    }

    render() {
        return (
            <Container className="art-container article" onMouseOver={this.props.currentSection != "Blog-CPV-Article" ? this.changeSection : () => { }}>
                <section class="art-section articles">
                    <div>
                        <Row className="title-row navigation">
                            <div md="auto" className='title-col'>
                                <ul class='navigation'>
                                    <li>
                                        <a href={window.location.origin + "/"}>Home</a>
                                    </li>
                                    <li>
                                        <a href={window.location.origin + "/blog"}>Tender Notify Blog</a>
                                    </li>
                                    <li>
                                        Why is it important to know the potential of the CPV?
                                    </li>
                                </ul>
                            </div>
                            <img src={magnifyingGlassPrimaryColor} onClick={this.props.changeArticleNavModalFlag}/>
                        </Row>
                        <Row className="title-row">
                            <div md="auto">
                                <hr class="line-title"></hr>
                                <h1 className="title-art">CPV</h1>
                                <p className='text-below-title'>Why is it important to know the potential of the CPV?</p>
                            </div>
                            <div className="second-title-col" md="auto">
                                <p className='published-day'>Updated on Jan 03 2023 10:00 AM • 4 minutes to read</p>
                            </div>
                        </Row>
                        <Row className="body-row">
                            <Col className="column-paragraph" md="auto">
                                <h2 className="art-subtitle">Learn in this article what a CPV is and how this code allows you to perform your searches more strategically within Tender Notify</h2>
                                <p className='art-paragraph'>
                                    A CPV code is a number used to indicate the object of a tender. The acronym stands for Common Procurement Vocabulary Code and is used by the European Union to classify goods and services procured by public authorities.
                                </p>
                                <p className='art-paragraph'>
                                    CPV codes follow a tree structure, with a hierarchy of code, digits, segmentation, and description. For example, a CPV code for the installation of telecommunications equipment might be 45314200-3. The first two digits (45) indicate the division (construction work), the third digit (3) indicates the group (building installation work), the fourth digit (1) indicates the class (electrical installation work), the fifth digit (4) indicates the category (installation of telecommunications equipment), and the sixth to eighth digits (200-3) indicate the sub-category (installation of telephone lines).
                                </p>
                            </Col>
                            <Col className="column-paragraph with-table" md="auto">
                                <table className="cpv-table">
                                    <thead>
                                        <tr className='tr-head'>
                                            <td className='thead-td-table'>
                                                <p className='thead-text'>Code</p>
                                            </td>
                                            <td className='thead-td-table'>
                                                <p className='thead-text'>Digits</p>
                                            </td>
                                            <td className='thead-td-table'>
                                                <p className='thead-text'>Segmentation</p>
                                            </td>
                                            <td className='thead-td-table'>
                                                <p className='thead-text'>Description</p>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>45000000-7</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>First two digits</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Divisions</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Construction work</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>45300000-0</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Third digit</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Group</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Building installation work</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>45310000-3</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Fourth digit</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Class</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Electrical installation work</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>45314000-1</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Fifth digit</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Categories</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Installation of telecommunications equipment</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>45314200-3</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Sixth to eighth digit</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Sub-categories</p>
                                            </td>
                                            <td className='tbody-td-table'>
                                                <p className='tbody-text'>Installation of telephone lines</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col className="column-paragraph before-cpv-image" md="auto">
                                <h2 className="art-subtitle">How can I expand the potential of a CPV in Tender Notify?</h2>
                            </Col>
                            <Col className="column-paragraph with-image before-keywords" md="auto">
                                <Row className="paragraph-row">
                                    <Col className="first-column cpv-image" md="auto">
                                        <p className='art-paragraph'>
                                            In Tender Notify, CPV codes can be used to make searches, alerts, and smart filters (as shown in the next image) more precise. By entering the specific CPV code of interest, users can filter the results to find the most relevant tenders for their needs. This can help to save time and make the search process more efficient.
                                        </p>
                                    </Col>
                                    <Col className="second-column cpv-image" md="auto">
                                        <img class="image" src={cpv1} onClick={() => this.changeArticleModalFlag(cpv1, "horizontal")} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="column-paragraph" md="auto">
                                <h2 className="art-subtitle">Keywords for this article</h2>
                                <p className='art-paragraph'>
                                    CPV, code, tree, divisions, group, class, category, sub-category, track tender, tenders, smart filter.
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Container className='share-and-more-arts-container'>
                        <Container className='more-arts-container'>
                            <Row className="body-row">
                                <Col className="column-paragraph" md="auto">
                                    <h2 className="more-arts-title">{"These articles may also interest you"}</h2>
                                    <ul className='list-margin'>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("track-tenders")}>
                                            Track Tenders so you don't miss anything
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("smart-filters")}>
                                            How to boost your search using Smart Filters
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("notification-preferences")}>
                                            Notification Preferences: your smart ally
                                        </li>
                                        <li className='summary-item' onClick={() => this.props.onClickBlogSection("reporting-and-dashboard")}>
                                            Share, measure and grow
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='share-container'>
                            <Row className="body-row">
                                <Col md="auto">
                                    <h2 className="share-title">{"Share"}</h2>
                                </Col>
                                <Col className="column-share-buttons" md="auto">
                                    <TelegramShareButton
                                        children={<TelegramIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#cpvs"}
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <EmailShareButton
                                        children={<EmailIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#cpvs"}
                                        subject={"Why is it important to know the potential of the CPV?"}
                                        body={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                    />
                                    <WhatsappShareButton
                                        title={"I found this on the Coowise Blog and wanted to share it with you, check it out : "}
                                        children={<WhatsappIcon size={35} round={false} />}
                                        url={window.location.origin + "/blog#cpvs"}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </section>
                <ArticleModal
                    articleModalFlag={this.state.articleModalFlag}
                    changeArticleModalFlag={this.changeArticleModalFlag}
                    image={this.state.image}
                    imageClass={this.state.imageClass}
                />
            </Container>
        );
    }
}

CpvArt.propTypes = {
    onClickBlogSection: PropTypes.func.isRequired,
    cookieUp: PropTypes.bool.isRequired,
    currentSection: PropTypes.string.isRequired,
    changeCurrentSection: PropTypes.func.isRequired,
    changeArticleNavModalFlag: PropTypes.func.isRequired
};

export default CpvArt;