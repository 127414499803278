import axios from "axios";
import * as actionTypes from "./actionTypes";

export const signUp = (user) => {
    let planId = window.sessionStorage.getItem("planId");
    let monthly = window.sessionStorage.getItem("monthly");

    let monthlyflag = true;

    if(monthly != null){
        if(monthly == "false")
            monthlyflag = false;
    }

    let params = {
        UserName: user.UserName,
        FirstName: user.FirstName,
        LastName: user.LastName,
        Email: user.Email,
        Phone: user.Phone,
        Language: user.Language,
        Sector: user.Sector,
        MktEmailing: user.MktEmailing,
        LegitimateInterest: user.LegitimateInterest,
        PriceId: planId != null ? planId : 1,
        Monthly: monthlyflag
    }

    return {
        type: actionTypes.FETCH_SIGNUP,
        payload: axios.post("api/user/alphasignup", params)
    }
};

export const changeSignUpStates = () => {
    return {
        type: actionTypes.CHANGE_SIGN_UP_STATES
    };
};

export const GetSignUpGeneralData = () => {
    return {
        type: actionTypes.FETCH_SIGNUPGENERALDATA,
        payload: axios.get("api/generaldata/getsignupgeneraldata")
    }
};

export const changeSubscription = (planId) => {
    let monthly = window.sessionStorage.getItem("monthly");

    let monthlyflag = true;

    if(monthly != null){
        if(monthly == "false")
            monthlyflag = false;
    }

    let params = {
        MembershipId: planId,
        Monthly: monthlyflag
    }

    return {
        type: actionTypes.FETCH_CHANGE_SUBSCRIPTION,
        payload: axios.post("api/user/alphachangesubscription", params)
    }
};

export const renewSubscription = () => {
    return {
        type: actionTypes.FETCH_RENEW_SUBSCRIPTION,
        payload: axios.post("api/user/alpharenewsubscription")
    }
};

export const sendSurvey = (performanceSatisfaction, issueDescription, frecuency, recommendation, improvement) => {

    let params = {
        PerformanceSatisfaction: performanceSatisfaction,
        IssueDescription: issueDescription,
        Frecuency: frecuency,
        Recommendation: recommendation,
        Improvement: improvement
    }

    return {
        type: actionTypes.FETCH_SEND_SATISFACTION_SURVEY,
        payload: axios.post("api/user/sendsatisfactionsurvey", params)
    }
};

export const changeSendSurveyStates = () => {
    return {
        type: actionTypes.CHANGE_SEND_SATISFACTION_SURVEY_STATES
    };
};