import axios from "axios";
import * as actionTypes from "./actionTypes";

export const setupLoginVariables = (tokensDTO) => {
    return {
        type: actionTypes.SETUP_LOGIN_VARIABLES,
        payload: {
            tokensDTO: tokensDTO
        }
    };
};

export const getUserData = () => {
    return {
        type: actionTypes.FETCH_GETUSERDATA,
        payload: axios.post("api/login/getuserdatawebtenders")        
    };
};

export const cleanUpUserData = () => {
    return {
        type: actionTypes.CLEAN_UP_USERDATA
    }
}

export const goToApp = () => {
    let params = {
        "RefreshToken": window.sessionStorage.getItem("refreshToken"),
        "AccessToken": window.sessionStorage.getItem("accessToken")
    }

    return {
        type: actionTypes.FETCH_GO_TO_APP,
        payload: axios.post("api/login/gettokenfromwebtoapp", params)
    }
};