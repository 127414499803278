import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";
import ReactGA4 from 'react-ga4';
import {
    changeCurrentSection,
} from "../../actions/generalActions";

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);

        this.changeSection = this.changeSection.bind(this);
    }

    changeSection() {
        this.props.changeCurrentSection("Privacy-Policy");
        if (this.props.cookieUp)
            ReactGA4.send({ hitType: "pageview", title: 'Privacy-Policy', page: '/Privacy-Policy' });
    }

    render() {
        return (
            <section id="privacy-section" class="privacy-section" onMouseOver={this.props.currentSection != "Privacy-Policy" ? this.changeSection : () => { }}>
                <Container>
                    <Row className="justify-content-md-left">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h1 className="title-cookies-policy">{"Privacy Policy"}</h1>
                            <p className='text-below-title'>
                                {"Last updated: Mar 25th, 2024"}
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"1. Data controller and description"}</h2>
                            <Col md="auto" className='sub-columns privacy'>
                            <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Tender Notify is committed to protecting the privacy of its users. This Privacy Policy outlines how we collect, process, and use your personal and non-personal information when you use our website and services (hereinafter referred to as the 'Service'). By using our Service, you agree to the terms of this Privacy Policy.                                            "
                                    }
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"1.1. Responsible party"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {"Company Legal Name: CoowiseIT OÜ"}
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {"Registration No: 16129859"}
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {"VAT Number: EE102700114"}
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "E-mail: "
                                    }
                                    <a href="mailto:contact@coowise.com">contact@coowise.com</a>
                                </p>
                            </Col>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"1.2. Description"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Acceptance of this Privacy Policy is a necessary condition for using our Service. This Privacy Policy governs the collection, processing, and use of your personal and non-personal information as a user of Tender Notify, as of the effective date shown in the header."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "In processing your personal data, Tender Notify complies with all applicable local and European laws and regulations."
                                    }
                                </p>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{this.props.language == "ES" ? "Cómo configurar y deshabilitar las cookies" : "2. Information collected"}</h2>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                        : "The Service can be used without providing any information, although to request information or contract our services, you will need to provide us with some personal information. The information (personal or non-personal) collected by the Service may vary accordingly."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                        : "The personal and non-personal information collected by the Service will come to us in three ways: 1) that which is collected automatically; 2) that which you voluntarily provide to us; and 3) that which is provided by third parties."
                                }
                            </p>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"2.1. Automatically collected"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "This information will consist of:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "Information collected through cookies or similar mechanisms stored on your device, always with your consent or in accordance with other legal bases. See our [Cookie Policy] for more information."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "The IP from which the connection is made, the type of device used and its characteristics, the version of the operating system, the type of browser, the language, the date, the country, the time of the request, the URL from which you came, the URL to which you go, or the mobile network used, among others."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Data on the use of the Service and possible errors detected during its use."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "In addition, Tender Notify uses Google Analytics, an analytics service provided by Google, LLC. located in the United States with headquarters at 1600 Amphitheatre Parkway, Mountain View, California 94043. To provide these services, they use cookies that collect information, including the user's IP address, which will be transmitted, processed, and stored by Google on the terms set out on the website www.google.com. Including the possible transmission of such information to third parties for reasons of legal requirement or where such third parties process the information on Google's behalf."
                                        }
                                    </li>
                                </ul>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "In any case, you can disable Google Analytics cookies from "
                                    }
                                    <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">here</a>.
                                </p>
                            </Col>

                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"2.2. Information you provide voluntarily"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "This information will consist of:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "The information required by Tender Notify to complete your registration on the website: name and surname, email address, telephone number, company name, level of experience in public sector tenders, region, and business sector, among others. All data is mandatory."
                                        }
                                    </li>
                                </ul>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "All data is mandatory."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "If you are registering on behalf of another person through the Service, you warrant that you have lawfully obtained the personal information and consent of such other persons before providing us with their data."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "In relation to your password, as a user of the Service, you are responsible for its protection. In this sense, you will assume any damage and harm that may result from its improper use or the transfer of the same to third parties."
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "The information, personal or otherwise, that may be contained in the messages sent through the contact or opinion channels established in the Service, for example, your name, email address, telephone number, and message."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Information, personal or otherwise, that you provide to make use of the free trial, e.g. name, email address, telephone number, company name, industry and where the company operates."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "The information, personal or otherwise, that you provide to purchase a subscription, e.g. first and last name, email address, telephone number, company name, industry, where the company operates and payment details."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies de terceros; se envían a tu navegador desde un equipo o dominio web que no gestionamos nosotros directamente, sino una tercera entidad como por ejemplo Google."
                                                : "Personal information required to subscribe to the newsletter, such as your email address."
                                        }
                                    </li>
                                </ul>
                            </Col>

                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"2.3. Information provided by third parties"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "This information will consist of:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "Information provided by payment systems or credit card processors, such as the time of purchase or the amount of the purchase."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "Information provided by social networks or similar services."
                                        }
                                    </li>
                                </ul>
                            </Col>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"3. Rights and purposes"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "We inform you that the completion of the forms is voluntary. However, if you do not fill in the mandatory fields (usually marked with an asterisk), the use of some functions of the Service will not be possible or will be limited."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "The personal data you provide will be stored and processed in Tender Notify's Register of Processing Activities in order to deal with your requests, provide the requested service and keep you informed about matters relating to the company's activity and its services."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "You may at any time exercise your rights of access, rectification, erasure, restriction, objection and portability of your personal data by sending an email to: "
                                }
                                <a href="mailto:contact@coowise.com">contact@coowise.com</a>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : " or to the postal address: Pärnu mnt 139c, Tallinn, Estonia, 11317."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "In both cases you must identify yourself with your first and last name and a copy of your ID card or national ID card, if necessary."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "If you have given consent for a specific purpose, you have the right to withdraw your consent at any time, without affecting the lawfulness of the processing based on the consent prior to its withdrawal."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "In addition, if you feel that there is a problem with the way Tender Notify is handling your data, you can address your complaints to the "
                                }
                                <a href="https://www.aepd.es/reglamento/derechos/index.html" target="_blank">appropriate data protection</a>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : " authority, being the "
                                }
                                <a href="https://www.agpd.es/" target="_blank">Spanish Data Protection Agency</a>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : " the one indicated in the case of Spain."
                                }
                            </p>

                        </Col>

                    </Row>

                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"4. Age"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "With respect to your use of the online service, you declare that you are of legal age and that you have the necessary legal capacity to be bound by this agreement and to use the site in accordance with its terms and conditions, which you fully understand and acknowledge."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "You declare that all the information you provide to access the Service, before and during its use, is true, complete and accurate."
                                }
                            </p>
                        </Col>
                    </Row>

                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"5. Use of data"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Tender Notify will use the data collected to:"
                                }
                            </p>
                            <ul className='list-margin'>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "Administer and update the Service (the legal basis being our legitimate interest in keeping and maintaining the Service up to date and in good order)."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "To deal with the issues you raise (the legal basis being our legitimate interest in dealing with our users' requests and queries)."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "Manage your registration on the platform (the legal basis being your consent)."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "Provide the contracted service (the legal basis being the contract)."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "Process the payments you make and issue the corresponding invoices (the legal basis being the contract)."
                                    }
                                </li>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "Send you by email our newsletter with promotional offers related to the contracted service (being the legal basis, depending on the case, the contractual relationship or our legitimate interest for direct marketing actions based on the previous contractual relationship, according to art. 21.2 LSSICE)."
                                    }
                                </li>
                            </ul>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "You will be able to unsubscribe from them from the email you receive or by contacting us at "
                                }
                                <a href="mailto:contact@coowise.com">contact@coowise.com</a>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : ". However, you will not be able to unsubscribe from certain correspondence from us, such as messages relating to the security of your data or the terms and conditions of the Service."
                                }
                            </p>
                            <ul className='list-margin'>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "To maintain the security of the Service, to investigate illegal activity, to enforce our terms and conditions and to assist law enforcement agencies in their investigations (the legal basis being our legitimate interest in ensuring and maintaining the security of the Service and its users)."
                                    }
                                </li>
                            </ul>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Tender Notify does not use automated decisions, although it may generate basic profiles of users, the legal basis being our legitimate interest for commercial purposes and to provide personalised offers."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Tender Notify may also use user information in the form of aggregated, anonymous data for display to third parties. We may also share statistics and demographic information about users and their use of the Service with third parties. But none of this will allow those third parties to identify you personally."
                                }
                            </p>
                            <Col md="auto" className='sub-columns privacy second'>
                                <hr class="line-title"></hr>
                                <h2 className="policy-subtitle privacy">{"5.1. In mails and contact forms"}</h2>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "The Service's website has a TLS encryption that ensures that the user can safely send your personal data through standard contact forms."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "Your personal data collected will be automatically processed and incorporated into the corresponding files of the register of processing activities and of which Tender Notify is the owner."
                                    }
                                </p>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "In this regard:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "We will receive your IP, which will be used to check the origin of the message in order to provide you with appropriate recommendations (e.g. to present the information in the correct language) and to detect possible irregularities (e.g. possible attempts to cyber-attack the Service), as well as data relating to your ISP."
                                        }
                                    </li>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "Likewise, you can provide us with your data by phone, email and other indicated means of communication."
                                        }
                                    </li>
                                </ul>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Puedes permitir, bloquear o eliminar las cookies instaladas en tu dispositivo desde tu navegador web. Si no permites la instalación de cookies en tu dispositivo, puede que la experiencia de navegación empeore. A continuación puedes encontrar cómo configurar las cookies en cada navegador:"
                                            : "Regarding the means of payment used:"
                                    }
                                </p>
                                <ul className='list-margin'>
                                    <li className='summary-item'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "Our payment service providers to pay for the provision of our services are credit or debit card through a safely payment gateway (Stripe). At no time will the Service or its staff have access to the bank details (e.g. credit card number) that you as a customer provide to these third parties."
                                        }
                                    </li>
                                </ul>
                            </Col>
                        </Col>
                    </Row>

                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"6. Data retention"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "The following is an indication of how long the data processed by the Service is retained:"
                                }
                            </p>
                            <ul className='list-margin'>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "Disaggregated data will be retained with no deletion period."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "Customer data will be kept for the minimum necessary period of time, which may be up to:"
                                    }
                                </li>
                                <ul className='list-margin'>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "5 years, according to art. 1964 of the Civil Code (personal actions without special time limit)."
                                        }
                                    </li>
                                    <li className='summary-item privacy'>
                                        {
                                            this.props.language == "ES"
                                                ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                                : "6 years, according to Art. 30 of the Commercial Code (e.g. accounting books or invoices)."
                                        }
                                    </li>

                                </ul>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "Users data will be kept for a maximum of 12 months in order to be able to respond to their requests."
                                    }
                                </li>
                                <li className='summary-item privacy'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "The data of newsletter subscribers will be kept from the time the user subscribes until the user unsubscribes."
                                    }
                                </li>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "The data of users uploaded by Tender Notify to its social networks will be kept from the time the user gives their consent until they withdraw it."
                                    }
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"7. Data shared with third parties"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "There are third parties that manage part of the Service."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Tender Notify requires them to comply with this Privacy Policy as far as it applies to them. However, Tender Notify will not be responsible for their compliance with this policy."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Under some circumstances, Tender Notify may share, use or preserve with third parties some of the personal information collected:"
                                }
                            </p>
                            <ul className='list-margin'>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "To provide the Service:"
                                    }
                                </li>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "Service providers that perform functions on our behalf, such as payment services, web hosting, business analytics, CRM mailing and billing tool, customer service, web marketing, or the agency that handles billing data. These service providers may collect and have access to information that is necessary for them to perform their functions, but they are not allowed to share or use the information for any other purpose."
                                    }
                                </p>
                                <li className='summary-item'>
                                    {
                                        this.props.language == "ES"
                                            ? "Cookies propias; se envían a tu navegador desde nuestros equipos o dominios web."
                                            : "To cooperate with law enforcement authorities:"
                                    }
                                </li>
                                <p className='cookie-policy-paragraph'>
                                    {
                                        this.props.language == "ES"
                                            ? "Según quién las gestiona:"
                                            : "If we believe it is reasonably necessary to satisfy any law, legal process or legitimate interest. In any event, we will only provide information that is strictly required."
                                    }
                                </p>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"8. Security Measures"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "Tender Notify takes all the necessary technical and organisational measures to protect the security and integrity of personal and non-personal information collected. Both against unauthorised access and against accidental alteration, loss or destruction."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "In any case, Tender Notify cannot guarantee the absolute security of the information collected, so you must cooperate and use common sense about the information shared at all times."
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "You understand and acknowledge that, even after deletion, personal and non-personal information may remain visible in cache or if other users have copied or stored it."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"9. Changes in the Privacy Policy"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "We may update this Privacy Policy in the future. We will notify you of changes by sending a notice to the email address provided and/or by posting a prominent notice on our website."
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="privacy-row">
                        <Col md="auto">
                            <hr class="line-title"></hr>
                            <h2 className="title-section-cookies-policy">{"10. Contact"}</h2>

                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "If you have any questions about this Privacy Policy, please contact us at:"
                                }
                            </p>
                            <p className='cookie-policy-paragraph'>
                                {
                                    this.props.language == "ES"
                                        ? "Según quién las gestiona:"
                                        : "E-mail: "
                                }
                                <a href="mailto:contact@coowise.com">contact@coowise.com</a>
                            </p>
                            <p className='cookie-policy-paragraph'>
                                Address: Pärnu mnt 139c, Tallinn, Estonia, 11317.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

const mapStateToProps = (value) => {
    return {
        currentSection: value.general.currentSection,

        cookieUp: value.general.cookieUp
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentSection: (currentSection) => dispatch(changeCurrentSection(currentSection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);