import React, { Component } from 'react';
import { Route } from "react-router-dom";
import '../css/animate.css';
import '../css/bootstrap-4.5.0.min.css';
import '../css/default.css';
import '../css/magnific-popup.css';
import '../css/slick.css';
import '../css/styles.css';
import CookiePolicy from './Legal/CookiePolicy';
import Home from './Home';
import PrivacyPolicy from './Legal/PrivacyPolicy';
import SignUp from './SignUp';
import TermsOfUseAndConditions from './Legal/TermsOfUseAndConditions';
import Blog from './Blog/Blog';
import SatisfactionSurvey from './SatisfactionSurvey';
import ApiReference from './Blog/ApiReference';
import LiveReport from './Blog/LiveReport/LiveReport';
import BetaClosed from './BetaClosed';

class Routes extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Route exact path="/" component={Home} />
                <Route exact path="/cookies-policy" component={CookiePolicy} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/terms-of-use-and-conditions" component={TermsOfUseAndConditions} />
                <Route exact path="/blog" component={Blog} />
                <Route path="/sign-up" component={SignUp} />
                <Route path="/satisfaction-survey" component={SatisfactionSurvey} />
                <Route path="/api-reference" component={ApiReference} />
                {/* <Route path="/live-report" component={LiveReport} /> */}
                <Route path="/tender-notify-beta-closed" component={BetaClosed}/>
            </>
        );
    }
}

export default (Routes);